import React, { useEffect, useMemo, useState } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
    editCommittee,
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import { generateOptions } from '../../utils/Utils';
import { fetchRole, fetchRoles, getRoles } from '../../app/reducers/Role/roleSlice';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import { authAxiosInstance, axiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import {
    editRegistrationRequest,
    editShortlistRegistrationRequest,
    fetchRegistrationRequest,
    fetchRegistrationRequests,
    getRegistrationRequests,
} from '../../app/reducers/RegistrationRequest/registrationRequestSlice';
import { getEmployees } from '../../app/reducers/Users/EmployeeSlice';
import QueryString from 'qs';
import { getUsers } from '../../app/reducers/Users/usersSlice';
import { CloudDrizzle } from 'react-feather';

const EditCommonPool = ({
    requestData,
    showApprovedModal,
    setShowApprovedModal,
}) => {
    console.log('data', requestData)
    const { committee } = useSelector(getCommittees);
    const { elementEditData, elementEditDataLoading } = useSelector(getRegistrationRequests);
    console.log('ele', elementEditData)
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(fetchRegistrationRequest({status : 'shortlisted' ,populate : true}))
    },[])
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            // _id: requestData && requestData._id,
            // firstName: requestData && requestData?.firstName,
            // lastName: requestData && requestData?.lastName,
            ...elementEditData
        },
        onSubmit: async (values, { resetForm }) => {
            console.log('values', values);
            await dispatch(editShortlistRegistrationRequest({_id : values?._id, committee1 : values?.committee1}))
            await dispatch(fetchRegistrationRequests({populate:true}));
            setShowApprovedModal(false);
        },
    });
    return (
        <ModalBasic
            modalOpen={showApprovedModal}
            setModalOpen={setShowApprovedModal}
        >
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4 p-4"
            >
                <FormikInputGroup
                    label="User ID"
                    name="_id"
                    formik={formik}
                    readOnly
                />
                <FormikInputGroup
                    label="First Name"
                    name="firstName"
                    formik={formik}
                    readOnly
                />
                <FormikInputGroup
                    label="Last Name"
                    name="lastName"
                    formik={formik}
                    readOnly
                />
                <FormikSelectGroup
                    // required
                    label="Committee"
                    name="committee1"
                    formik={formik}
                    options={generateOptions({
                        array: committee ? committee?.docs : [],
                        labelField: 'name',
                        valueField: '_id',
                    })}
                />
                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default EditCommonPool;
