export const VOLUNTEER_ROLE_ID = '65e1a6c27ff07b14c0d2234d';
export const CL_ROLE_ID = '65fad505161e2986373e50b8';
export const ACL_ROLE_ID = '65fad50b161e2986373e50bb';
export const UNAPPROVED_CL_ROLE_ID = '66235be544205533152041eb';
export const CORE_ROLE = ['Head', 'HOD', 'Admin Head', 'Sub Head'];
export const NCP_REPRESENTATIVE_ROLE = '6645db895684ac8e33b564ea';
export const SUPER_USER_ROLE = '65eefc78f75155065d460167';

export const link_data = [
    {
      links: [
        { title: "Home", featureId : "" , href: "https://o2thefest.com/" },
        { title: "Gallery", featureId : "" , href: "https://o2thefest.com/gallery" },
        { title: "Team", featureId : "6671639779214c023ab51a4d" , href: "https://o2thefest.com/core" },
        { title: "Sponsors", featureId : "" , href: "https://o2thefest.com/sponsors" },
        { title: "Events", featureId : "667163b779214c023ab51a50" , href: "https://o2thefest.com/events" },
        { title: "Schedule", featureId : "667163e379214c023ab51a53" , href: "https://o2thefest.com/schedule" },
        { title: "Score Board", featureId : "6671640d79214c023ab51a56" , href: "https://o2thefest.com/scoreboard" },
        { title: "Registration", featureId : "65fadd155ee0eea0e5230c35" , href: "/externalRegister" },
        { title: "Team Registration", featureId : "65f14861c9d73a599d816e57" , href: "/register" },
        { title: "NCP Registration", featureId : "6671799d3a7d1f1f9d6b28af" , href: "/ncpRegister" },
        { title: "Login", featureId : "667164a079214c023ab51a59" , href: "/login" },
      ],
    },
    {
      logo: {
        url: "https://s3.ap-south-1.amazonaws.com/media.softwave.in/logo_final_9_8da49a5639.svg",
      },
    },
  ];
