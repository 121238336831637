import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEvent, fetchEvents, getEvents } from '../../app/reducers/Event/eventSlice';
import { getMediaLibrary } from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';

const ViewEvents = () => {
    const { event, loading } = useSelector(getEvents);
    const { baseUrl } = useSelector(getMediaLibrary);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const debouncedSearch = useCallback(
        _.debounce((search) => {
            console.log('searching');
            dispatch(fetchEvents({ search: search }));
        }, 300),
        []
    );

    const cols = [
        // {
        //     Header: 'Event Photo',
        //     Cell: ({ row }) => {
        //         return (
        //             <div>
        //                 {row?.original?.photos && (
        //                     <img
        //                         src={baseUrl + row?.original?.photos[0]}
        //                         height={70}
        //                         width={100}
        //                     />
        //                 )}
        //             </div>
        //         );
        //     },
        // },
        {
            Header: 'Title',
            Cell : ({row})=>{
                return(
                    <span style={{ color : `${row?.original?.committee?.hexCode}`}}>{row?.original?.name}</span>
                )
            }
        },
        {
            Header: 'Committee',
            Cell : ({row})=>{
                return(
                    <span style={{ color : `${row?.original?.committee?.hexCode}`}}>{row?.original?.committee?.name}</span>
                )
            }
        },
        {
            Header: 'Podium',
            accessor : 'podium',
            // Cell: ({ row }) => {
            //     const points = row?.original?.podium.points || [];
            //     return (
            //         <ul>
            //             {points.map((point, index) => (
            //                 <li key={index}>
            //                     {point.name && <span>{point.name}</span>}
            //                     &nbsp; - &nbsp; {point.pointAwarded}
            //                 </li>
            //             ))}
            //         </ul>
            //     );
            // },
        },
        // {
        //     Header: 'Event Date & Time',
        //     Cell: ({ row }) => {
        //         const datetimeString = row?.original?.eventDate;
        //         const datetime = new Date(datetimeString);
        //         const date = datetime.toISOString().split('T')[0]; //date extract

        //         const offset = 5.5; // UTC+05:30 //time convert to UTC
        //         const utcTime =
        //             datetime.getTime() + datetime.getTimezoneOffset() * 60000;
        //         const time = new Date(
        //             utcTime + 3600000 * offset
        //         ).toLocaleTimeString('en-US', {
        //             hour12: true,
        //             hour: 'numeric',
        //             minute: 'numeric',
        //         });
        //         return (
        //             <div className="flex flex-col items-center">
        //                 <span>{date}</span>
        //                 <span>{time}</span>
        //             </div>
        //         );
        //     },
        // },
        {
            Header: 'Min. Participants',
            Cell: ({ row }) => {
                return (
                    <div className="flex justify-center">
                        {row?.original?.teamMinCapacity}
                    </div>
                );
            },
        },
        {
            Header: 'Max. Participants',
            Cell: ({ row }) => {
                return (
                    <div className="flex justify-center">
                        {row?.original?.teamMaxCapacity}
                    </div>
                );
            },
        },
        {
            Header: 'No. of Team Slots',
            Cell: ({ row }) => {
                return (
                    <div className="flex justify-center">
                        {row?.original?.teamMaxRegistration}
                    </div>
                );
            },
        },
        {
            Header : ' ',
            Cell : ({row})=>{
                return(
                    <div className="flex gap-2 items-center cursor-pointer">
                        <DangerButton
                            onClick={async(e)=>{
                                e.stopPropagation();
                                console.log('object id', row?.original?._id)
                                await dispatch(
                                    deleteEvent({ id : row?.original?._id})
                                )
                                dispatch(fetchEvents({ populate:true }))
                            }}
                        >
                            Delete
                        </DangerButton>
                    </div>
                )
            }
        },
    ];
    const data = useMemo(() => (event?.docs ? event?.docs : []), [event]);

    useEffect(() => {
        dispatch(fetchEvents({ populate: true, sort : { 'committee.name' : 1, order : 1 } }));
    }, [page]);
    return (
        <PageWithCard heading="View Events">
            <TableWithHeadingAndGlobalSearch
                loading={loading}
                data={data}
                searchFunction={debouncedSearch}
                columns={cols}
            />
            <PaginationClassic setPage={setPage} paginationDetails={event} />
        </PageWithCard>
    );
};

export default ViewEvents;
