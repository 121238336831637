import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import { useDispatch, useSelector } from 'react-redux';
import {
    editRegistrationRequest,
    fetchRegistrationRequests,
    getRegistrationRequests,
} from '../../app/reducers/RegistrationRequest/registrationRequestSlice';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import FilterRegistrationCommittee from './FilterRegistrationCommittee';
import { fetchCommittees } from '../../app/reducers/Committee/committeeSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import ApprovedRegistrationRequest from './ApprovedRegistrationRequest';
import { fetchRoles } from '../../app/reducers/Role/roleSlice';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import moment from 'moment/moment';

const ViewRegistrationRequest = () => {
    const [showApprovedModal, setShowApprovedModal] = useState(false);
    const [requestData, setRequestData] = useState({});
    const [page, setPage] = useState(1);
    const { registrationRequest, loading } = useSelector(
        getRegistrationRequests
    );
    const dispatch = useDispatch();
    const debouncedSearch = useCallback(
        _.debounce((search) => {
            dispatch(
                fetchRegistrationRequests({ search: search, populate: true })
            );
        }, 300)
    );

    const columns = [
        // {
        //     Header: 'Date',
        //     accessor: 'createdAt',
        //     Cell: ({ row }) => {
        //         return (
        //             <span>
        //                 {moment(row?.original?.createdAt).format('DD MMM YYYY')}
        //             </span>
        //         );
        //     },
        // },
        {
            Header: 'Name',
            Cell: ({ row }) => {
                return (
                    <span>
                        {row?.original?.firstName} {row?.original?.lastName}
                    </span>
                );
            },
        },
        // {
        //     Header: 'Email Address',
        //     accessor: 'email',
        // },
        // {
        //     Header: 'Roll No.',
        //     Cell: ({ row }) => {
        //         return <span>{row?.original?.rollNo}</span>;
        //     },
        // },

        {
            Header: 'Committee',
            accessor: 'committee1.name',
        },
        {
            Header : 'Role',
            Cell : ({ row })=>{
                return ( row?.original?.user &&
                    (<span>{row?.original?.user?.role?.name}</span>)
                )
            }
        },
        {
            Header: 'Status',
            Cell: ({ row }) => {
                return <span>{row?.original?.status}</span>;
            },
        },
        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row gap-2">
                        {['declined', 'pending', 'shortlisted'].includes(
                            row?.original?.status
                        ) && (
                            <PrimaryButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setRequestData(row?.original);
                                    setShowApprovedModal(true);
                                }}
                            >
                                Approve
                            </PrimaryButton>
                        )}
                        {[ 'pending', 'shortlisted'].includes(
                            row?.original?.status
                        ) && (
                            <DangerButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    console.log(row?.original);
                                    await dispatch(
                                        editRegistrationRequest({
                                            ...row.original,
                                            isDecline: true,
                                        })
                                    );
                                    await dispatch(
                                        fetchRegistrationRequests({
                                            populate: true,
                                        })
                                    );
                                }}
                            >
                                Decline
                            </DangerButton>
                        )}
                    </div>
                );
            },
        },
    ];
    const dataMemo = useMemo(
        () => registrationRequest?.docs ?? [],
        [registrationRequest]
    );
    const columnsData = useMemo(() => columns ?? [], [columns]);
    useEffect(() => {
        dispatch(
            fetchRegistrationRequests({ populate: true })
        );
        dispatch(fetchCommittees());
        dispatch(fetchRoles());
    }, [page]);

    return (
        <PageWithCard heading="Data Bank">
            <ApprovedRegistrationRequest
                requestData={requestData}
                showApprovedModal={showApprovedModal}
                setShowApprovedModal={setShowApprovedModal}
            />
            <FilterRegistrationCommittee loading={loading} />
            <TableWithHeadingAndGlobalSearch
                loading={loading}
                heading={'Data Bank'}
                data={dataMemo}
                columns={columnsData}
                searchFunction={debouncedSearch}
            />
            <PaginationClassic
                setPage={setPage}
                paginationDetails={registrationRequest}
            />
        </PageWithCard>
    );
};

export default ViewRegistrationRequest;
