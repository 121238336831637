import React, { useEffect, useState } from 'react';
import { getWebsiteCreatives } from '../app/reducers/WebsitesCreatives/websitesCreativeSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchEmployees,
    getEmployees,
} from '../app/reducers/Users/EmployeeSlice';
import { CORE_ROLE, VOLUNTEER_ROLE_ID } from '../utils/constants';
import {
    fetchCollegeParticipants,
    getCollegeParticipant,
} from '../app/reducers/CollegeParticipant/collegeParticipantSlice';
import QueryString from 'qs';
import { authAxiosInstance } from '../utils/axiosConfig';
import { getEvents } from '../app/reducers/Event/eventSlice';
import moment from 'moment/moment';
import {
    fetchParticipants,
    getParticipants,
} from '../app/reducers/Participant/teamParticipantSlice';

const DashboardAdminHead = ({ user, registeredEvent, totalEvents }) => {
    const { websiteCreatives } = useSelector(getWebsiteCreatives);
    console.log('creative data', websiteCreatives);
    const dynamicDefaultData = websiteCreatives[0];
    const [coreTeam, setCoreTeam] = useState(0);
    const [totalVolunteer, setTotalVolunteer] = useState(0);
    const [totalSubstitutes, setTotalSubstitutes] = useState(0);
    const [totalOtse, setTotalOtse] = useState(0);
    const [upcomingEvents, setUpcomingEvents] = useState(0);
    const { employees } = useSelector(getEmployees);
    const { collegeParticipant } = useSelector(getCollegeParticipant);
    const { teamParticipant } = useSelector(getParticipants);
    console.log('teamParticipant1122', teamParticipant);
    const { event } = useSelector(getEvents);
    console.log('event1122', event);
    const [eventGroup, setEventGroup] = useState({
        scheduled: {},
        unscheduled: [],
    });
    const dispatch = useDispatch();
    const computeCoreTeamData = () => {
        try {
            const teamData = employees?.docs?.filter((ele) =>
                CORE_ROLE.includes(ele?.role?.name)
            );
            // console.log('o2 core',teamData)
            setCoreTeam(teamData.length);

            const volunteerData = employees?.docs?.filter(
                (ele) => ele?.role?._id === VOLUNTEER_ROLE_ID
            );
            // console.log('Volunteers', volunteerData)
            setTotalVolunteer(volunteerData.length);
        } catch (error) {
            console.log('Error', error);
        }
    };
    const computeSubstitutes = async () => {
        try {
            const string = QueryString.stringify({
                populate: true,
                limit: 5000,
            });
            const resp = await authAxiosInstance.get(
                `/teamParticipant/getSubstituteData?${string}`
            );
            // console.log('Substi', resp?.data?.data);
            setTotalSubstitutes(resp?.data?.data?.length);
        } catch (error) {
            console.log('Error', error);
        }
    };
    const computeOtse = async () => {
        try {
            const stringData = QueryString.stringify({ type: 'otse' });
            const resp = await authAxiosInstance.get(
                `/teamParticipant?${stringData}`
            );
            // console.log('otse', resp?.data?.data?.docs);
            setTotalOtse(resp?.data?.data?.docs?.length);
        } catch (error) {
            console.log('Error', error);
        }
    };
    const computeUpcomingEvent = async () => {
        try {
            const stringData = QueryString.stringify({
                runningStatus: 'upcoming',
            });
            const resp = await authAxiosInstance.get(`/event?${stringData}`);
            console.log('event up', resp?.data?.data?.docs);
            setUpcomingEvents(resp?.data?.data?.docs?.length);
        } catch (error) {
            console.log('Error', error);
        }
    };
    const computeEventDate = () => {
        const eventDate = event?.docs.reduce(
            (acc, ele) => {
                if (ele.status === 'scheduled') {
                    const date = moment(ele.startTime).format('DD/MM/YYYY');
                    if (acc.scheduled[date]) {
                        acc.scheduled[date] = [...acc.scheduled[date], ele];
                    } else {
                        acc.scheduled[date] = [ele];
                    }
                } else {
                    acc.unscheduled = [...acc.unscheduled, ele];
                }
                return acc;
            },
            { scheduled: {}, unscheduled: [] }
        );
        console.log('group1122', eventDate);
        setEventGroup(eventDate);
    };

    useEffect(() => {
        if (event?.docs?.length) {
            computeEventDate();
        }
    }, [event]);
    useEffect(() => {
        dispatch(fetchEmployees({ populate: true, limit: 5000 }));
        dispatch(fetchCollegeParticipants({ populate: true, limit: 5000 }));
    }, []);
    useEffect(() => {
        computeCoreTeamData();
        computeSubstitutes();
    }, [employees]);
    useEffect(() => {
        computeSubstitutes();
        computeOtse();
        computeUpcomingEvent();
    }, [collegeParticipant]);
    useEffect(() => {
        dispatch(fetchParticipants({ populate: true, limit: 5000 }));
    }, []);

    return (
        <>
            {['Admin Head', 'HOD', 'Developer','Super User'].includes(user?.role?.name) && (
                <div className="w-full grid grid-cols-2 md:grid-cols-3 gap-4 p-8">
                    <div className="bg-black rounded-lg p-6 flex items-center justify-center flex-col text-center">
                        <p className="text-slate-200 font-semibold text-xl ">
                            O2 Core
                        </p>
                        <span
                            className="font-semibold text-2xl md:text-6xl"
                            style={{
                                color: dynamicDefaultData?.highlightedText
                                    ? dynamicDefaultData?.highlightedText
                                    : '#ffffff',
                            }}
                        >
                            {coreTeam}
                        </span>
                    </div>
                    <div className="bg-black rounded-lg p-6 flex items-center justify-center flex-col text-center">
                        <p className="text-slate-200 font-semibold text-xl ">
                            O2 Volunteers
                        </p>
                        <span
                            className="font-semibold text-2xl md:text-6xl"
                            style={{
                                color: dynamicDefaultData?.highlightedText
                                    ? dynamicDefaultData?.highlightedText
                                    : '#ffffff',
                            }}
                        >
                            {totalVolunteer}
                        </span>
                    </div>
                    <div className="bg-black rounded-lg p-6 flex items-center justify-center flex-col text-center">
                        <p className="text-slate-200 font-semibold text-xl ">
                            Registered Colleges
                        </p>
                        <span
                            className="font-semibold text-2xl md:text-6xl"
                            style={{
                                color: dynamicDefaultData?.highlightedText
                                    ? dynamicDefaultData?.highlightedText
                                    : '#ffffff',
                            }}
                        >
                            {collegeParticipant
                                ? collegeParticipant?.docs?.length
                                : 0}
                        </span>
                    </div>
                    <div className="bg-black rounded-lg p-6 flex items-center justify-center flex-col text-center">
                        <p className="text-slate-200 font-semibold text-xl ">
                            Upcoming Events
                        </p>
                        <span
                            className="font-semibold text-2xl md:text-6xl"
                            style={{
                                color: dynamicDefaultData?.highlightedText
                                    ? dynamicDefaultData?.highlightedText
                                    : '#ffffff',
                            }}
                        >
                            {upcomingEvents}
                        </span>
                    </div>
                    
                    <div className="bg-black rounded-lg p-6 flex items-center justify-center flex-col text-center">
                        <p className="text-slate-200 font-semibold text-xl ">
                            OTSEs
                        </p>
                        <span
                            className="font-semibold text-2xl md:text-6xl"
                            style={{
                                color: dynamicDefaultData?.highlightedText
                                    ? dynamicDefaultData?.highlightedText
                                    : '#ffffff',
                            }}
                        >
                            {totalOtse}
                        </span>
                    </div>
                    <div className="bg-black rounded-lg p-6 flex items-center justify-center flex-col text-center">
                        <p className="text-slate-200 font-semibold text-xl ">
                            Substitutes
                        </p>
                        <span
                            className="font-semibold text-2xl md:text-6xl"
                            style={{
                                color: dynamicDefaultData?.highlightedText
                                    ? dynamicDefaultData?.highlightedText
                                    : '#ffffff',
                            }}
                        >
                            {totalSubstitutes}
                        </span>
                    </div>
                    {Object.keys(eventGroup.scheduled).map((date, index) => {
                        const participantsForDay = eventGroup.scheduled[
                            date
                        ].reduce((dayAcc, event) => {
                            const participantsForEvent =
                                teamParticipant?.docs?.reduce(
                                    (eventAcc, teamEvent) => {
                                        if (
                                            teamEvent?.event?._id === event._id
                                        ) {
                                            eventAcc +=
                                                teamEvent.participants.length;
                                        }
                                        return eventAcc;
                                    },
                                    0
                                );

                            return dayAcc + participantsForEvent;
                        }, 0);

                        return (
                            <div
                                key={index}
                                className="bg-black rounded-lg p-6 flex items-center justify-center flex-col text-center"
                            >
                                <p className="text-slate-200 font-semibold text-xl ">
                                    {`Participants Day ${index + 1}`}
                                </p>
                                <span
                                    className="font-semibold text-2xl md:text-6xl"
                                    style={{
                                        color: dynamicDefaultData?.highlightedText
                                            ? dynamicDefaultData?.highlightedText
                                            : '#ffffff',
                                    }}
                                >
                                    {participantsForDay ? participantsForDay : 0}
                                </span>
                            </div>
                        );
                    })}
                </div>
            )}
            {user?.role?.name === 'NCP Representative' && (
                <div className="w-full md:w-[55%] grid grid-cols-2 gap-4  mb-10 py-4 px-8">
                    <div className=" bg-black rounded-lg p-6 flex items-center justify-center flex-col text-center">
                        <p className="text-slate-200 font-semibold text-lg md:text-4xl">
                            No. of events registered in
                        </p>
                        <span
                            className="font-semibold text-2xl md:text-6xl"
                            style={{
                                color: dynamicDefaultData?.highlightedText
                                    ? dynamicDefaultData?.highlightedText
                                    : '#ffffff',
                            }}
                        >
                            {`${registeredEvent}/${totalEvents}`}
                        </span>
                    </div>
                    {Object.keys(eventGroup.scheduled).map((date, index) => {
                        const eventsForDay = eventGroup.scheduled[date];
                        const numberOfEvents = eventsForDay.length;
                        console.log('participantsForDay', eventsForDay.length);
                        // for(let i = 0; i < participantsForDay.length; i++ ){

                        // }
                        return (
                            <div className=" bg-black rounded-lg  p-6 flex items-center justify-center flex-col text-center">
                                <p className="text-slate-200 font-semibold text-lg md:text-4xl">
                                    {`No of events on Day ${index + 1}`}
                                </p>
                                <span
                                    className="font-semibold text-2xl md:text-6xl"
                                    style={{
                                        color: dynamicDefaultData?.highlightedText
                                            ? dynamicDefaultData?.highlightedText
                                            : '#ffffff',
                                    }}
                                >
                                    {numberOfEvents}
                                </span>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default DashboardAdminHead;
