import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import { useDispatch, useSelector } from 'react-redux';
import {
    editRegistrationRequest,
    editShortlistRegistrationRequest,
    fetchRegistrationRequests,
    getRegistrationRequests,
} from '../../app/reducers/RegistrationRequest/registrationRequestSlice';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import { fetchCommittees } from '../../app/reducers/Committee/committeeSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { fetchRoles } from '../../app/reducers/Role/roleSlice';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import moment from 'moment/moment';
import ApprovedRegistrationRequest from '../RegistrationRequest/ApprovedRegistrationRequest';
import FilterRegistrationCommittee from '../RegistrationRequest/FilterRegistrationCommittee';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import EditCommonPool from './EditCommonPool';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { getFeatures } from '../../app/reducers/Features/featuresSlice';
import { getAuth } from '../../app/reducers/Auth/authSlice';

const CommonPool = ({ actionFeatures }) => {
    const { user } = useSelector(getAuth);
    const [showApprovedModal, setShowApprovedModal] = useState(false);
    const [requestData, setRequestData] = useState({});
    const [canShowActionFeature, setCanShowActionFeature] = useState(true)
    const [page, setPage] = useState(1);
    const { registrationRequest, loading } = useSelector(
        getRegistrationRequests
    );
    const { features } = useSelector(getFeatures);
    const computeActiveFeature = () => {
        let canShow = true;
        if (!user.isSuperUser && user?.role?.name !== 'Super User') {
            if (features?.docs?.length) {
                actionFeatures.forEach((item) => {
                    const allowedAction = features?.docs?.find(
                        (ele) => ele?._id === item
                    );
                    canShow = canShow && allowedAction?.status == 'active';
                });
                console.log('canShow', canShow)
                setCanShowActionFeature(canShow);
            }
        }
        else{
            if( user.isSuperUser || user?.role?.name == 'Admin Head'){
                setCanShowActionFeature(true)
            }
        }
    };

    const dispatch = useDispatch();
    const debouncedSearch = useCallback(
        _.debounce((search) => {
            dispatch(
                fetchRegistrationRequests({ search: search, populate: true })
            );
        }, 300)
    );

    const columns = [
        // {
        //     Header: 'Date',
        //     accessor: 'createdAt',
        //     Cell: ({ row }) => {
        //         return (
        //             <span>
        //                 {moment(row?.original?.createdAt).format('DD MMM YYYY')}
        //             </span>
        //         );
        //     },
        // },
        {
            Header: 'Name',
            Cell: ({ row }) => {
                return (
                    <span>
                        {row?.original?.firstName} {row?.original?.lastName}
                    </span>
                );
            },
        },
        // {
        //     Header: 'Email Address',
        //     accessor: 'email',
        // },
        // {
        //     Header: 'Roll No.',
        //     Cell: ({ row }) => {
        //         return <span>{row?.original?.rollNo}</span>;
        //     },
        // },

        {
            Header: 'Committee',
            accessor: 'committee1.name',
        },
        {
            Header : 'Role',
            Cell : ({ row })=>{
                return ( row?.original?.user &&
                    (<span>{row?.original?.user?.role?.name}</span>)
                )
            }
        },
        {
            Header: 'Status',
            Cell: ({ row }) => {
                return <span>{row?.original?.status}</span>;
            },
        },
        {
            Header: 'Action',
            Cell: ({ row }) => {
                return ( row?.original?.status == 'shortlisted' ?
                    (<div className="flex flex-row gap-2">
                        {  canShowActionFeature   &&
                            <SecondaryButton 
                                onClick = {async (e)=>{
                                    e.stopPropagation();
                                    setRequestData(row?.original);
                                    setShowApprovedModal(true);
                                }}
                            >
                                Edit
                            </SecondaryButton>
                        }
                        {/* {['declined', 'pending', 'shortlisted'].includes(
                            row?.original?.status
                        ) && (
                            <PrimaryButton ton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setRequestData(row?.original);
                                    setShowApprovedModal(true);
                                }}
                            >
                                Approve
                            </PrimaryButton>
                        )} */}
                        {['approved', 'pending', 'shortlisted'].includes(
                            row?.original?.status
                        ) && (
                            <DangerButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    console.log(row?.original);
                                    await dispatch(
                                        editShortlistRegistrationRequest({
                                            ...row.original,
                                            status: 'pending',
                                        })
                                    );
                                    await dispatch(
                                        fetchRegistrationRequests({
                                            populate: true,
                                        })
                                    );
                                }}
                            >
                                Decline
                            </DangerButton>
                        )}
                    </div>) : []
                );
            },
        },
    ];
    const dataMemo = useMemo(
        () => registrationRequest?.docs ? registrationRequest?.docs?.filter((ele)=> ele?.status !== 'pending') : [],
        [registrationRequest]
    );
    const columnsData = useMemo(() => columns ?? [], [columns]);
    useEffect(() => {
        
        computeActiveFeature();
        dispatch(
            fetchRegistrationRequests({ populate: true })
        );
        dispatch(fetchCommittees());
        dispatch(fetchRoles({type : 'Internal',sort :{name:1}}));
    }, [features, page]);

    return (
        <PageWithCard heading="Common Pool of Volunteers">
            <EditCommonPool
                requestData={requestData}
                showApprovedModal={showApprovedModal}
                setShowApprovedModal={setShowApprovedModal}
            />
            <FilterRegistrationCommittee loading={loading} />
            <TableWithHeadingAndSearch
                heading={'Common Pool of Volunteers'}
                data={dataMemo}
                columns={columnsData}
            
            />
            <PaginationClassic
                setPage={setPage}
                paginationDetails={registrationRequest}
            />
        </PageWithCard>
    );
};

export default CommonPool;
