import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { object, string, array, ref } from 'yup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import { generateOptions } from '../../utils/Utils';
import {
    createRegistrationRequest,
    fetchRegistrationRequests,
    getRegistrationRequests,
} from '../../app/reducers/RegistrationRequest/registrationRequestSlice';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import FormikFileInput from '../../components/formik/FormikFileInput';
import {
    COURSES_TYPE,
    PART_OF_O2_TYPE,
    YEAR_TYPE,
} from '../../utils/dropdownOptions';
import FormikCheckbox from '../../components/formik/FormikCheckbox';
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload';
import {
    fetchCollege,
    fetchColleges,
    getColleges,
} from '../../app/reducers/College/collegeSlice';
import { useNavigate } from 'react-router-dom';
import FormikINPhoneInput from '../../components/formik/FormikINPhoneInput';
import AuthImage from '../../images/O2 banner.png';
import { link_data } from '../../utils/constants';
import NavbarLeft from '../../components/Navbar/NavbarLeft';
import { fetchWebsiteCreativesData, getWebsiteCreatives } from '../../app/reducers/WebsitesCreatives/websitesCreativeSlice';
import FormikDirectImageUpload from '../../components/formik/FormikDirectImageUpload';

const Register = () => {
    const { committee } = useSelector(getCommittees);
    const { websiteCreatives } = useSelector(getWebsiteCreatives)
    console.log('creative data', websiteCreatives)
    const dynamicDefaultData = websiteCreatives[0];
    const navigate = useNavigate();
    const { college } = useSelector(getColleges);
    console.log('committee', college);
    console.log('url', process.env.REACT_APP_S3_URL);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchCommittees());
        dispatch(fetchColleges());
        // dispatch(fetchWebsiteCreativesData({ name: 'Default' }))
    }, []);

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            username: '',
            // college: '',
            courses: '',
            year: '',
            rollNo: '',
            collegeIdPhoto: '',
            committee1: '',
            committee2: '',
            committee3: '',
            partOfO2: '',
        },
        validationSchema: object({
            firstName: string()
                .matches(/^[A-Z][a-z]*$/, 'Case sensitive & can not contain spaces. i.e. Hello').label('First Name')
                .required(),
            lastName: string()
                .matches(/^[A-Z][a-z]*$/, 'Case sensitive & can not contain spaces. i.e. Hello').label('Last Name')
                .required(),
            email: string().email('Invalid email').label('Email').required(),
            password: string().matches(/^\S+$/,'Space in not allowed').label('Password').required(),
            confirmPassword: string().label('Confirm Password')
                .required()
                .oneOf([ref('password')], 'Password must be same'),
            username: string()
                .matches(
                    /^[0-9]{10}$/,
                    'Contact Number must be numbers (exact 10 digits)'
                )
                .min(10,'Min 10 digits')
                .max(10, 'Max 10 digits').label('Contact Number')
                .required(),
            // college: Yup.string().required(),
            courses: string().label('Courses').required(),
            year: string().label('Year').required(),
            rollNo: string().label('Roll No.').required(),
            collegeIdPhoto: string().label('College ID').required(),
            committee1: string().label('Preferred Committee 1').required(),
            committee2: string().label('Preferred Committee 2').required(),
            committee3: string().label('Preferred Committee 3').required(),
            partOfO2 : string().label('Please Confirm if your were in team O2 ?').required(),
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log('register values', values);
            const requestObj = {
                ...values,
                contactNumber: values?.username,
                preferredCommittee: `${values?.committee1},${values?.committee2},${values?.committee3}`
            }
            console.log('register values', requestObj);
            const resp = await dispatch(createRegistrationRequest(requestObj));
            if (resp?.payload?.success) {
                navigate(`/internalRegistrationGreetPage/${resp?.payload?.data?._id}`)
            }
            resetForm();
        },
    });
    return (
        // <>
        //     <NavbarLeft props={link_data} />
        //     <main className="bg-black">
        //         <div className="relative md:flex pt-[50px] min-h-screen">
        //             <div
        //                 className="hidden  md:w-1/2  md:flex "
        //                 aria-hidden="true"
        //                 style={{
        //                     backgroundColor: '#000000',
        //                 }}
        //             >
        //                 {/* <div className='w-[400px] h-[400px] lg:w-[500px] lg:h-[500px] mx-auto'> */}
        //                 <div className='w-full h-full'>
        //                     <img
        //                         className="object-cover w-full h-full"
        //                         style={{
        //                             objectPosition : 'center center'
        //                         }}
        //                         src={`${process.env.REACT_APP_S3_URL}${dynamicDefaultData?.banner}`}
        //                         // width="760"
        //                         // height="1024"
        //                         alt="Authentication"
        //                     />
        //                 </div>
        //             </div>
                    <div className="md:w-1/2 ">
                        {/* <div className=" h-full flex flex-col after:flex-1"> */}
                                
                            <div className="w-full h-full xl:w-2/3 flex flex-col items-center mx-auto px-4 md:px-1 lg:px-4 py-8">
                                <form
                                    className="flex w-full flex-col gap-2 xl:gap-6 bg-black p-4 rounded sm:rounded-md"
                                    onSubmit={formik.handleSubmit}
                                >
                                <div className='text-left '>
                                    <h1 className="text-3xl text-slate-200 font-bold mb-2 ">
                                        Welcome 👋
                                    </h1>
                                    <p className='text-slate-200 text-sm mb-2 '>Fill this form to register</p>
                                </div>
                                    <div className="flex flex-col gap-4 md:flex-row w-full">
                                        <div className="w-full">
                                            <FormikInputGroup
                                                name="firstName"
                                                required
                                                formik={formik}
                                                label="First Name"
                                            />
                                        </div>
                                        <div className="w-full">
                                            <FormikInputGroup
                                                name="lastName"
                                                required
                                                formik={formik}
                                                label="Last Name"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-4 md:flex-row w-full">
                                        <div className="w-full">
                                            <FormikINPhoneInput
                                                name="username"
                                                label="Contact Number"
                                                formik={formik}
                                                required
                                            />
                                        </div>
                                        <div className="w-full">
                                            <FormikInputGroup
                                                name="email"
                                                required
                                                formik={formik}
                                                label="Email"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-4 md:flex-row w-full">
                                        <div className="w-full">
                                            <FormikSelectGroup
                                                name="year"
                                                label="Year"
                                                formik={formik}
                                                required
                                                options={YEAR_TYPE}
                                            />
                                        </div>
                                        <div className="w-full">
                                            <FormikSelectGroup
                                                name="courses"
                                                label="Courses"
                                                formik={formik}
                                                required
                                                options={COURSES_TYPE}
                                            />
                                        </div>
                                        <div className="w-full">
                                            <FormikInputGroup
                                                name="rollNo"
                                                required
                                                formik={formik}
                                                label="Roll No."
                                            />
                                        </div>
                                    </div>
                                    {/* <FormikSelectGroup
                                    name="college"
                                    label="College"
                                    formik={formik}
                                    required
                                    options={generateOptions({
                                        array: college ? college?.docs : [],
                                        labelField: 'name',
                                        valueField: '_id',
                                    })}
                                /> */}
                                    <FormikDirectImageUpload
                                        name="collegeIdPhoto"
                                        label="College ID Photo"
                                        formik={formik}
                                        location={`1648_entertainment/registered/collegeId`}
                                        required
                                        randomize
                                    />
                                    <div className="flex flex-col gap-3 md:flex-row">
                                        <FormikSelectGroup
                                            onChange={(selectedOption) => {
                                                const payload = {
                                                    label: selectedOption.label,
                                                    populate: true,
                                                };
                                                if (
                                                    formik?.values?.committee1 ===
                                                    selectedOption.value
                                                ) {
                                                    toast.error(
                                                        `${payload.label} already selected`
                                                    );
                                                    return;
                                                }
                                                if (
                                                    formik?.values?.committee3 ===
                                                    selectedOption.value
                                                ) {
                                                    toast.error(
                                                        `${payload.label} already selected`
                                                    );
                                                    return;
                                                }
                                                formik.setFieldValue(
                                                    'committee1',
                                                    selectedOption.value
                                                );
                                            }}
                                            name="committee1"
                                            required
                                            formik={formik}
                                            label="Preferred Committee 1"
                                            options={generateOptions({
                                                array: committee
                                                    ? committee.docs
                                                    : [],
                                                labelField: 'name',
                                                valueField: '_id',
                                            })}
                                        />

                                        <FormikSelectGroup
                                            onChange={(selectedOption) => {
                                                const payload = {
                                                    label: selectedOption.label,
                                                    populate: true,
                                                };
                                                if (
                                                    formik?.values?.committee1 ===
                                                    selectedOption.value
                                                ) {
                                                    toast.error(
                                                        `${payload.label} already selected`
                                                    );
                                                    return;
                                                }
                                                if (
                                                    formik?.values?.committee3 ===
                                                    selectedOption.value
                                                ) {
                                                    toast.error(
                                                        `${payload.label} already selected`
                                                    );
                                                    return;
                                                }
                                                formik.setFieldValue(
                                                    'committee2',
                                                    selectedOption.value
                                                );
                                            }}
                                            name="committee2"
                                            required
                                            formik={formik}
                                            label="Preferred Committee 2"
                                            options={generateOptions({
                                                array: committee
                                                    ? committee.docs
                                                    : [],
                                                labelField: 'name',
                                                valueField: '_id',
                                            })}
                                        />

                                    </div>
                                    <div className="flex flex-col gap-3 md:flex-row">
                                        <FormikSelectGroup
                                            onChange={(selectedOption) => {
                                                const payload = {
                                                    label: selectedOption.label,
                                                    populate: true,
                                                };
                                                if (
                                                    formik?.values?.committee1 ===
                                                    selectedOption.value
                                                ) {
                                                    toast.error(
                                                        `${payload.label} already selected`
                                                    );
                                                    return;
                                                }
                                                if (
                                                    formik?.values?.committee2 ===
                                                    selectedOption.value
                                                ) {
                                                    toast.error(
                                                        `${payload.label} already selected`
                                                    );
                                                    return;
                                                }
                                                formik.setFieldValue(
                                                    'committee3',
                                                    selectedOption.value
                                                );
                                            }}
                                            name="committee3"
                                            required
                                            formik={formik}
                                            label="Preferred Committee 3"
                                            options={generateOptions({
                                                array: committee
                                                    ? committee.docs
                                                    : [],
                                                labelField: 'name',
                                                valueField: '_id',
                                            })}
                                        />
                                        <FormikSelectGroup
                                            name="partOfO2"
                                            label="Were you in team O2 ?"
                                            formik={formik}
                                            options={PART_OF_O2_TYPE}
                                        />
                                    </div>
                                    {/* <div className="flex flex-col gap-4 md:flex-row w-full"> */}
                                    {/* <div className="w-full"> */}
                                    <FormikInputGroup
                                        name="password"
                                        required
                                        formik={formik}
                                        label="Password"
                                        type="password"
                                    />
                                    {/* </div> */}
                                    {/* <div className="w-full"> */}
                                    <FormikInputGroup
                                        name="confirmPassword"
                                        required
                                        formik={formik}
                                        label="Confirm Password"
                                        type="password"
                                    />
                                    {/* </div> */}
                                    {/* </div> */}
                                    <div className="text-center mt-2">
                                        <PrimaryButton
                                            type="submit"
                                            className=" sm:w-[40%] "
                                            style={{
                                                backgroundColor: dynamicDefaultData?.highlightedText ? dynamicDefaultData?.highlightedText : '',
                                            }}
                                        >
                                            Register
                                        </PrimaryButton>
                                    </div>
                                </form>
                            </div>
                        {/* </div> */}
                    </div>
        //         </div>
        //     </main>

        // </>
    );
};

export default Register;
