import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/Auth/authSlice';
import mediaLibrarySlice from './reducers/MediaLibrary/mediaLibrarySlice';
import emailSlice from './reducers/Emails/emailSlice';
import usersSlice from './reducers/Users/usersSlice';
import EmployeeSlice from './reducers/Users/EmployeeSlice';
import CollegeSlice from './reducers/College/collegeSlice';
import FestivalSlice from './reducers/Festival/festivalSlice';
import RoleSlice from './reducers/Role/roleSlice';
import CommitteeSlice from './reducers/Committee/committeeSlice';
import generalBulkUploadSlice from './reducers/GeneralBulkUpload/generalBulkUploadSlice';
import registrationRequestSlice from './reducers/RegistrationRequest/registrationRequestSlice';
import featuresSlice from './reducers/Features/featuresSlice';
import eventSlice from './reducers/Event/eventSlice';
import scoringTemplateSlice from './reducers/ScoringTemplate/scoringTemplateSlice';
import externalRegistrationRequestSlice from './reducers/ExternalRegistrationRequest/externalRegistrationRequestSlice';
import collegeParticipantSlice from './reducers/CollegeParticipant/collegeParticipantSlice';
import participantSlice from './reducers/Participant/teamParticipantSlice';
import scheduleSlice from './reducers/schedule/scheduleSlice';
import websitesCreativeSlice from './reducers/WebsitesCreatives/websitesCreativeSlice';
import confirmPopUpSlice from './reducers/ConfirmPopUp/condirmPopUpSlice';
import scoreboardSlice from './reducers/Scoreboard/scoreboardSlice';
import scorecardSlice from './reducers/Scorecard/scorecardSlice';
import warOfClSlice from './reducers/WarOfCl/warOfClSlice';
import warOfClScoringSlice from './reducers/WarOfClScoring/warOfClScoringSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        mediaLibrary: mediaLibrarySlice,
        emails: emailSlice,
        users: usersSlice,
        employees: EmployeeSlice,
        colleges: CollegeSlice,
        festivals: FestivalSlice,
        roles: RoleSlice,
        committee: CommitteeSlice,
        generalBulkUpload: generalBulkUploadSlice,
        registrationRequest: registrationRequestSlice,
        features: featuresSlice,
        event : eventSlice,
        scoringTemplate : scoringTemplateSlice,
        externalRegistrationRequest :externalRegistrationRequestSlice,
        collegeParticipant : collegeParticipantSlice,
        teamParticipant : participantSlice,
        schedule : scheduleSlice,
        websiteCreatives : websitesCreativeSlice,
        confirmPopUp : confirmPopUpSlice,
        scoreBoard : scoreboardSlice,
        scorecard : scorecardSlice,
        warOfCl : warOfClSlice,
        warOfClScoring : warOfClScoringSlice,
    },
});
