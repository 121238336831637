import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import UserAvatar from '../../images/user-avatar-white-64.png';
import { useDispatch, useSelector } from 'react-redux';
import Transition from '../../utils/Transition';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';

function UserMenu({ align }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { user } = useSelector(getAuth);
    const [userInfo, setUserInfo] = useState({});
    console.log('user menu', user);
    const trigger = useRef(null);
    const dropdown = useRef(null);
    // close on click outside
    const allUsers = async () => {
        const resp = await authAxiosInstance.get(`/users/employee`);
        const respData = resp?.data?.data?.docs;
        console.log('object', respData);
        const data = respData?.map(ele);
    };
    const userDetails = async () => {
        try {
            const userPayload = { username: user?.username };
            const stringData = QueryString.stringify(userPayload);
            const resp = await authAxiosInstance.get(
                `users/employee?${stringData}`
            );
            const userData = resp?.data?.data?.docs[0];
            console.log('user detail', userData);
            setUserInfo(userData);
        } catch (error) {
            console.log(error);
            return error;
        }
    };
    useEffect(() => userDetails(), []);
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!dropdown.current) return;
            if (
                !dropdownOpen ||
                dropdown.current.contains(target) ||
                trigger.current.contains(target)
            )
                return;
            setDropdownOpen(false);
        };
        // userDetails();
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    return (
        <div className="relative inline-flex">
            <button
                ref={trigger}
                className="inline-flex justify-center items-center group"
                aria-haspopup="true"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                aria-expanded={dropdownOpen}
            >
                <img
                    className="w-8 h-8 rounded-full"
                    src={UserAvatar}
                    width="32"
                    height="32"
                    alt="User"
                />
                <div className="flex items-center truncate">
                    <span className="truncate ml-2 text-sm text-slate-200 font-medium group-hover:text-red-500">
                        {userInfo?.firstName} {userInfo?.lastName}
                    </span>
                    <svg
                        className="w-3 h-3 shrink-0 ml-1 fill-current text-slate-200 group-hover:text-red-500"
                        viewBox="0 0 12 12"
                    >
                        <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                </div>
            </button>

            <Transition
                className={`origin-top-right z-10 absolute top-full min-w-44 bg-black border border-slate-600 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${
                    align === 'right' ? 'right-0' : 'left-0'
                }`}
                show={dropdownOpen}
                enter="transition ease-out duration-200 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-200"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
            >
                <div
                    ref={dropdown}
                    onFocus={() => setDropdownOpen(true)}
                    onBlur={() => setDropdownOpen(false)}
                >
                    <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-600">
                        <div className="font-medium text-slate-800">
                            {user.name}
                        </div>
                        <div className="text-xs text-slate-400 italic">
                            {user?.role?.name}
                        </div>
                    </div>
                    {user?.role?.name === 'Super User' && (
                        <div className="pt-0.5 pb-2 px-3 mb-1 border-b font-medium text-sm border-slate-600  text-red-500">
                            <button
                                onClick={async (e) => {
                                    try {
                                        e.preventDefault();
                                    const confirmSubmit = window.confirm(
                                        'Are you sure you want to reset all data ?' +
                                            '\n' +
                                        'Once Submit you cannot able fetch them again...!'
                                    );
                                    if(confirmSubmit){
                                        await authAxiosInstance.delete(`/reset`);
                                        toast.success('Data Reset successful')
                                    }
                                    } catch (error) {
                                        toast.error('Failed to reset data')
                                    }
                                    
                                }}
                            >
                                Reset Data
                            </button>
                        </div>
                    )}
                    <ul className="px-2">
                        <li className="bg-red-500 rounded">
                            <Link
                                className="font-medium text-sm text-white hover:text-black flex items-center py-1 px-3"
                                to="/logout"
                                onClick={() => setDropdownOpen(!dropdownOpen)}
                            >
                                Sign Out
                            </Link>
                        </li>
                    </ul>
                </div>
            </Transition>
        </div>
    );
}

export default UserMenu;
