import React, { useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    editEvent,
    fetchEvent,
    fetchEvents,
    getEvents,
} from '../../app/reducers/Event/eventSlice';
import SetButtons from '../../components/infrastructure/SetButtons';
import { ChevronRight, Download, Eye } from 'react-feather';
import { useFormik } from 'formik';
import FormikLabel from '../../components/formik/FormikLabel';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import Tabs from '../../components/infrastructure/Tabs/Tabs';
import TableWithHeading from '../../components/Table/TableWithHeading';
import {
    editParticipant,
    fetchParticipants,
    getParticipants,
} from '../../app/reducers/Participant/teamParticipantSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import ViewDetailsOfParticipants from '../Participant/ViewDetailsOfParticipants';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import { exportCsv, generateOptions } from '../../utils/Utils';
import { fetchScoringTemplates } from '../../app/reducers/ScoringTemplate/scoringTemplateSlice';
import { createScoreBoard } from '../../app/reducers/Scoreboard/scoreboardSlice';
import {
    createScorecard,
    editScorecard,
    fetchScorecard,
    getScorecards,
} from '../../app/reducers/Scorecard/scorecardSlice';
import {
    fetchCommittee,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import { getFeatures } from '../../app/reducers/Features/featuresSlice';
import { toast } from 'react-toastify';
import WarOfClScoreboard from './WarOfClScoreboard';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { ClipLoader } from 'react-spinners';
import {
    fetchWarOfCls,
    getWarOfCls,
} from '../../app/reducers/WarOfCl/warOfClSlice';
import ViewDetails from '../CollegesRequest/ViewDetails';
import { saveAs } from 'file-saver';

const EventScoreboard = ({ actionFeatures }) => {
    const { user } = useSelector(getAuth);
    console.log('user', user);
    const { id } = useParams();
    const { eventId } = useParams();
    const { elementEditData: committeeEditData } = useSelector(getCommittees);
    // console.log('committee', committeeEditData);
    const { event, elementEditData } = useSelector(getEvents);
    const { elementEditData: scorecardEditData } = useSelector(getScorecards);
    // console.log('score data', scorecardEditData)
    const [requestData, setRequestData] = useState({});
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [activeButtonIndex, setActiveButtonIndex] = useState(0);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const { teamParticipant } = useSelector(getParticipants);
    // console.log('parti', teamParticipant);
    const { features } = useSelector(getFeatures);
    const [canShowSubmit, setCanShowSubmit] = useState(true);
    const [canShowUpload, setCanShowUpload] = useState(true);
    const [canShowEliminate, setCanShowEliminate] = useState(true);
    const [isEditable, setIsEditable] = useState(false);
    const [actionType, setActionType] = useState('');
    const [isDownloading, setIsDownloading] = useState({
        pl: false,
        woCl: false,
        pss: false,
        woClSs: false,
    });
    const { warOfCl } = useSelector(getWarOfCls);
    const [showClDetailModal, setShowClDetailModal] = useState(false);

    const scoreboardTabs = [
        { name: 'Scoring' },
        { name: 'Participants' },
        { name: 'All Registrations' },
    ];
    const eventData = useMemo(
        () => event?.docs?.filter((ele) => ele?.committee?._id == id),
        [event]
    );
    const dispatch = useDispatch();
    // console.log('edit', isEditable);
    const actionsBtn = ['Submit', 'Upload', 'Eliminate'];
    const createFeatureActionsConfig = (actionFeatures, actions) => {
        const config = {};
        actionFeatures.forEach((ids, index) => {
            config[ids] = actions[index];
        });
        return config;
    };
    const computeActiveFeature = () => {
        const buttonStateSetter = {
            Submit: setCanShowSubmit,
            Upload: setCanShowUpload,
            Eliminate: setCanShowEliminate,
        };
        const actionButtonMap = createFeatureActionsConfig(
            actionFeatures,
            actionsBtn
        );
        let canShow = true;
        if (!user.isSuperUser && user?.role?.name !== 'Super User') {
            if (features?.docs?.length) {
                features?.docs?.forEach((item) => {
                    if (actionFeatures.includes(item?._id)) {
                        const action = actionButtonMap[item?._id];
                        const status = item?.status;
                        if (buttonStateSetter[action]) {
                            buttonStateSetter[action](status === 'active');
                        }
                    }
                });
            }
        } else {
            Object.values(buttonStateSetter).forEach((btn) => btn(canShow));
        }
    };

    const computeInitialValue = (data) => {
        if (data) {
            return data;
        } else {
            return {
                firstPlace: '',
                secondPlace: '',
                thirdPlace: '',
                noShow: '',
                disqualification: '',
            };
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: computeInitialValue(scorecardEditData),
        onSubmit: async (values, { resetForm }) => {
            console.log('scoreboard values', values);

            if (actionType === 'submit') {
                let createObj = {
                    ...values,
                    committee: id,
                    event: eventId,
                };
                await dispatch(createScorecard(createObj));
                await dispatch(
                    fetchScorecard({ event: eventId, committee: id })
                );
            } else if (actionType === 'update' && isEditable) {
                console.log('edit values', values);
                let editObj = {
                    _id: values?._id,
                    ...values,
                };
                console.log('edit', editObj);
                await dispatch(editScorecard(editObj));
                await dispatch(
                    fetchScorecard({ event: eventId, committee: id })
                );
            } else if (actionType === 'upload') {
                const eventScoreName = (rank, level, type) => {
                    let capitalLevel =
                        level.charAt(0).toUpperCase() + level.slice(1);
                    let capitalType =
                        type.charAt(0).toUpperCase() + type.slice(1);
                    return `${rank}${capitalLevel}${capitalType}`;
                };
                let editObj = {
                    _id: values?._id,
                    ...values,
                    status: 'uploaded',
                };
                console.log('edit', editObj);
                await dispatch(editScorecard(editObj));
                await dispatch(
                    fetchScorecard({ event: eventId, committee: id })
                );

                const { _id, committee, event, createdAt, updatedAt, ...rest } =
                    values;
                console.log('rest', rest);

                for (const [rank, teamIds] of Object.entries(rest)) {
                    if (teamIds.length === 0 || teamIds[0] === '') {
                        continue;
                    }
                    for (const teamId of teamIds) {
                        console.log('id no.', teamId);
                        const teamData = teamParticipant?.docs?.filter(
                            (ele) => ele._id === teamId
                        );
                        const teamParticipantData = teamData[0];
                        console.log('team data', teamParticipantData);
                        if (teamParticipantData?.type !== 'ncp') {
                            let eventScoreType = eventScoreName(
                                rank,
                                elementEditData?.level,
                                teamParticipantData?.type
                            );
                            console.log('rank name', eventScoreType);

                            const resp = await dispatch(
                                fetchScoringTemplates({ name: eventScoreType })
                            );
                            const scoringData = resp?.payload?.data?.docs[0];
                            console.log('scoring name data', scoringData);
                            console.log('rank', eventScoreType);
                            let scoreObj = {
                                college: teamParticipantData?.college,
                                team: teamParticipantData?._id,
                                rank: eventScoreType,
                                points: scoringData?.points,
                                type: teamParticipantData?.type,
                                notes: `${rank} ranks`,
                                event: teamParticipantData?.event,
                                status: 'approved',
                                scorecardId: _id,
                            };
                            console.log('score obj', scoreObj);

                            await dispatch(createScoreBoard(scoreObj));
                        }
                    }
                }
            }

            resetForm();
            if (actionType !== 'edit') {
                setActionType('');
                setIsEditable(false);
            }
        },
    });

    const participantCols = [
        {
            Header: 'CC Code',
            accessor: 'collegeCode',
            cellStyle: {
                width: '40%',
            },
        },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row items-center gap-4">
                        {
                            <PrimaryButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    setRequestData(row?.original);
                                    setShowDetailsModal(true);
                                }}
                            >
                                View Participants{' '}
                                <Eye size={14} className="ml-2" />
                            </PrimaryButton>
                        }
                        {(committeeEditData?.scorer === user?._id ||
                            ['HOD', 'Super User', 'Developer'].includes(
                                user?.role?.name
                            )) &&
                            canShowEliminate && (
                                <DangerButton
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        let obj = {
                                            ...row?.original,
                                            status: 'eliminate',
                                        };
                                        console.log('eliminate', obj);
                                        await dispatch(editParticipant(obj));
                                        await dispatch(
                                            fetchParticipants({
                                                event: eventId,
                                                status: 'approved',
                                                sort: { collegeCode: 1 },
                                            })
                                        );
                                    }}
                                >
                                    Eliminate
                                </DangerButton>
                            )}
                    </div>
                );
            },
            cellStyle: {
                width: '60%',
            },
        },
    ];
    const WarOfClCols = [
        {
            Header: 'CC Code',
            accessor: 'collegeCode',
            cellStyle: {
                width: '50%',
            },
        },
        {
            Header: ' ',
            Cell: ({ row }) => {
                // console.log('row', row?.original);
                return (
                    <div className="flex flex-row items-center gap-4">
                        <PrimaryButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setRequestData(row?.original?.user);
                                setShowClDetailModal(true);
                            }}
                        >
                            View Participants <Eye size={14} className="ml-2" />
                        </PrimaryButton>
                    </div>
                );
            },
            cellStyle: {
                width: '50%',
            },
        },
    ];
    const allRegistrationsCols = [
        {
            Header: 'CC Code',
            accessor: 'collegeCode',
            cellStyle: {
                width: '30%',
            },
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ row }) => {
                return (
                    <span>
                        {row?.original?.status === 'approved'
                            ? 'qualified'
                            : row?.original?.status}
                    </span>
                );
            },
            cellStyle: {
                width: '30%',
            },
        },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row items-center gap-4">
                        {
                            <PrimaryButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    setRequestData(row?.original);
                                    setShowDetailsModal(true);
                                }}
                            >
                                View Participants{' '}
                                <Eye size={14} className="ml-2" />
                            </PrimaryButton>
                        }
                        {(committeeEditData?.scorer === user?._id ||
                            ['HOD', 'Super User', 'Developer'].includes(
                                user?.role?.name
                            )) && (
                            <DangerButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    let obj = {
                                        ...row?.original,
                                        status: 'approved',
                                    };
                                    await dispatch(editParticipant(obj));
                                    await dispatch(
                                        fetchParticipants({
                                            event: eventId,
                                            sort: { collegeCode: 1 },
                                        })
                                    );
                                }}
                            >
                                Back To Participants
                            </DangerButton>
                        )}
                    </div>
                );
            },
            cellStyle: {
                width: '40%',
            },
        },
    ];

    const scoringOption = useMemo(() => {
        return teamParticipant?.docs
            ?.filter((ele) => ele.event === eventId)
            .map((item) => ({
                label: item.collegeCode,
                value: item._id,
            }));
    });

    const participantMemo = useMemo(() => {
        if (teamParticipant && teamParticipant?.docs) {
            // const regularData = teamParticipant?.docs?.filter((ele) =>
            //     ['regular', 'otse'].includes(ele?.type)
            // );
            const regularData = teamParticipant?.docs;
            return regularData;
        } else {
            return [];
        }
    }, [teamParticipant]);

    const warOfClMemo = useMemo(
        () => (warOfCl?.docs ? warOfCl?.docs : []),
        [warOfCl]
    );

    const allRegistrationsMemo = useMemo(() => {
        if (teamParticipant && teamParticipant?.docs) {
            // const regularData = teamParticipant?.docs?.filter((ele) =>
            //     ['regular', 'otse'].includes(ele?.type)
            // );
            const regularData = teamParticipant?.docs;
            return regularData;
        } else {
            return [];
        }
    }, [teamParticipant]);

    useEffect(() => {
        if (eventId) {
            dispatch(fetchEvent({ _id: eventId, populate: true }));
            dispatch(
                fetchEvents({
                    populate: true,
                    'committee._id': id,
                    sort: { order: 1 },
                })
            );
        }
        if (id) {
            dispatch(fetchCommittee({ _id: id, populate: true }));
        }
    }, [id, eventId, activeButtonIndex]);

    useEffect(() => {
        if (eventId) {
            const index = eventData?.findIndex((ele) => ele?._id === eventId);
            if (index !== -1) {
                setActiveButtonIndex(index);
            }
        }
    }, [eventData, eventId, scoreboardTabs]);

    useEffect(() => {
        if (selectedTabIndex === 0) {
            dispatch(
                fetchParticipants({
                    event: eventId,
                    status: 'approved',
                    sort: { collegeCode: 1 },
                })
            );
            dispatch(fetchWarOfCls({ populate: true, sort: { createdAt: 1 } }));
            if (eventId) {
                dispatch(fetchScorecard({ event: eventId, committee: id }));
            }
        }
        if (selectedTabIndex === 1) {
            dispatch(
                fetchParticipants({
                    event: eventId,
                    status: 'approved',
                    sort: { collegeCode: 1 },
                })
            );
        }
        if (selectedTabIndex === 2) {
            dispatch(
                fetchParticipants({
                    event: eventId,
                    sort: { collegeCode: 1 },
                })
            );
        }
    }, [eventId, selectedTabIndex]);

    useEffect(() => {
        computeActiveFeature();
    }, [features]);

    const handleButtonClick = (index) => {
        setActiveButtonIndex(index);
    };
    const handleScoreTabsClick = (index) => {
        setSelectedTabIndex(index);
    };

    const renderButtons = () => {
        if (scorecardEditData) {
            if (['Developer', 'Super User'].includes(user?.role?.name)) {
                if (isEditable) {
                    return (
                        <div className="flex flex-row items-center gap-4">
                            <PrimaryButton
                                type="submit"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setActionType('update');
                                    formik.handleSubmit();
                                }}
                            >
                                Submit Changes
                            </PrimaryButton>
                            <DangerButton
                                type="button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsEditable(false);
                                }}
                            >
                                Cancel Edit
                            </DangerButton>
                        </div>
                    );
                } else {
                    return (
                        <div className="flex flex-row gap-2">
                            {scorecardEditData?.status === 'loaded' && (
                                <PrimaryButton
                                    type="button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setActionType('edit');
                                        setIsEditable(true);
                                    }}
                                >
                                    Edit
                                </PrimaryButton>
                            )}
                            {scorecardEditData?.status === 'loaded' &&
                                canShowUpload && (
                                    <DangerButton
                                        type="button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setActionType('upload');
                                            formik.handleSubmit();
                                        }}
                                    >
                                        Upload
                                    </DangerButton>
                                )}
                        </div>
                    );
                }
            }
            if (
                ['Developer', 'HOD', 'Admin Head', 'Super User'].includes(
                    user?.role?.name
                )
            ) {
                return (
                    scorecardEditData?.status === 'loaded' &&
                    canShowUpload && (
                        <DangerButton
                            type="button"
                            onClick={(e) => {
                                e.stopPropagation();
                                setActionType('upload');
                                formik.handleSubmit();
                            }}
                        >
                            Upload
                        </DangerButton>
                    )
                );
            }
        } else {
            if (
                committeeEditData?.scorer === user?._id ||
                ['Developer', 'HOD', 'Super User'].includes(user?.role?.name)
            ) {
                return (
                    canShowSubmit && (
                        <PrimaryButton
                            type="submit"
                            onClick={async (e) => {
                                e.stopPropagation();
                                setActionType('submit');
                                let eventEditObj = {
                                    ...elementEditData,
                                    runningStatus: 'ended',
                                };
                                await dispatch(editEvent(eventEditObj));
                            }}
                        >
                            Submit
                        </PrimaryButton>
                    )
                );
            }
        }
        return null;
    };

    return (
        <PageWithCard
            heading={
                <>
                    <Link to={'/committee/event'}>Events</Link>
                    <span className="text-slate-200 inline-block px-1">
                        <ChevronRight size={14} className="-mb-0.5" />
                    </span>
                    <span>{elementEditData?.committee?.name}</span>
                    <span className="text-slate-200 inline-block px-1">
                        <ChevronRight size={14} className="-mb-0.5" />
                    </span>
                    <span>{'Scoreboard'}</span>
                    <span className="text-slate-200 inline-block px-1">
                        <ChevronRight size={14} className="-mb-0.5" />
                    </span>
                    <span>{elementEditData?.name}</span>
                    <span className="text-slate-200 inline-block px-1">
                        <ChevronRight size={14} className="-mb-0.5" />
                    </span>
                    {selectedTabIndex === 0 && <span>{'Scoring'}</span>}
                    {selectedTabIndex === 1 && <span>{'Participants'}</span>}
                    {selectedTabIndex === 2 && (
                        <span>{'All Registrations'}</span>
                    )}
                </>
            }
        >
            <>
                <SetButtons>
                    {eventData?.map((ele, index) => (
                        <Link
                            key={ele?._id}
                            to={`/committee/event/scoreboard/${id}/${ele?._id}`}
                        >
                            <button
                                className={
                                    activeButtonIndex === index
                                        ? `btn  text-red-500 px-2 p-px text-xs sm:text-sm md:text-base min-w-[100px]  md:min-w-[0px]`
                                        : `btn text-slate-200 px-2 p-px text-xs sm:text-sm md:text-base  min-w-[100px] md:min-w-[0px]`
                                }
                                onClick={() => handleButtonClick(index)}
                            >
                                {ele?.name}
                            </button>
                        </Link>
                    ))}
                </SetButtons>
                <SetButtons>
                    {scoreboardTabs?.map((ele, index) => (
                        <div key={index}>
                            <button
                                className={
                                    selectedTabIndex === index
                                        ? `btn  text-red-500 px-2 p-px text-xs sm:text-sm md:text-base min-w-[100px]  md:min-w-[0px]`
                                        : `btn text-slate-200 px-2 p-px text-xs sm:text-sm md:text-base  min-w-[100px] md:min-w-[0px]`
                                }
                                onClick={() => handleScoreTabsClick(index)}
                            >
                                {ele?.name}
                            </button>
                        </div>
                    ))}
                </SetButtons>
                {selectedTabIndex === 1 && (
                    <>
                        <ViewDetailsOfParticipants
                            requestData={requestData}
                            showApprovedModal={showDetailsModal}
                            setShowApprovedModal={setShowDetailsModal}
                        />
                        <ViewDetails
                            requestData={requestData}
                            showApprovedModal={showClDetailModal}
                            setShowApprovedModal={setShowClDetailModal}
                        />
                    </>
                )}
                {selectedTabIndex === 2 && (
                    <ViewDetailsOfParticipants
                        requestData={requestData}
                        showApprovedModal={showDetailsModal}
                        setShowApprovedModal={setShowDetailsModal}
                    />
                )}
                {selectedTabIndex === 0 && (
                    <div className="w-full md:w-1/2">
                        {scorecardEditData ? (
                            <>
                                <h1 className="mb-2 font-semibold text-xl text-slate-200">
                                    Participants
                                </h1>
                                <form
                                    className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md"
                                    onSubmit={formik.handleSubmit}
                                >
                                    {elementEditData?.podium >= 1 && (
                                        <div className="flex flex-row items-center gap-4">
                                            <div className="w-1/3">
                                                <FormikLabel label="1st" />
                                            </div>
                                            <div className="w-2/3">
                                                <FormikMultiSelect
                                                    onChange={(
                                                        selectedOptions
                                                    ) => {
                                                        const selectedValues =
                                                            selectedOptions.map(
                                                                (option) =>
                                                                    option.value
                                                            );
                                                        const duplicateValue =
                                                            selectedValues.find(
                                                                (value) =>
                                                                    formik.values.secondPlace.includes(
                                                                        value
                                                                    ) ||
                                                                    formik.values.thirdPlace.includes(
                                                                        value
                                                                    ) ||
                                                                    formik.values.noShow.includes(
                                                                        value
                                                                    ) ||
                                                                    formik.values.disqualification.includes(
                                                                        value
                                                                    )
                                                            );
                                                        if (duplicateValue) {
                                                            toast.error(
                                                                `${scoringOption.find((opt) => opt.value === duplicateValue).label} already selected`
                                                            );
                                                            return;
                                                        }
                                                        formik.setFieldValue(
                                                            'firstPlace',
                                                            selectedValues
                                                        );
                                                    }}
                                                    name="firstPlace"
                                                    formik={formik}
                                                    options={scoringOption}
                                                    isMulti
                                                    isDisabled={
                                                        !isEditable ||
                                                        ![
                                                            'Developer',
                                                            'Super User',
                                                        ].includes(
                                                            user?.role?.name
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {elementEditData?.podium >= 2 && (
                                        <div className="flex flex-row items-center gap-4">
                                            <div className="w-1/3">
                                                <FormikLabel label="2nd" />
                                            </div>
                                            <div className="w-2/3">
                                                <FormikMultiSelect
                                                    onChange={(
                                                        selectedOptions
                                                    ) => {
                                                        const selectedValues =
                                                            selectedOptions.map(
                                                                (option) =>
                                                                    option.value
                                                            );
                                                        const duplicateValue =
                                                            selectedValues.find(
                                                                (value) =>
                                                                    formik.values.firstPlace.includes(
                                                                        value
                                                                    ) ||
                                                                    formik.values.thirdPlace.includes(
                                                                        value
                                                                    ) ||
                                                                    formik.values.noShow.includes(
                                                                        value
                                                                    ) ||
                                                                    formik.values.disqualification.includes(
                                                                        value
                                                                    )
                                                            );
                                                        if (duplicateValue) {
                                                            toast.error(
                                                                `${scoringOption.find((opt) => opt.value === duplicateValue).label} already selected`
                                                            );
                                                            return;
                                                        }
                                                        formik.setFieldValue(
                                                            'secondPlace',
                                                            selectedValues
                                                        );
                                                    }}
                                                    name="secondPlace"
                                                    formik={formik}
                                                    options={scoringOption}
                                                    isMulti
                                                    isDisabled={
                                                        !isEditable ||
                                                        ![
                                                            'Developer',
                                                            'Super User',
                                                        ].includes(
                                                            user?.role?.name
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {elementEditData?.podium >= 3 && (
                                        <div className="flex flex-row items-center gap-4">
                                            <div className="w-1/3">
                                                <FormikLabel label="3rd" />
                                            </div>
                                            <div className="w-2/3">
                                                <FormikMultiSelect
                                                    onChange={(
                                                        selectedOptions
                                                    ) => {
                                                        const selectedValues =
                                                            selectedOptions.map(
                                                                (option) =>
                                                                    option.value
                                                            );
                                                        const duplicateValue =
                                                            selectedValues.find(
                                                                (value) =>
                                                                    formik.values.firstPlace.includes(
                                                                        value
                                                                    ) ||
                                                                    formik.values.secondPlace.includes(
                                                                        value
                                                                    ) ||
                                                                    formik.values.noShow.includes(
                                                                        value
                                                                    ) ||
                                                                    formik.values.disqualification.includes(
                                                                        value
                                                                    )
                                                            );
                                                        if (duplicateValue) {
                                                            toast.error(
                                                                `${scoringOption.find((opt) => opt.value === duplicateValue).label} already selected`
                                                            );
                                                            return;
                                                        }
                                                        formik.setFieldValue(
                                                            'thirdPlace',
                                                            selectedValues
                                                        );
                                                    }}
                                                    name="thirdPlace"
                                                    formik={formik}
                                                    options={scoringOption}
                                                    isMulti
                                                    isDisabled={
                                                        !isEditable ||
                                                        ![
                                                            'Developer',
                                                            'Super User',
                                                        ].includes(
                                                            user?.role?.name
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className="flex flex-row items-center gap-4">
                                        <div className="w-1/3">
                                            <FormikLabel label="No Show" />
                                        </div>
                                        <div className="w-2/3">
                                            <FormikMultiSelect
                                                onChange={(selectedOptions) => {
                                                    const selectedValues =
                                                        selectedOptions.map(
                                                            (option) =>
                                                                option.value
                                                        );
                                                    const duplicateValue =
                                                        selectedValues.find(
                                                            (value) =>
                                                                formik.values.firstPlace.includes(
                                                                    value
                                                                ) ||
                                                                formik.values.secondPlace.includes(
                                                                    value
                                                                ) ||
                                                                formik.values.thirdPlace.includes(
                                                                    value
                                                                ) ||
                                                                formik.values.disqualification.includes(
                                                                    value
                                                                )
                                                        );
                                                    if (duplicateValue) {
                                                        toast.error(
                                                            `${scoringOption.find((opt) => opt.value === duplicateValue).label} already selected`
                                                        );
                                                        return;
                                                    }
                                                    formik.setFieldValue(
                                                        'noShow',
                                                        selectedValues
                                                    );
                                                }}
                                                name="noShow"
                                                formik={formik}
                                                options={scoringOption}
                                                isMulti
                                                isDisabled={
                                                    !isEditable ||
                                                    ![
                                                        'Developer',
                                                        'Super User',
                                                    ].includes(user?.role?.name)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center gap-4">
                                        <div className="w-1/3">
                                            <FormikLabel label="Disqualification" />
                                        </div>
                                        <div className="w-2/3">
                                            <FormikMultiSelect
                                                onChange={(selectedOptions) => {
                                                    const selectedValues =
                                                        selectedOptions.map(
                                                            (option) =>
                                                                option.value
                                                        );
                                                    const duplicateValue =
                                                        selectedValues.find(
                                                            (value) =>
                                                                formik.values.firstPlace.includes(
                                                                    value
                                                                ) ||
                                                                formik.values.secondPlace.includes(
                                                                    value
                                                                ) ||
                                                                formik.values.thirdPlace.includes(
                                                                    value
                                                                ) ||
                                                                formik.values.noShow.includes(
                                                                    value
                                                                )
                                                        );
                                                    if (duplicateValue) {
                                                        toast.error(
                                                            `${scoringOption.find((opt) => opt.value === duplicateValue).label} already selected`
                                                        );
                                                        return;
                                                    }
                                                    formik.setFieldValue(
                                                        'disqualification',
                                                        selectedValues
                                                    );
                                                }}
                                                name="disqualification"
                                                formik={formik}
                                                options={scoringOption}
                                                isMulti
                                                isDisabled={
                                                    !isEditable ||
                                                    ![
                                                        'Developer',
                                                        'Super User',
                                                    ].includes(user?.role?.name)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div>{renderButtons()}</div>
                                </form>
                            </>
                        ) : (
                            <>
                                <h1 className="mb-2 font-semibold text-xl text-slate-200">
                                    Participants
                                </h1>
                                <form
                                    className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md "
                                    onSubmit={formik.handleSubmit}
                                >
                                    {elementEditData?.podium >= 1 && (
                                        <div className="flex flex-row items-center gap-4">
                                            <div className="w-1/3">
                                                <FormikLabel label="1st" />
                                            </div>
                                            <div className="w-2/3">
                                                <FormikMultiSelect
                                                    onChange={(
                                                        selectedOptions
                                                    ) => {
                                                        const selectedValues =
                                                            selectedOptions.map(
                                                                (option) =>
                                                                    option.value
                                                            );
                                                        const duplicateValue =
                                                            selectedValues.find(
                                                                (value) =>
                                                                    formik.values.secondPlace.includes(
                                                                        value
                                                                    ) ||
                                                                    formik.values.thirdPlace.includes(
                                                                        value
                                                                    ) ||
                                                                    formik.values.noShow.includes(
                                                                        value
                                                                    ) ||
                                                                    formik.values.disqualification.includes(
                                                                        value
                                                                    )
                                                            );
                                                        if (duplicateValue) {
                                                            toast.error(
                                                                `${scoringOption.find((opt) => opt.value === duplicateValue).label} already selected`
                                                            );
                                                            return;
                                                        }
                                                        formik.setFieldValue(
                                                            'firstPlace',
                                                            selectedValues
                                                        );
                                                    }}
                                                    name="firstPlace"
                                                    formik={formik}
                                                    options={scoringOption}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {elementEditData?.podium >= 2 && (
                                        <div className="flex flex-row items-center gap-4">
                                            <div className="w-1/3">
                                                <FormikLabel label="2nd" />
                                            </div>
                                            <div className="w-2/3">
                                                <FormikMultiSelect
                                                    onChange={(
                                                        selectedOptions
                                                    ) => {
                                                        const selectedValues =
                                                            selectedOptions.map(
                                                                (option) =>
                                                                    option.value
                                                            );
                                                        const duplicateValue =
                                                            selectedValues.find(
                                                                (value) =>
                                                                    formik.values.firstPlace.includes(
                                                                        value
                                                                    ) ||
                                                                    formik.values.thirdPlace.includes(
                                                                        value
                                                                    ) ||
                                                                    formik.values.noShow.includes(
                                                                        value
                                                                    ) ||
                                                                    formik.values.disqualification.includes(
                                                                        value
                                                                    )
                                                            );
                                                        if (duplicateValue) {
                                                            toast.error(
                                                                `${scoringOption.find((opt) => opt.value === duplicateValue).label} already selected`
                                                            );
                                                            return;
                                                        }
                                                        formik.setFieldValue(
                                                            'secondPlace',
                                                            selectedValues
                                                        );
                                                    }}
                                                    name="secondPlace"
                                                    formik={formik}
                                                    options={scoringOption}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {elementEditData?.podium >= 3 && (
                                        <div className="flex flex-row items-center gap-4">
                                            <div className="w-1/3">
                                                <FormikLabel label="3rd" />
                                            </div>
                                            <div className="w-2/3">
                                                <FormikMultiSelect
                                                    onChange={(
                                                        selectedOptions
                                                    ) => {
                                                        const selectedValues =
                                                            selectedOptions.map(
                                                                (option) =>
                                                                    option.value
                                                            );
                                                        const duplicateValue =
                                                            selectedValues.find(
                                                                (value) =>
                                                                    formik.values.firstPlace.includes(
                                                                        value
                                                                    ) ||
                                                                    formik.values.secondPlace.includes(
                                                                        value
                                                                    ) ||
                                                                    formik.values.noShow.includes(
                                                                        value
                                                                    ) ||
                                                                    formik.values.disqualification.includes(
                                                                        value
                                                                    )
                                                            );
                                                        if (duplicateValue) {
                                                            toast.error(
                                                                `${scoringOption.find((opt) => opt.value === duplicateValue).label} already selected`
                                                            );
                                                            return;
                                                        }
                                                        formik.setFieldValue(
                                                            'thirdPlace',
                                                            selectedValues
                                                        );
                                                    }}
                                                    name="thirdPlace"
                                                    formik={formik}
                                                    options={scoringOption}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className="flex flex-row items-center gap-4">
                                        <div className="w-1/3">
                                            <FormikLabel label="No Show" />
                                        </div>
                                        <div className="w-2/3">
                                            <FormikMultiSelect
                                                onChange={(selectedOptions) => {
                                                    const selectedValues =
                                                        selectedOptions.map(
                                                            (option) =>
                                                                option.value
                                                        );
                                                    const duplicateValue =
                                                        selectedValues.find(
                                                            (value) =>
                                                                formik.values.firstPlace.includes(
                                                                    value
                                                                ) ||
                                                                formik.values.secondPlace.includes(
                                                                    value
                                                                ) ||
                                                                formik.values.thirdPlace.includes(
                                                                    value
                                                                ) ||
                                                                formik.values.disqualification.includes(
                                                                    value
                                                                )
                                                        );
                                                    if (duplicateValue) {
                                                        toast.error(
                                                            `${scoringOption.find((opt) => opt.value === duplicateValue).label} already selected`
                                                        );
                                                        return;
                                                    }
                                                    formik.setFieldValue(
                                                        'noShow',
                                                        selectedValues
                                                    );
                                                }}
                                                name="noShow"
                                                formik={formik}
                                                options={scoringOption}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center gap-4">
                                        <div className="w-1/3">
                                            <FormikLabel label="Disqualification" />
                                        </div>
                                        <div className="w-2/3">
                                            <FormikMultiSelect
                                                onChange={(selectedOptions) => {
                                                    const selectedValues =
                                                        selectedOptions.map(
                                                            (option) =>
                                                                option.value
                                                        );
                                                    const duplicateValue =
                                                        selectedValues.find(
                                                            (value) =>
                                                                formik.values.firstPlace.includes(
                                                                    value
                                                                ) ||
                                                                formik.values.secondPlace.includes(
                                                                    value
                                                                ) ||
                                                                formik.values.thirdPlace.includes(
                                                                    value
                                                                ) ||
                                                                formik.values.noShow.includes(
                                                                    value
                                                                )
                                                        );
                                                    if (duplicateValue) {
                                                        toast.error(
                                                            `${scoringOption.find((opt) => opt.value === duplicateValue).label} already selected`
                                                        );
                                                        return;
                                                    }
                                                    formik.setFieldValue(
                                                        'disqualification',
                                                        selectedValues
                                                    );
                                                }}
                                                name="disqualification"
                                                formik={formik}
                                                options={scoringOption}
                                            />
                                        </div>
                                    </div>
                                    <div>{renderButtons()}</div>
                                </form>
                            </>
                        )}
                        {
                            <WarOfClScoreboard
                                committeeEditData={committeeEditData}
                                eventId={eventId}
                                canShowSubmit={canShowSubmit}
                                user={user}
                                id={id}
                                activeButtonIndex={activeButtonIndex}
                                elementEditData={elementEditData}
                            />
                        }
                    </div>
                )}
                {selectedTabIndex === 1 && (
                    <div className="w-full md:w-2/3 xl:w-1/2">
                        <h1 className="mb-2 font-semibold text-xl text-slate-200">
                            Participants
                        </h1>
                        <TableWithHeading
                            columns={participantCols}
                            data={participantMemo}
                        />
                        {elementEditData?.warOfClEvent === 'yes' && (
                            <>
                                <h1 className="mb-2 font-semibold text-xl text-slate-200">
                                    War of CLs
                                </h1>
                                <TableWithHeading
                                    columns={WarOfClCols}
                                    data={warOfClMemo}
                                />
                            </>
                        )}
                        {
                            <div className="flex flex-row items-start justify-start gap-4 mt-8">
                                {isDownloading.ps ? (
                                    <ClipLoader color="#FFFFFF" size={18} />
                                ) : (
                                    (committeeEditData?.scorer === user?._id ||
                                        [
                                            'HOD',
                                            'Super User',
                                            'Developer',
                                            'Head',
                                            'Admin Head',
                                        ].includes(user?.role?.name)) && (
                                        <SecondaryButton
                                            onClick={async (e) => {
                                                e.stopPropagation();
                                                try {
                                                    setIsDownloading(
                                                        (state) => ({
                                                            ...state,
                                                            ps: true,
                                                        })
                                                    );
                                                    let data = {
                                                        populate: true,
                                                        status: 'approved',
                                                        'event._id':
                                                            elementEditData?._id,
                                                        sort: {
                                                            'event.name': 1,
                                                            collegeCode: 1,
                                                        },
                                                    };

                                                    const stringData =
                                                        QueryString.stringify(
                                                            data
                                                        );
                                                    const resp =
                                                        await authAxiosInstance.get(
                                                            `/teamParticipant?${stringData}`
                                                        );
                                                    console.log(
                                                        'dwnld data',
                                                        resp?.data?.data?.docs
                                                    );
                                                    if (
                                                        resp?.data?.data?.docs
                                                            ?.length > 0
                                                    ) {
                                                        let mainData = [];
                                                        let srNo = 1;
                                                        resp?.data?.data?.docs?.forEach(
                                                            (ele, index) => {
                                                                let {
                                                                    college,
                                                                    event,
                                                                    collegeCode,
                                                                    ncpCollege,
                                                                    mainCode,
                                                                    status,
                                                                    participants,
                                                                    substitutes,
                                                                } = ele;

                                                                participants?.forEach(
                                                                    (p, i) => {
                                                                        mainData.push(
                                                                            {
                                                                                srNo: srNo++,
                                                                                collegeCode:
                                                                                    collegeCode,
                                                                                participantName: `${p.firstName} ${p.lastName}`,
                                                                                event: event?.name,
                                                                                // college: college ? college?.name : ncpCollege,
                                                                                // status: status,
                                                                                // mainCode: mainCode,
                                                                            }
                                                                        );
                                                                    }
                                                                );
                                                            }
                                                        );
                                                        console.log(
                                                            'main data',
                                                            mainData
                                                        );
                                                        const fileName = `${elementEditData?.name}`
                                                        exportCsv(mainData, fileName);
                                                    }
                                                } catch (error) {
                                                    console.log(error);
                                                    toast.error(
                                                        'Fail to Download'
                                                    );
                                                } finally {
                                                    setIsDownloading(
                                                        (state) => ({
                                                            ...state,
                                                            ps: false,
                                                        })
                                                    );
                                                }
                                            }}
                                        >
                                            <Download
                                                size={14}
                                                className="mr-2"
                                            />{' '}
                                            Participant List
                                        </SecondaryButton>
                                    )
                                )}
                                {isDownloading.woCl ? (
                                    <ClipLoader color="#FFFFFF" size={18} />
                                ) : (
                                    elementEditData?.warOfClEvent === 'yes' && (
                                        <SecondaryButton
                                            onClick={async (e) => {
                                                e.stopPropagation();
                                                try {
                                                    setIsDownloading(
                                                        (state) => ({
                                                            ...state,
                                                            woCl: true,
                                                        })
                                                    );
                                                    let data = {
                                                        populate: true,
                                                        sort: {
                                                            collegeCode: 1,
                                                        },
                                                    };

                                                    console.log(
                                                        'event',
                                                        eventData
                                                    );
                                                    const stringData =
                                                        QueryString.stringify(
                                                            data
                                                        );
                                                    const resp =
                                                        await authAxiosInstance.get(
                                                            `/warOfCl?${stringData}`
                                                        );
                                                    console.log(
                                                        'dwnld data',
                                                        resp?.data?.data?.docs
                                                    );
                                                    if (
                                                        resp?.data?.data?.docs
                                                            ?.length > 0
                                                    ) {
                                                        let mainData =
                                                            resp?.data?.data?.docs?.map(
                                                                (
                                                                    ele,
                                                                    index
                                                                ) => {
                                                                    let {
                                                                        collegeCode,
                                                                        user,
                                                                    } = ele;
                                                                    return {
                                                                        srNo: `${index + 1}`,
                                                                        collegeCode:
                                                                            collegeCode,
                                                                        participants: `${user.firstName} ${user.lastName}`,
                                                                    };
                                                                }
                                                            );
                                                        console.log(
                                                            'main data',
                                                            mainData
                                                        );
                                                        exportCsv(mainData);
                                                    }
                                                } catch (error) {
                                                    console.log(error);
                                                    toast.error(
                                                        'Fail to Download'
                                                    );
                                                } finally {
                                                    setIsDownloading(
                                                        (state) => ({
                                                            ...state,
                                                            woCl: false,
                                                        })
                                                    );
                                                }
                                            }}
                                        >
                                            <Download
                                                size={14}
                                                className="mr-2"
                                            />{' '}
                                            War Of CL List
                                        </SecondaryButton>
                                    )
                                )}
                            </div>
                        }
                        {
                            <div className="flex flex-row items-start justify-start gap-4 mt-8">
                                {isDownloading.pss ? (
                                    <ClipLoader color="#FFFFFF" size={18} />
                                ) : (
                                    <SecondaryButton
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            try {
                                                setIsDownloading((state) => ({
                                                    ...state,
                                                    pss: true,
                                                }));
                                                const data = {
                                                    eventIds: [eventId],
                                                    status: 'approved',
                                                };
                                                console.log('data', data);
                                                const resp =
                                                    await authAxiosInstance.post(
                                                        `/teamParticipant/getEventParticipantData`,
                                                        data
                                                    );
                                                console.log(
                                                    'event wise',
                                                    resp?.data?.data
                                                );
                                                const participantData =
                                                    resp?.data?.data;
                                                const response =
                                                    await authAxiosInstance.post(
                                                        '/printable/eventParticipantScoreSheet',
                                                        participantData,
                                                        { responseType: 'blob' }
                                                    );
                                                const fileName =
                                                    `${elementEditData?.name} ScoreSheet.pdf`;
                                                saveAs(response.data, fileName);
                                            } catch (error) {
                                                console.log('Error', error);
                                                toast.error('Fail to Download');
                                            } finally {
                                                setIsDownloading((state) => ({
                                                    ...state,
                                                    pss: false,
                                                }));
                                            }
                                        }}
                                    >
                                        <Download size={14} className="mr-2" />{' '}
                                        Participants Score Sheet
                                    </SecondaryButton>
                                )}
                                {isDownloading.woClSs ? (
                                    <ClipLoader color="#FFFFFF" size={18} />
                                ) : (
                                    elementEditData?.warOfClEvent === 'yes' && (
                                        <SecondaryButton
                                            onClick={async (e) => {
                                                e.stopPropagation();
                                                try {
                                                    setIsDownloading(
                                                        (state) => ({
                                                            ...state,
                                                            woClSs: true,
                                                        })
                                                    );
                                                    const stringData =
                                                        QueryString.stringify({
                                                            _id: eventId,
                                                        });
                                                    const resp =
                                                        await authAxiosInstance.get(
                                                            `/event?${stringData}`
                                                        );
                                                    console.log(
                                                        'resp',
                                                        resp?.data?.data
                                                            ?.docs[0]
                                                    );
                                                    const eventData =
                                                        resp?.data?.data
                                                            ?.docs[0];
                                                    const clString =
                                                        QueryString.stringify({
                                                            populate: true,
                                                            sort:{collegeCode : 1}
                                                        });
                                                    const clResp =
                                                        await authAxiosInstance.get(
                                                            `/warOfCl?${clString}`
                                                        );
                                                    console.log(
                                                        'clResp',
                                                        clResp?.data?.data?.docs
                                                    );
                                                    const participants =
                                                        clResp?.data?.data
                                                            ?.docs;
                                                    const participantData = [
                                                        {
                                                            eventData:
                                                                eventData,
                                                            participants:
                                                                participants,
                                                        },
                                                    ];
                                                    const response =
                                                    await authAxiosInstance.post(
                                                        '/printable/warOfClScoreSheet',
                                                        participantData,
                                                        { responseType: 'blob' }
                                                    );
                                                const fileName =
                                                    `${elementEditData?.name} WarOfCl ScoreSheet.pdf`;
                                                saveAs(response.data, fileName);
                                                } catch (error) {
                                                    console.log('Error', error);
                                                    toast.error(
                                                        'Fail to Download'
                                                    );
                                                } finally {
                                                    setIsDownloading(
                                                        (state) => ({
                                                            ...state,
                                                            woClSs: false,
                                                        })
                                                    );
                                                }
                                            }}
                                        >
                                            <Download
                                                size={14}
                                                className="mr-2"
                                            />{' '}
                                            War of Cl Score Sheet
                                        </SecondaryButton>
                                    )
                                )}
                            </div>
                        }
                    </div>
                )}
                {selectedTabIndex === 2 && (
                    <div className="w-full md:w-3/4">
                        <TableWithHeading
                            columns={allRegistrationsCols}
                            data={allRegistrationsMemo}
                        />
                    </div>
                )}
            </>
        </PageWithCard>
    );
};

export default EventScoreboard;
