import React, { useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useFormik } from 'formik';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { object, string } from 'yup';
import {
    createSchedule,
    fetchSchedules,
    getSchedules,
} from '../../app/reducers/schedule/scheduleSlice';
import { useDispatch, useSelector } from 'react-redux';
import EventCalender from './EventCalender';
import moment from 'moment';
import FormikTime from '../../components/formik/FormikTime';
import Tabs from '../../components/infrastructure/Tabs/Tabs';
import Tab from '../../components/infrastructure/Tabs/Tab';
import {
    editEvent,
    fetchEvents,
    getEvents,
} from '../../app/reducers/Event/eventSlice';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import {
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import TableWithHeading from '../../components/Table/TableWithHeading';
import AddEventSchedule from './AddEventSchedule';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { actions } from 'react-table';
import EditEventSchedule from './EditEventSchedule';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import ShowDropdownForSchedule from '../../components/infrastructure/ShowDropdownForSchedule';
import TableForScheduleEvent from '../../components/Table/TableForShceduleEvent';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'react-feather';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import { getFeatures } from '../../app/reducers/Features/featuresSlice';

const AddSchedule = ({actionFeatures}) => {
    const { user } = useSelector(getAuth);
    const { features } = useSelector(getFeatures);
    const [requestData, setRequestData] = useState({});
    const [eventGroup, setEventGroup] = useState({
        scheduled: {},
        unscheduled: [],
    });
    const [showApproveModal, setShowApprovedModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [canShowSchedule, setCanShowSchedule] = useState(true);
    const [canShowEditSchedule, setCanShowEditSchedule] = useState(true);
    const [canShowUnschedule, setCanShowUnschedule] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const { event } = useSelector(getEvents);
    console.log('event', event);
    const computeEventDate = () => {
        const eventDate = event?.docs.reduce(
            (acc, ele) => {
                if (ele.status === 'scheduled') {
                    const date = moment(ele.startTime).format('DD/MM/YYYY');
                    if (acc.scheduled[date]) {
                        acc.scheduled[date] = [...acc.scheduled[date], ele];
                    } else {
                        acc.scheduled[date] = [ele];
                    }
                } else {
                    acc.unscheduled = [...acc.unscheduled, ele];
                }
                return acc;
            },
            { scheduled: {}, unscheduled: [] }
        );
        console.log('eventDate', eventDate);
        setEventGroup(eventDate);
    };
   
    const actionsBtn = ['Schedule','Edit','Unschedule'];
    const createFeatureActionsConfig = (actionFeatures,actions)=>{
        const config = {};
        actionFeatures.forEach((ids, index)=>{
            config[ids] = actions[index]
        });
        return config;
    }
    const computeActiveFeature = () => {
        const buttonStateSetter = {
            'Schedule' : setCanShowSchedule,
            'Edit' : setCanShowEditSchedule,
            'Unschedule' : setCanShowUnschedule,
        }
        const actionButtonMap = createFeatureActionsConfig(actionFeatures,actionsBtn)
        let canShow = true;
        if (!user.isSuperUser && user?.role?.name !== 'Super User') {
            if (features?.docs?.length){
                features?.docs?.forEach((item)=>{
                    if(actionFeatures.includes(item?._id)){
                        const action = actionButtonMap[item?._id];
                        const status = item?.status;
                        if(buttonStateSetter[action]){
                            buttonStateSetter[action](status === 'active')
                        }
                    }
                })
                
            }
        } else {
            Object.values(buttonStateSetter).forEach((btn)=> btn(canShow));
        }
    };
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchEvents({ populate: true, sort: { startTime: 1 } }));
        
    }, []);
    useEffect(() => {
        if (event?.docs?.length) {
            computeEventDate();
            }
        computeActiveFeature();
    }, [event, features]);
    console.log('event group', eventGroup);
    console.log('event group', eventGroup.scheduled['10/05/2024']);

    const cols = [
        {
            Header: 'Event Name',
            accessor : 'name',
            Cell: ({ row }) => {
                return (
                    <span
                        style={{
                            color: `${row?.original?.committee?.hexCode}`,
                        }}
                    >
                        {row?.original?.name}
                    </span>
                );
            },
        },
        {
            Header : 'Subtitle',
            Cell: ({ row }) => {
                return (
                    <span
                        style={{
                            color: `${row?.original?.committee?.hexCode}`,
                        }}
                    >
                        {row?.original?.subTitle}
                    </span>
                );
            },
        },
        {
            Header: 'Committee',
            accessor: 'committee.name',
            Cell: ({ row }) => {
                return (
                    <span
                        style={{
                            color: `${row?.original?.committee?.hexCode}`,
                        }}
                    >
                        {row?.original?.committee?.name}
                    </span>
                );
            },
        },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row items-center gap-2">
                        {[
                            'Admin Head',
                            'Developer',
                            'Super User',
                            'HOD',
                        ].includes(user?.role?.name) && (
                            canShowSchedule &&
                            (<PrimaryButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setRequestData(row?.original);
                                    setShowApprovedModal(true);
                                }}
                            >
                                Schedule
                            </PrimaryButton>)
                        )}
                    </div>
                );
            },
        },
    ];
    const scheduleCols = [
        {
            Header: 'Event Name',
            Cell: ({ row }) => {
                return (
                    <span
                        style={{
                            color: `${row?.original?.committee?.hexCode}`,
                        }}
                    >
                        {row?.original?.name}
                    </span>
                );
            },
            cellStyle: {
                width: '20%',
            },
        },
        // {
        //     Header: 'Date',
        //     accessor: 'date',
        //     Cell: ({ row }) => {
        //         return (
        //             <span>
        //                 {moment(row?.original?.startTime).format('DD MMM YYYY')}
        //             </span>
        //         );
        //     },
        // },
        {
            Header: 'Start Time',
            accessor: 'startTime',
            Cell: ({ row }) => {
                console.log('color committee', row?.original);
                return (
                    <span
                        style={{
                            color: `${row?.original?.committee?.hexCode}`,
                        }}
                    >
                        {moment(row?.original?.startTime).format('h:mm a')}
                    </span>
                );
            },
            cellStyle: {
                width: '20%',
            },
        },
        {
            Header: 'End Time',
            accessor: 'endTime',
            Cell: ({ row }) => {
                return (
                    <span
                        style={{
                            color: `${row?.original?.committee?.hexCode}`,
                        }}
                    >
                        {moment(row?.original?.endTime).format('h:mm a')}
                    </span>
                );
            },
            cellStyle: {
                width: '20%',
            },
        },
        {
            Header: 'Venue',
            Cell: ({ row }) => {
                return (
                    <span
                        style={{
                            color: `${row?.original?.committee?.hexCode}`,
                        }}
                    >
                        {row?.original?.venue}
                    </span>
                );
            },
            cellStyle: {
                width: '20%',
            },
        },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row items-center gap-2">
                        {[
                            'Admin Head',
                            'Developer',
                            'Super User',
                            'HOD',
                        ].includes(user?.role?.name) && (
                            canShowEditSchedule &&
                            (<PrimaryButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setRequestData(row?.original);
                                    setShowEditModal(true);
                                }}
                            >
                                Edit
                            </PrimaryButton>)
                        )}
                        {[
                            'Admin Head',
                            'Developer',
                            'Super User',
                            'HOD',
                        ].includes(user?.role?.name) && (
                            canShowUnschedule &&
                            (<DangerButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    const unscheduledObj = {
                                        ...row?.original,
                                        status: 'unscheduled',
                                        runningStatus : 'upcoming',
                                        startTime: null,
                                        endTime: null,
                                        venue: null,
                                    };
                                    console.log('object', unscheduledObj);
                                    await dispatch(editEvent(unscheduledObj));
                                    await dispatch(
                                        fetchEvents({
                                            populate: true,
                                            sort: { startTime: 1 },
                                        })
                                    );
                                }}
                            >
                                Unschedule
                            </DangerButton>)
                        )}
                    </div>
                );
            },
            cellStyle: {
                width: '20%',
            },
        },
    ];

    return (
        <>
        {
        !['CL', 'ACL' , 'NCP Representative'].includes(user?.role?.name) ?
        (<PageWithCard
            heading={
                <>
                    <Link to={'/addSchedule'}>Schedule</Link>
                    <span className="text-slate-200 inline-block px-1">
                        <ChevronRight size={14} className="-mb-0.5" />{' '}
                    </span>
                    {selectedIndex === 0 && <span>{'Non-Scheduled'}</span>}
                    {selectedIndex === 1 && <span>{'Scheduled'}</span>}
                </>
            }
        >
            {selectedIndex === 0 && (
                <AddEventSchedule
                    requestData={requestData}
                    showApproveModal={showApproveModal}
                    setShowApproveModal={setShowApprovedModal}
                    setRequestData={setRequestData}
                />
            )}
            {selectedIndex === 1 && (
                <EditEventSchedule
                    requestData={requestData}
                    showApproveModal={showEditModal}
                    setShowApproveModal={setShowEditModal}
                    setRequestData={setRequestData}
                />
            )}
            <Tabs
                headers={['Non-Scheduled', 'Scheduled']}
                onTabChange={(index) => {
                    setSelectedIndex(index);
                }}
            >
                 <Tab>
                    <TableWithHeadingAndSearch
                        columns={cols}
                        data={eventGroup.unscheduled}
                        heading={''}
                    />
                </Tab>
                 <Tab>
                    {Object.keys(eventGroup.scheduled).map((ele) => (
                        <div className="mb-2">
                            <ShowDropdownForSchedule
                                heading={
                                    <div className="mb-2">
                                        <h1 className="text-slate-200 text-2xl font-medium px-2">
                                            {ele}
                                        </h1>
                                    </div>
                                }
                                allowedRoles={[
                                    'HOD',
                                    'Head',
                                    'Sub Head', 
                                    'Scorer',
                                    'Volunteer',
                                    'Admin Head',
                                    'Super User',
                                    'CL',
                                    'ACL',
                                    'Unapproved CL',
                                    'Developer',
                                    'NCP Representative',
                                ]}
                            >
                                <TableForScheduleEvent
                                    columns={scheduleCols}
                                    data={eventGroup.scheduled[ele]}
                                />
                            </ShowDropdownForSchedule>
                        </div>
                    ))}
                </Tab>
            </Tabs>
        </PageWithCard>)
        :
        (
            <PageWithCard heading={<Link to={'/addSchedule'}>Schedule</Link>}>
                <Tab>
                    {Object.keys(eventGroup.scheduled).map((ele) => (
                        <div className="mb-2">
                            <ShowDropdownForSchedule
                                heading={
                                    <div className="mb-2">
                                        <h1 className="text-slate-200 text-2xl font-medium px-2">
                                            {ele}
                                        </h1>
                                    </div>
                                }
                                allowedRoles={[
                                    'HOD',
                                    'Head',
                                    'Sub Head', 
                                    'Scorer',
                                    'Volunteer',
                                    'Admin Head',
                                    'Super User',
                                    'CL',
                                    'ACL',
                                    'Unapproved CL',
                                    'Developer',
                                    'NCP Representative',
                                ]}
                            >
                                <TableForScheduleEvent
                                    columns={scheduleCols}
                                    data={eventGroup.scheduled[ele]}
                                />
                            </ShowDropdownForSchedule>
                        </div>
                    ))}
                </Tab>
            </PageWithCard>
        )
    }
        </>
    );
};

export default AddSchedule;
