import React, { useEffect, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { Link, useParams } from 'react-router-dom';
import { ChevronRight, XSquare } from 'react-feather';
import SetButtons from '../../components/infrastructure/SetButtons';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { object, string } from 'yup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import { useDispatch, useSelector } from 'react-redux';
import {
    createWebsiteCreativeData,
    fetchWebsiteCreativesData,
    getWebsiteCreatives,
} from '../../app/reducers/WebsitesCreatives/websitesCreativeSlice';
import FormikLabel from '../../components/formik/FormikLabel';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import ShowDropdownForSchedule from '../../components/infrastructure/ShowDropdownForSchedule';
import FormikDraggableProvider from '../../components/formik/FormikDraggableProvider';
import { getFeatures } from '../../app/reducers/Features/featuresSlice';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import FormikDirectImageUpload from '../../components/formik/FormikDirectImageUpload';
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload';

const Default = ({canShowDefaultUpdate}) => {
    const { user } = useSelector(getAuth);
    console.log('user', user)
    console.log('update can show ?', canShowDefaultUpdate)
    const { pages } = useParams();
    const { websiteCreatives } = useSelector(getWebsiteCreatives);
    const elementEditData = websiteCreatives[0];
    const dispatch = useDispatch();
    
    const computeInitialValue = (data) => {
        if (data) {
            return data;
        } else {
            return {
                name: pages,
                highlightedText: '',
                normalText: '',
                websiteBackGroundColor: '',
                headingColor: '',
                bubbleText:'',
                internalId: '',
                externalClId: '',
                externalAclId: '',
                pop: '',
                popElimination: '',
                externalCertificates: '',
                internalCertificates: '',
                brochure: '',
                banner : '',
                scheduleIcon:'',
                footerContent: [
                    {
                        icon: '',
                        title: '',
                        link: '',
                    },
                ],
                notActiveText:'',
            };
        }
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: computeInitialValue(elementEditData),
        onSubmit: (values) => {
            console.log('creatives values', values);
            const obj = {
                ...values,
                name: pages,
            };
            console.log('object', obj);
            dispatch(createWebsiteCreativeData(obj));
        },
    });
    useEffect(() => {
        dispatch(fetchWebsiteCreativesData({ name: 'Default' }));
    }, []);
    return (
        <>
            <div className="mb-2">
                <h1 className="text-slate-200 text-xl font-medium px-2">
                    Text color
                </h1>
            </div>
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md w-full md:w-[50%] mb-3"
            >
                <div className="w-full flex flex-row justify-between items-center">
                    <div className='w-[50%]'>
                    <FormikLabel label="Highlighted Text" />
                    </div>
                    <div className='w-[50%]'>
                    <FormikInputGroup
                            name="highlightedText"
                            formik={formik}
                        />
                    </div>
                </div>
                <div className="w-full flex flex-row justify-between items-center">
                    <div className='w-[50%]'>
                    <FormikLabel label="Normal Text" />
                    </div>
                    <div className='w-[50%]'>
                    <FormikInputGroup name="normalText" formik={formik} />
                    </div>
                </div>
                <div className="w-full flex flex-row justify-between items-center">
                    <div className='w-[50%]'>
                    <FormikLabel label="WebSite BG Color" />
                    </div>
                    <div className='w-[50%]'>
                    <FormikInputGroup
                            name="websiteBackGroundColor"
                            formik={formik}
                        />
                    </div>
                </div>
                <div className="w-full flex flex-row justify-between items-center">
                    <div className='w-[50%]'>
                    <FormikLabel label="Heading Color" />
                    </div>
                    <div className='w-[50%]'>
                        <FormikInputGroup name="headingColor" formik={formik} />
                    </div>
                </div>
                <div className="w-full flex flex-row justify-between items-center">
                    <div className='w-[50%]'>
                    <FormikLabel label="Bubble Text" />
                    </div>
                    <div className='w-[50%]'>
                    <FormikInputGroup name="bubbleText" formik={formik} />
                    </div>
                </div>
                {/* <div className=" flex gap-2">
                    <div className="w-1/2 flex flex-col gap-4 items-start justify-center">
                        <FormikLabel label="Highlighted Text" />
                        <FormikLabel label="Normal Text" />
                        <FormikLabel label="WebSite BG Color" />
                        <FormikLabel label="Heading Color" />
                        <FormikLabel label="Bubble Text" />
                    </div>
                    <div className="w-1/2 flex flex-col gap-2 items-start justify-center">
                        <FormikInputGroup
                            name="highlightedText"
                            formik={formik}
                        />
                        <FormikInputGroup name="normalText" formik={formik} />
                        <FormikInputGroup
                            name="websiteBackGroundColor"
                            formik={formik}
                        />
                        <FormikInputGroup name="headingColor" formik={formik} />
                        <FormikInputGroup name="bubbleText" formik={formik} />
                    </div>
                </div> */}
                { ['Super User','HOD','Admin Head','Developer'].includes(user?.role?.name) && (canShowDefaultUpdate && <div className="text-center mt-4">
                    <DangerButton type="submit">Update</DangerButton>
                </div>)}
            </form>
            {<div className="mb-3">
                <ShowDropdownForSchedule
                    heading={
                        <div className="mb-2">
                            <h1 className="text-slate-200 text-xl font-medium px-2">
                                Templates
                            </h1>
                        </div>
                    }
                    allowedRoles={['Super User','HOD','Admin Head','Developer']}
                >

                    <form
                        onSubmit={formik.handleSubmit}
                        className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md w-full md:w-[50%] mb-3"
                    >
                        <div className="w-full flex flex-row justify-between items-center">
                            <FormikLabel label="Internal ID" />
                            <div className="w-[50%] md:w-[70%]">
                                <FormikDirectImageUpload
                                    formik={
                                        formik
                                    }
                                    name='internalId'
                                    location={`1648_entertainment/websitesCreatives/templates/`}
                                    randomize
                                />
                            </div>
                        </div>
                        <div className="w-full flex flex-row justify-between items-center" >
                            <FormikLabel label="External Cl ID" />
                            <div className="w-[50%] md:w-[70%]">
                                <FormikDirectImageUpload
                                    formik={
                                        formik
                                    }
                                    name='externalClId'
                                    location={`1648_entertainment/websitesCreatives/templates/`}
                                    randomize
                                />
                            </div>
                        </div>
                        <div className="w-full flex flex-row justify-between items-center" >
                            <FormikLabel label="External Acl ID" />
                            <div className="w-[50%] md:w-[70%]">
                                <FormikDirectImageUpload
                                    formik={
                                        formik
                                    }
                                    name='externalAclId'
                                    location={`1648_entertainment/websitesCreatives/templates/`}
                                    randomize
                                />
                            </div>
                        </div>
                        <div className="w-full flex flex-row justify-between items-center">
                            <FormikLabel label="POP" />
                            <div className="w-[50%] md:w-[70%]">
                                <FormikDirectImageUpload
                                    formik={
                                        formik
                                    }
                                    name='pop'
                                    location={`1648_entertainment/websitesCreatives/templates/`}
                                    randomize
                                />
                            </div>
                        </div>
                        <div className="w-full flex flex-row justify-between items-center">
                            <FormikLabel label="POP Elimination" />
                            <div className="w-[50%] md:w-[70%]">
                                <FormikDirectImageUpload
                                    formik={
                                        formik
                                    }
                                    name='popElimination'
                                    location={`1648_entertainment/websitesCreatives/templates/`}
                                    randomize
                                />
                            </div>
                        </div>
                        <div className="w-full flex flex-row justify-between items-center">
                            <FormikLabel label="External Certificates" />
                            <div className="w-[50%] md:w-[70%]">
                                <FormikDirectImageUpload
                                    formik={
                                        formik
                                    }
                                    name='externalCertificates'
                                    location={`1648_entertainment/websitesCreatives/templates/`}
                                    randomize
                                />
                            </div>
                        </div>
                        <div className="w-full flex flex-row justify-between items-center">
                            <FormikLabel label="Internal Certificates" />
                            <div className="w-[50%] md:w-[70%]">
                                <FormikDirectImageUpload
                                    formik={
                                        formik
                                    }
                                    name='internalCertificates'
                                    location={`1648_entertainment/websitesCreatives/templates/`}
                                    randomize
                                />
                            </div>
                        </div>
                        { ['Super User','HOD','Admin Head','Developer'].includes(user?.role?.name) && (canShowDefaultUpdate && <div className="text-center mt-4">
                            <DangerButton type="submit">Update</DangerButton>
                        </div>)}
                    </form>
                </ShowDropdownForSchedule>

            </div>
            }
            {
                 <div className="mb-3">
                 <ShowDropdownForSchedule
                     heading={
                         <div className="mb-2">
                             <h1 className="text-slate-200 text-xl font-medium px-2">
                                Brochure
                             </h1>
                         </div>
                     }
                     allowedRoles={['Super User','HOD','Admin Head','Developer']}
                 >
                    <form
                        onSubmit={formik.handleSubmit}
                        className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md w-full md:w-[50%] mb-3"
                    >
                        <div className="w-full flex flex-row justify-between items-center">
                            <FormikLabel label="Brochure" />
                            <div className="w-[50%] md:w-[70%]">
                                <FormikDirectFileUpload
                                    formik={
                                        formik
                                    }
                                    name='brochure'
                                    location={`1648_entertainment/websitesCreatives/brochure/`}
                                    randomize
                                />
                            </div>
                        </div>
                        { ['Super User','HOD','Admin Head','Developer'].includes(user?.role?.name) && (canShowDefaultUpdate && <div className="text-center mt-4">
                            <DangerButton type="submit">Update</DangerButton>
                        </div>)}
                    </form>
                 </ShowDropdownForSchedule>
                 </div>
            }
            {
                 <div className="mb-3">
                 <ShowDropdownForSchedule
                     heading={
                         <div className="mb-2">
                             <h1 className="text-slate-200 text-xl font-medium px-2">
                                Login & Registration
                             </h1>
                         </div>
                     }
                     allowedRoles={['Super User','HOD','Admin Head','Developer']}
                 >
                    <form
                        onSubmit={formik.handleSubmit}
                        className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md w-full md:w-[50%] mb-3"
                    >
                        <div className="w-full flex flex-row justify-between items-center">
                            <FormikLabel label="Banner" />
                            <div className="w-[50%] md:w-[70%]">
                                <FormikDirectImageUpload
                                    formik={
                                        formik
                                    }
                                    name='banner'
                                    location={`1648_entertainment/websitesCreatives/login&registration/`}
                                    randomize
                                />
                            </div>
                        </div>
                       {['Super User','HOD','Admin Head','Developer'].includes(user?.role?.name) && (canShowDefaultUpdate &&  <div className="text-center mt-4">
                            <DangerButton type="submit">Update</DangerButton>
                        </div>)}
                    </form>
                 </ShowDropdownForSchedule>
                 </div>
            }
            {
                 <div className="mb-3">
                 <ShowDropdownForSchedule
                     heading={
                         <div className="mb-2">
                             <h1 className="text-slate-200 text-xl font-medium px-2">
                                Schedule Icon
                             </h1>
                         </div>
                     }
                     allowedRoles={['Super User','HOD','Admin Head','Developer']}
                 >
                    <form
                        onSubmit={formik.handleSubmit}
                        className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md w-full md:w-[50%] mb-3"
                    >
                        <div className="w-full flex flex-row justify-between items-center">
                            <FormikLabel label="Icon" />
                            <div className="w-[50%] md:w-[70%]">
                                <FormikDirectImageUpload
                                    formik={
                                        formik
                                    }
                                    name='scheduleIcon'
                                    location={`1648_entertainment/websitesCreatives/login&registration/`}
                                    randomize
                                />
                            </div>
                        </div>
                        { ['Super User','HOD','Admin Head','Developer'].includes(user?.role?.name) && (canShowDefaultUpdate && <div className="text-center mt-4">
                            <DangerButton type="submit">Update</DangerButton>
                        </div>)}
                    </form>
                 </ShowDropdownForSchedule>
                 </div>
            }
            {
                <div className="mb-3">
                    <ShowDropdownForSchedule
                        heading={
                            <div className="mb-2">
                                <h1 className="text-slate-200 text-xl font-medium px-2">
                                    Footer
                                </h1>
                            </div>
                        }
                        allowedRoles={['Super User','HOD','Admin Head','Developer']}
                    >
                        <form
                            onSubmit={formik.handleSubmit}
                            className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md w-full md:w-[50%]"
                        >
                            <div>
                                <FormikProvider
                                    value={formik}
                                    name={'footerContent'}
                                >
                                    <FieldArray
                                        name="footerContent"
                                        render={(arrayHelpers) => {
                                            return (
                                                <div className="flex flex-col my-2 gap-2 rounded-lg">
                                                    {formik?.values?.footerContent?.map(
                                                        (ele, index) => (
                                                            <div
                                                                className="mb-2"
                                                                key={index}
                                                            >
                                                                <div className="relative">
                                                                    
                                                                            <XSquare
                                                                                className=" right-0 text-red-500"
                                                                                onClick={() =>
                                                                                    arrayHelpers.remove(
                                                                                        index
                                                                                    )
                                                                                }
                                                                            />
                                                                        
                                                                    <div className="flex flex-col gap-2 text-slate-200">
                                                                        <div className="w-full flex flex-row justify-between items-center">
                                                                            <FormikLabel label="Logo" />
                                                                            <div className="w-[70%]">
                                                                                <FormikDirectImageUpload
                                                                                    formik={
                                                                                        formik
                                                                                    }
                                                                                    name={`footerContent.${index}.icon`}
                                                                                    location={`1648_entertainment/websitesCreatives/footer/icon/`}
                                                                                    randomize
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="w-full flex flex-row justify-between items-center">
                                                                            <FormikLabel label="Title" />
                                                                            <div className="w-[70%]">
                                                                                <FormikInputGroup
                                                                                    formik={
                                                                                        formik
                                                                                    }
                                                                                    name={`footerContent.${index}.title`}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="w-full flex flex-row justify-between items-center">
                                                                            <FormikLabel label="Link" />
                                                                            <div className="w-[70%]">
                                                                                <FormikInputGroup
                                                                                    formik={
                                                                                        formik
                                                                                    }
                                                                                    name={`footerContent.${index}.link`}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                    <div>
                                                        <PrimaryButton
                                                            type="button"
                                                            onClick={() => {
                                                                arrayHelpers.push(
                                                                    {}
                                                                );
                                                            }}
                                                        >
                                                            Add more
                                                        </PrimaryButton>
                                                    </div>
                                                </div>
                                            );
                                        }}
                                    />
                                </FormikProvider>
                            </div>
                            { ['Super User','HOD','Admin Head','Developer'].includes(user?.role?.name) && (canShowDefaultUpdate && <div className="text-center ">
                                <DangerButton type="submit">
                                    Update
                                </DangerButton>
                            </div>)}
                        </form>
                    </ShowDropdownForSchedule>
                </div>
            }
            {
                 <div className="mb-3">
                 <ShowDropdownForSchedule
                     heading={
                         <div className="mb-2">
                             <h1 className="text-slate-200 text-xl font-medium px-2">
                                Feature Not Active Text
                             </h1>
                         </div>
                     }
                     allowedRoles={['Super User','HOD','Admin Head','Developer']}
                 >
                    <form
                        onSubmit={formik.handleSubmit}
                        className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md w-full md:w-[50%] mb-3"
                    >
                        <div className="w-full flex flex-row justify-between items-center">
                            <FormikLabel label="Text" />
                            <div className="w-[50%] md:w-[70%]">
                                <FormikInputGroup
                                    formik={
                                        formik
                                    }
                                    name='notActiveText'                                    
                                />
                            </div>
                        </div>
                        { ['Super User','HOD','Admin Head','Developer'].includes(user?.role?.name) && (canShowDefaultUpdate && <div className="text-center mt-4">
                            <DangerButton type="submit">Update</DangerButton>
                        </div>)}
                    </form>
                 </ShowDropdownForSchedule>
                 </div>
            }
        </>
    );
};

export default Default;
