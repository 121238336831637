import React, { useState } from 'react';
import { Eye, EyeOff } from 'react-feather';

const PasswordToggle = ({ field, form, ...props }) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="relative w-full">
            <input
                {...field}
                {...props}
                type={showPassword ? 'text' : 'password'}
                className="w-full form-input border-0 rounded-md text-slate-100"
                style={{
                    backgroundColor: '#141414'
                }}
            />
            <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center px-2 text-sm text-slate-400 hover:text-slate-200 focus:outline-none"
                onClick={togglePasswordVisibility}
            >
                {showPassword ? <Eye size={14}  /> : <EyeOff size={14} />}
            </button>
        </div>
    );
};

export default PasswordToggle;
