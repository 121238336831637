import React, { useMemo } from 'react';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import { useFormik } from 'formik';
import { generateOptions } from '../../utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import {
    fetchRegistrationRequests,
    getRegistrationRequests,
} from '../../app/reducers/RegistrationRequest/registrationRequestSlice';
import { ClipLoader } from 'react-spinners';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { STATUS_TYPE } from '../../utils/dropdownOptions';

const FilterRegistrationCommittee = ({ loading }) => {
    const { committee } = useSelector(getCommittees);
    const { registrationRequest } = useSelector(getRegistrationRequests);
    0;
    const dispatch = useDispatch();
    11;
    const formik = useFormik({
        initialValues: {
            committee: [],
            status: [],
        },
        onSubmit: (values, { resetForm }) => {
            console.log('filter values', values);
            dispatch(
                fetchRegistrationRequests({
                    populate: true,
                    // status : 'pending'
                    $or: [
                        { 'committee1._id': { $in: values.committee } },
                        // { 'committee2._id': { $in: values.committee } },
                        // { 'committee3._id': { $in: values.committee } },
                        { status: { $in: values.status } },
                    ],
                })
            );
        },
    });
    return (
        <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col gap-3 mb-6"
        >
            <FormikMultiSelect
                name="committee"
                label="Filter By Committee"
                formik={formik}
                options={generateOptions({
                    array: committee ? committee?.docs : [],
                    labelField: 'name',
                    valueField: '_id',
                })}
            />
            <FormikMultiSelect
                name="status"
                label="Filter By Status"
                formik={formik}
                options={STATUS_TYPE}
            />
            {loading ? (
                <ClipLoader color="#FFFFFF" size={18} />
            ) : (
                <div>
                    <PrimaryButton type="submit">Summit</PrimaryButton>
                </div>
            )}
        </form>
    );
};

export default FilterRegistrationCommittee;
