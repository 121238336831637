import React from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useFormik } from 'formik';
import { object, ref, string } from 'yup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useDispatch } from 'react-redux';
import { editRegistrationRequestUserPass } from '../../app/reducers/RegistrationRequest/registrationRequestSlice';

const ChangePassword = ({
    requestedData,
    showChangePasswordModal,
    setShowChangePasswordModal,
}) => {
    // console.log('req data', requestedData);
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: object({
            password: string().required(),
            confirmPassword: string()
                .required()
                .oneOf([ref('password')], 'Password must be same'),
        }),
        onSubmit: async (values) => {
            console.log('change pass', values);
            await dispatch(
                editRegistrationRequestUserPass({
                    username: requestedData?.username,
                    password: values?.password,
                })
            );
            setShowChangePasswordModal(false)
        },
    });
    return (
        <ModalBasic
            modalOpen={showChangePasswordModal}
            setModalOpen={setShowChangePasswordModal}
        >
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4  bg-black p-4 rounded sm:rounded-md"
            >
                <FormikInputGroup
                    autocomplete="new-password"
                    name="password"
                    type="password"
                    label="Password"
                    formik={formik}
                    required
                />
                <FormikInputGroup
                    name="confirmPassword"
                    formik={formik}
                    label="Confirm Password"
                    required
                    type="password"
                />
                <div className="text-center">
                    <PrimaryButton type="submit" className="w-[25%]">
                        Submit
                    </PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default ChangePassword;
