import React, { useEffect, useMemo, useState } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { array, object, string } from 'yup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { getColleges } from '../../app/reducers/College/collegeSlice';
import {
    createParticipant,
    editParticipant,
    fetchParticipant,
    fetchParticipants,
    getParticipants,
    resetParticipant,
} from '../../app/reducers/Participant/teamParticipantSlice';
import { ClipLoader } from 'react-spinners';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import {
    fetchEvent,
    fetchEvents,
    getEvents,
} from '../../app/reducers/Event/eventSlice';
import { useParams } from 'react-router-dom';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { toast } from 'react-toastify';
import FormikINPhoneInput from '../../components/formik/FormikINPhoneInput';
import UniqCodeModal from './UniqCodeModal';

const OtseParticipant = ({
    requestData,
    showOtseModal,
    setShowOtseModal,
    computeOtseParticipants,
}) => {
    const { user } = useSelector(getAuth);
    const { id, eventId } = useParams();
    const [otseParticipants, setOtseParticipants] = useState([]);
    const [collegeName, setCollegeName] = useState({});
    const { teamParticipant } = useSelector(getParticipants);
    const { event, elementEditData } = useSelector(getEvents);
    const [data, setData] = useState({});
    const [showCodeModal, setShowCodeModal] = useState(true);
    const [existingContactNumbers, setExistingContactNumbers] = useState([]);
    const dispatch = useDispatch();

    const collegeNameId = async () => {
        const collegePayload = { username: user?.username };
        const collegeString = QueryString.stringify(collegePayload);
        const resp = await authAxiosInstance.get(
            `/users/employee?${collegeString}`
        );
        const collegeResp = resp?.data?.data?.docs[0];
        console.log('college', collegeResp);
        setCollegeName(collegeResp);
    };
    const computeOtseTeamMaxParticipants = async () => {
        const eventPayload = {
            event: eventId,
            type: 'otse',
            college: user?.college?._id,
        };
        const stringData = QueryString.stringify(eventPayload);
        const resp = await authAxiosInstance.get(
            `/teamParticipant?${stringData}`
        );
        const eventData = resp?.data?.data?.docs[0]?.participants;
        setOtseParticipants(eventData);
    };
    useEffect(() => {
        const fetchExistingParticipants = async () => {
            const participantPayload = {
                event: eventId,
                type: 'otse',
                college: user?.college?._id,
            };
            const stringParticipantData =
                QueryString.stringify(participantPayload);
            const respParticipant = await authAxiosInstance.get(
                `/teamParticipant?${stringParticipantData}`
            );
            const participants = respParticipant?.data?.data?.docs || [];
            setExistingContactNumbers(participants.map((p) => p.contactNumber));
        };

        fetchExistingParticipants();
    }, [eventId]);

    const formik = useFormik({
        initialValues: {
            participants: [
                {
                    firstName: '',
                    lastName: '',
                    contactNumber: '',
                },
            ],
        },
        validationSchema: object({
            participants: array().of(
                object({
                    firstName: string()
                        .matches(
                            /^[A-Z][a-z]*$/,
                            'Case sensitive & can not contain spaces. i.e. Hello'
                        )
                        .label('First Name')
                        .required(),
                    lastName: string()
                        .matches(
                            /^[A-Z][a-z]*$/,
                            'Case sensitive & can not contain spaces. i.e. Hello'
                        )
                        .label('Last Name')
                        .required(),
                    contactNumber: string()
                        .matches(
                            /^[0-9]{10}$/,
                            'Contact Number must be numbers (exact 10 digits)'
                        )
                        .min(10, 'Min 10 digits')
                        .max(10, 'Max 10 digits')
                        .label('Contact Number')
                        .required(),
                })
            ),
        }),
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            try {
                const newContactNumbers = values.participants.map(
                    (p) => p.contactNumber
                );
                const duplicateNewContacts = newContactNumbers.filter(
                    (item, index) => newContactNumbers.indexOf(item) !== index
                );
                if (duplicateNewContacts.length > 0) {
                    toast.error(
                        'Some contact numbers are duplicated in the added participants .'
                    );
                    setSubmitting(false);
                    return;
                }

                const duplicateContacts = values.participants.filter(
                    (participant) =>
                        existingContactNumbers.includes(
                            participant.contactNumber
                        )
                );

                if (duplicateContacts.length > 0) {
                    toast.error(
                        'Some contact numbers are already registered for this event.'
                    );
                    setSubmitting(false);
                    return;
                }

                const eventPayload = { _id: eventId };
                const stringData = QueryString.stringify(eventPayload);
                const resp = await authAxiosInstance.get(
                    `/event?${stringData}`
                );
                const eventSingleData = resp?.data?.data?.docs[0];
                console.log('event data', eventSingleData);
                const participantPayload = {
                    event: eventId,
                    status: 'approved',
                };
                const stringParticipantData =
                    QueryString.stringify(participantPayload);
                const respParticipant = await authAxiosInstance.get(
                    `/teamParticipant?${stringParticipantData}`
                );

                const totalParticipants = respParticipant?.data?.data?.docs;

                if (elementEditData?.teamMinCapacity === null) {
                    toast.error(`Min Participants capacity require`);
                    return;
                }

                if (
                    values?.participants?.length >=
                    elementEditData?.teamMinCapacity
                ) {
                    if (
                        eventSingleData?.teamMaxRegistration >
                        totalParticipants?.length
                    ) {
                        let obj = {
                            collegeCode: '',
                            college: collegeName?.college,
                            event: eventId,
                            type: 'otse',
                            participants: values?.participants,
                            status: 'approved',
                            mainCode: user?.username,
                        };

                        const resp = await dispatch(createParticipant(obj));
                        await dispatch(
                            fetchParticipants({
                                event: eventId,
                                college: user?.college?._id,
                            })
                        );
                        await dispatch(fetchEvent({ _id: eventId }));
                        computeOtseParticipants();
                        setShowOtseModal(false);
                        resetForm();
                        console.log('resp', resp);
                        if (resp?.payload?.success) {
                            console.log('data', resp?.payload?.data?.data);
                            setData(resp?.payload?.data?.data);
                            setShowCodeModal(true);
                        }
                    } else {
                        toast.error(`Team Slots are full !!!`);
                    }
                } else {
                    toast.error(
                        ` Alert! It seems you haven't met the minimum participant (${elementEditData?.teamMinCapacity})
                    requirement for this event. Please double-check and 
                    ensure all criteria are fulfilled accordingly.`
                        // `Min Participants ${elementEditData?.teamMinCapacity} require`
                    );
                }
            } catch (error) {
                console.log('error', error);
            } finally {
                setSubmitting(false);
            }
        },
    });
    useEffect(() => {
        if (eventId) {
            dispatch(
                fetchParticipants({
                    event: eventId,
                    college: user?.college?._id,
                })
            );
            computeOtseTeamMaxParticipants();
            collegeNameId();
        }
    }, [id, eventId, JSON.stringify(formik?.values?.participants)]);

    return (
        <>
            <UniqCodeModal
                data={data}
                showCodeModal={showCodeModal}
                setShowCodeModal={setShowCodeModal}
            />
            <ModalBasic
                modalOpen={showOtseModal}
                setModalOpen={setShowOtseModal}
            >
                {/* {elementEditData?.teamMinCapacity > otseParticipants?.length && ( */}
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4 pb-4 m-4"
                >
                    <FormikProvider value={formik}>
                        <FieldArray
                            name="participants"
                            render={(arrayHelpers) => {
                                return (
                                    <div className="flex flex-col gap-2">
                                        <div>
                                            {formik.values.participants.map(
                                                (ele, index) => (
                                                    <div
                                                        className="relative mb-2 flex flex-col gap-4"
                                                        style={{
                                                            border: '1px solid #475569',
                                                            borderRadius: '5px',
                                                            padding: '16px',
                                                        }}
                                                        key={index}
                                                    >
                                                        <div className="flex flex-col gap-4 sm:flex-row w-full">
                                                            <div className="w-full">
                                                                <FormikInputGroup
                                                                    required
                                                                    formik={
                                                                        formik
                                                                    }
                                                                    label="First Name"
                                                                    name={`participants.${index}.firstName`}
                                                                />
                                                            </div>
                                                            <div className="w-full">
                                                                <FormikInputGroup
                                                                    required
                                                                    formik={
                                                                        formik
                                                                    }
                                                                    label="Last Name"
                                                                    name={`participants.${index}.lastName`}
                                                                />
                                                            </div>
                                                            <div className="w-full">
                                                                <FormikINPhoneInput
                                                                    required
                                                                    formik={
                                                                        formik
                                                                    }
                                                                    name={`participants.${index}.contactNumber`}
                                                                    label="Phone No."
                                                                />
                                                            </div>
                                                        </div>
                                                        {index != 0 && (
                                                            <div>
                                                                <DangerButton
                                                                    className="mt-3"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        arrayHelpers.remove(
                                                                            index
                                                                        );
                                                                    }}
                                                                    type="button"
                                                                >
                                                                    Remove
                                                                </DangerButton>
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        {elementEditData?.teamMaxCapacity > 1 &&
                                            formik?.values?.participants
                                                ?.length !==
                                                elementEditData?.teamMaxCapacity && (
                                                <div>
                                                    <PrimaryButton
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            arrayHelpers.push(
                                                                {}
                                                            );
                                                        }}
                                                        type="button"
                                                    >
                                                        Add More Participant
                                                    </PrimaryButton>
                                                </div>
                                            )}
                                    </div>
                                );
                            }}
                        />
                    </FormikProvider>
                    <div>
                        <PrimaryButton
                            type="submit"
                            disabled={formik.isSubmitting}
                        >
                            Save
                        </PrimaryButton>
                    </div>
                </form>
                {/* //    )} */}
            </ModalBasic>
        </>
    );
};

export default OtseParticipant;
