import AddCollege from '../pages/College/AddCollege';
import EditCollege from '../pages/College/EditCollege';
import ViewCollege from '../pages/College/ViewCollege';
import Dashboard from '../pages/Dashboard';
import AddFestival from '../pages/Festival/AddFestival';
import EditFestival from '../pages/Festival/EditFestival';
import ViewFestival from '../pages/Festival/ViewFestival';
import MediaLibrary from '../pages/MediaLibrary/MediaLibrary';
import AddRole from '../pages/Role/AddRole';
import EditRole from '../pages/Role/EditRole';
import ViewRole from '../pages/Role/ViewRole';
import AddEmployee from '../pages/Users/AddEmployee';
import EditEmployee from '../pages/Users/EditEmployee';
import EditEmployeePage from '../pages/Users/EditEmployeePage';
import ViewEmployees from '../pages/Users/ViewEmployees';
import AddCommittee from '../pages/Committee/AddCommittee';
import EditCommittee from '../pages/Committee/EditCommittee';
import ViewCommittee from '../pages/Committee/ViewCommittee';
import GeneralBulkUpload from '../pages/generalBulkUpload/GeneralBulkUpload';
import ViewRegistrationRequest from '../pages/RegistrationRequest/ViewRegistrationRequest';
import ViewShortlistRegistrationRequest from '../pages/ShortlistRegistrationRequest/ShortlistRegistrationRequest';
import AddFeatures from '../pages/Features/AddFeatures';
import ViewFeatures from '../pages/Features/ViewFeatures';
import { ROLES } from './roles';
import EditFeature from '../pages/Features/EditFeature';
import AddEvents from '../pages/Events/AddEvents';
import ViewEvents from '../pages/Events/ViewEvents';
import AddScoringTemplate from '../pages/ScoringTemplate/AddScoringTemplate';
import ViewScoringTemplate from '../pages/ScoringTemplate/ViewScoringTemplate';
import EditScoringTemplate from '../pages/ScoringTemplate/EditScoringTemplate';
import ApproveVolunteer from '../pages/ApproveVolunteer/ApproveVolunteer';
import CommonPool from '../pages/CommonPool/CommonPool';
import CoreApproval from '../pages/CoreApproval/CoreApproval';
import Event from '../pages/Committee/Event';
import NonEvent from '../pages/Committee/NonEvent';
import CollegesRequest from '../pages/CollegesRequest/CollegesRequest';
import GreetPage from '../pages/ExternalGreetPage';
import AclRegistration from '../pages/AclRegistration/AclRegistration';
import AddParticipant from '../pages/Participant/AddParticipant';
import AllRegistration from '../pages/AllRegistration/AllRegistration';
import AddSchedule from '../pages/Schedule/AddSchedule';
import ViewParticipants from '../pages/Participant/ViewParticipants';
import NcpRegistration from '../pages/NcpRegistration/NcpRegistration';
import NcpParticipant from '../pages/Participant/NcpParticipant';
import ViewAllEmployees from '../pages/Users/ViewAllEmployees';
import EditAllEmployeePage from '../pages/Users/EditAllEmployeePage';
import WebsiteCreatives from '../pages/WebsiteCreatives/WebsiteCreatives';
import Default from '../pages/WebsiteCreatives/Default';
import Scoreboard from '../pages/Scoreboard/Scoreboard';
import EventScoreboard from '../pages/Scoreboard/EventScoreboard';
import ViewScorecard from '../pages/Scoreboard/ViewScorecard';
import CLEventSetup from '../pages/WarOfCL/CLEventSetup';
import WarOfCLRegistration from '../pages/WarOfCL/WarOfCLRegistration';
import WarOfClParticipants from '../pages/WarOfCL/WarOfClParticipants';
import Printable from '../pages/Printable/Printable';


export const routes = [
    {
        route: '/',
        element: <Dashboard actionFeatures={['66cebb4b952e94f67242f917']} />,
        allowedRoles: ROLES.ALL,
        allowedFeatures: [],
    },
    {
        route: '/users/add-employee',
        element: <AddEmployee />,
        allowedRoles: ['Super User','Developer'],
        allowedFeatures: [],
    },
    {
        route: '/users/edit-employee',
        element: <EditEmployee />,
        allowedRoles: ['Super User','Developer'],
        allowedFeatures: [],
    },
    {
        route: '/users/editEmployee/:id',
        element: <EditEmployeePage />,
        allowedRoles: ['Super User','Developer'],
        allowedFeatures: [],
    },
    {
        route: '/users/editAllEmployee/:id',
        element: <EditAllEmployeePage />,
        allowedRoles: ['Super User','Developer'],
        allowedFeatures: [],
    },
    {
        route: '/users/view-employee',
        element: <ViewEmployees />,
        allowedRoles: ['Super User','Developer'],
        allowedFeatures: [],
    },
    {
        route: '/users/view-all-employee',
        element: <ViewAllEmployees />,
        allowedRoles: ['Super User','Developer'],
        allowedFeatures: [],
    },
    {
        route: '/college/add',
        element: <AddCollege />,
        allowedRoles: ['Super User','Developer'],
        allowedFeatures: [],
    },
    {
        route: '/college/view',
        element: <ViewCollege />,
        allowedRoles: ['Super User','Developer'],
        allowedFeatures: [],
    },
    {
        route: '/college/edit/:id',
        element: <EditCollege />,
        allowedRoles: ['Super User','Developer'],
        allowedFeatures: [],
    },
    {
        route: '/festival/add',
        element: <AddFestival />,
        allowedRoles: ['Super User','Developer'],
        allowedFeatures: [],
    },
    {
        route: '/festival/view',
        element: <ViewFestival />,
        allowedRoles: ['Super User','Developer'],
        allowedFeatures: [],
    },
    {
        route: '/festival/edit/:id',
        element: <EditFestival />,
        allowedRoles: ['Super User','Developer'],
        allowedFeatures: [],
    },
    {
        route: '/mediaLibrary',
        element: <MediaLibrary />,
        allowedRoles: ['Developer'],
        allowedFeatures: [],
    },
    {
        route: '/mediaLibrary/*',
        element: <MediaLibrary />,
        allowedRoles: ['Developer'],
        allowedFeatures: [],
    },
    {
        route: '/role/add',
        element: <AddRole />,
        allowedRoles: ['Developer'],
        allowedFeatures: [],
    },
    {
        route: '/role/view',
        element: <ViewRole />,
        allowedRoles: ['Developer'],
        allowedFeatures: [],
    },
    {
        route: '/role/edit/:id',
        element: <EditRole />,
        allowedRoles: ['Developer'],
        allowedFeatures: [],
    },
    {
        route: '/committee/add',
        element: <AddCommittee />,
        allowedRoles: ['Super User','Developer'],
        allowedFeatures: [],
    },
    {
        route: '/committee/edit/:id',
        element: <EditCommittee />,
        allowedRoles: ['Developer','Super User'],
        allowedFeatures: [],
    },
    {
        route: '/committee/view',
        element: <ViewCommittee />,
        allowedRoles: ['Super User','Developer'],
        allowedFeatures: [],
    },
    {
        route : '/aclRegistration',
        element :<AclRegistration actionFeatures={['6687a520afe55b15084a71b7','6687a53fafe55b15084a71ba']} />,
        allowedRoles : ['CL','Developer'],
        allowedFeatures :[]
    },
    {
        route : '/committee/event',
        element : <Event actionFeatures={['6666faf0855b40c9de51c4b0']}/>,
        allowedRoles : ['Super User','HOD','CL','Developer'],
        allowedFeatures : [],
    },
    {
        route : '/committee/non-event',
        element : <NonEvent actionFeatures={['6666faf0855b40c9de51c4b0']} />,
        allowedRoles : ['Super User','HOD','Developer'],
        allowedFeatures : [],
    },
    {
        route: '/features/add',
        element: <AddFeatures />,
        allowedRoles: ['Developer'],
        allowedFeatures: [],
    },
    {
        route: '/features/edit/:id',
        element: <EditFeature />,
        allowedRoles: ['HOD', 'Super User','Developer'],
        allowedFeatures: [],
    },
    {
        route: '/features/view',
        element: <ViewFeatures />,
        allowedRoles: ['HOD', 'Super User','Developer'],
        allowedFeatures: [],
    },
    {
        route :'/event/add',
        element : <AddEvents />,
        allowedRoles : ['Super User','Developer'],
        allowedFeatures : []
    },
    {
        route :'/committee/event/editEvent/:id/:eventId',
        element : <AddEvents actionFeatures={['6666faf0855b40c9de51c4b0']} />,
        allowedRoles : ['Super User','Developer'],
        allowedFeatures : []
    },
    {
        route :'/event/view',
        element : <ViewEvents />,
        allowedRoles : ['Super User','Developer'],
        allowedFeatures : []
    },
    {
        route : '/scoringTemplate/add',
        element :<AddScoringTemplate />,
        allowedRoles : ['Developer'],
        allowedFeatures : []
    },
    {
        route : '/scoringTemplate/view',
        element :<ViewScoringTemplate/>,
        allowedRoles : ['Super User','Developer'],
        allowedFeatures : []
    },
    {
        route : '/scoringTemplate/edit/:id',
        element : <EditScoringTemplate />,
        allowedRoles : ['HOD', 'Super User','Developer'],
        allowedFeatures :[]
    },
    //generalBulkUpload
    {
        route: 'generalBulkUpload',
        element: <GeneralBulkUpload />,
        allowedRoles: ['Developer', 'Super User'],
        allowedFeatures: [],
    },
    {
        route: 'viewRegistrationRequest',
        element: <ViewRegistrationRequest />,
        allowedRoles: ['Admin Head', 'HOD','Developer'],
        allowedFeatures: [],
    },
    {
        route: 'viewShortlistRequest',
        element: <ViewShortlistRegistrationRequest />,
        allowedRoles: ['Admin Head', 'HOD','Developer'],
        allowedFeatures: [],
    },
    {
        route: 'approveVolunteer',
        element : <ApproveVolunteer actionFeatures={['66177eca073c673e57dc26a9']} />,
        allowedRoles : ['Head', 'Sub Head','HOD','Developer' ],
        allowedFeatures : [],
    },
    {
        route : '/committee/event/approveVolunteer/:id',
        element : <ApproveVolunteer actionFeatures={['66177eca073c673e57dc26a9']} />,
        allowedRoles:[],
        allowedFeatures : [],
    },
    {
        route : '/committee/non-event/approveVolunteer/:id',
        element : <ApproveVolunteer actionFeatures={['66177eca073c673e57dc26a9']} />,
        allowedRoles:[],
        allowedFeatures : [],
    },
    {
        route : 'commonPool',
        element : <CommonPool actionFeatures={['66177a52073c673e57dc2670']}/>,
        allowedRoles : ['Head', 'HOD', 'Admin Head','Developer'],
        allowedFeatures : []
    },
    {
        route : 'coreApproval',
        element : <CoreApproval />,
        allowedRoles : ['Super User', 'Developer','Admin Head'],
        allowedFeatures : [],
    },
    {
        route : 'collegesRequest',
        element : <CollegesRequest actionFeatures={['66d6c5ab87a6f1f07a157faa']}/>,
        allowedRoles : ROLES.ALL,
        allowedFeatures : [],
    },
    {
        route : '/participant/add',
        element : <AddParticipant actionFeatures={['6687a53fafe55b15084a71ba', '6687a56eafe55b15084a71c0']}/>,
        allowedRoles :['CL','Developer'],
        allowedFeatures : []
    },
    {
        route : '/committee/event/viewParticipant/:id/:eventId',
        element : <ViewParticipants actionFeatures={['669a5698b453e0a13a9bf528','66cebb4b952e94f67242f917']}/>,
        allowedRoles :['Developer'],
        allowedFeatures : []
    },
    {
        route : '/committee/event/participant/:id/:eventId',
        element : <AddParticipant actionFeatures={['6687a53fafe55b15084a71ba', '6687a56eafe55b15084a71c0','6687a55dafe55b15084a71bd','66bb02b31476b1e00efbbd89']}/>,
        allowedRoles :['Developer'],
        allowedFeatures : []
    },
    {
        route : '/ncpParticipant/:id/:eventId',
        element : <NcpParticipant actionFeatures={['6687a53fafe55b15084a71ba','6687a55dafe55b15084a71bd']}/>,
        allowedRoles :['Developer','NCP Representative'],
        allowedFeatures : []
    },
    {
        route : '/allRegistration',
        element : <AllRegistration actionFeatures={['667ec76fc39ca5ba70292648','66177eca073c673e57dc26a9']} />,
        allowedRoles : ['Super User','Developer', 'Admin Head', 'HOD'],
        allowedFeatures :[],
    },
    {
        route : '/addSchedule',
        element : <AddSchedule actionFeatures={['6666fe8d855b40c9de51c510','667e9475c39ca5ba7029171a','6667e6295f3a6b74d32d2e7a']}/>,
        allowedRoles : ['Developer','Super User','HOD','Admin Head'],
        allowedFeatures : []
    },
    {
        route : '/websitesCreatives',
        element : <WebsiteCreatives actionFeatures={['667e9687c39ca5ba7029172a','6688fb3199d9d526017dabcc']}/>,
        allowedRoles : ['Super User','Developer'],
        allowedFeatures:[]
    },
    {
        route : '/scoreboard',
        element : <Scoreboard />,
        allowedRoles : ['CL','Super User','Developer'],
        allowedFeatures :[]
    },
    {
        route : '/committee/event/scoreboard/:id/:eventId',
        element : <EventScoreboard actionFeatures={['669a1bb1b453e0a13a9bf226','669a1bccb453e0a13a9bf229','669a3594b453e0a13a9bf2a1']}/>,
        allowedRoles : [],
        allowedFeatures : []
    },
    {
        route : '/viewScorecard',
        element : <ViewScorecard />,
        allowedRoles : [],
        allowedFeatures  : []
    },
    {
        route : '/warOfClEventSetup',
        element : <CLEventSetup actionFeatures={['6666faf0855b40c9de51c4b0']}/>,
        allowedRoles :[],
        allowedFeatures : [],
    },
    {
        route :'/warOfClRegistration',
        element : <WarOfCLRegistration actionFeatures={['6687a55dafe55b15084a71bd']} />,
        allowedRoles :[],
        allowedFeatures : [],
    },
    {
        route :'/warOfClParticipants',
        element : <WarOfClParticipants />,
        allowedRoles :[],
        allowedFeatures : [],
    },
    {
        route : '/printable',
        element : <Printable />,
        allowedRoles:[],
        allowedFeatures:[]
    },
];
