import React, { useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import Tabs from '../../components/infrastructure/Tabs/Tabs';
import Tab from '../../components/infrastructure/Tabs/Tab';
import { IndeterminateCheckbox } from '../../components/Table/IndeterminateCheckbox';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { useDispatch, useSelector } from 'react-redux';
import {
    editRegistrationRequest,
    editShortlistRegistrationRequest,
    fetchRegistrationRequests,
    getRegistrationRequests,
} from '../../app/reducers/RegistrationRequest/registrationRequestSlice';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import {
    deleteEmployee,
    editEmployee,
    fetchEmployees,
    getEmployees,
} from '../../app/reducers/Users/EmployeeSlice';
import { CORE_ROLE } from '../../utils/constants';
import EditShortlisted from './EditShortlisted';
import ApprovedRegistrationRequest from '../RegistrationRequest/ApprovedRegistrationRequest';
import EditApprovedVolunteer from './EditApprovedVolunteer';
import EditCoreTeam from './EditCoreTeam';
import {
    editCommittee,
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import { fetchRoles, getRoles } from '../../app/reducers/Role/roleSlice';
import QueryString from 'qs';
import { authAxiosInstance, axiosInstance } from '../../utils/axiosConfig';
import FilterApprovedVolunteer from '../ApproveVolunteer/FilterApprovedVolunteer';
import { useFormik } from 'formik';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import { exportCsv, generateOptions } from '../../utils/Utils';
import PendingReqPromote from './PendingReqPromote';
import BulkEditShortlisted from './BulkEditShorlisted';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import {
    fetchFeatures,
    getFeatures,
} from '../../app/reducers/Features/featuresSlice';
import { Link } from 'react-router-dom';
import { ChevronRight, Download, Eye } from 'react-feather';
import { toast } from 'react-toastify';
import PendingPromote from './PendingPromote';
import ShortlistReqPromote from './ShortlistReqPromote';
import ShortlistPromoteReqRole from '../ApproveVolunteer/ShortlistPromoteReqRole';
import PromoteVolunteer from '../ApproveVolunteer/PromoteVolunteer';
import EditPending from './EditPending';
import PromoteApprovedVolunteerVolunteer from './PromoteApprovedVolunteer';
import ShortlistPromote from './ShortlistPromote';
import { ClipLoader } from 'react-spinners';
import { fetchEvents } from '../../app/reducers/Event/eventSlice';
import Preview from './Preview';

const AllRegistration = ({ actionFeatures }) => {
    const { user } = useSelector(getAuth);
    const { features } = useSelector(getFeatures);
    console.log('features', features);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [page, setPage] = useState(1);
    const [requestData, setRequestData] = useState({});
    const [editShortlistRequestData, setEditShortlistRequestData] = useState(
        {}
    );
    const [editPendingRequestData, setEditPendingRequestData] = useState({});
    const [approvedVolunteerRequestData, setApprovedVolunteerRequestData] =
        useState({});
    const [
        showApprovedVolunteerPromoteModal,
        setShowApprovedVolunteerPromoteModal,
    ] = useState(false);
    const [showApprovedModal, setShowApprovedModal] = useState(false);
    const [showPromoteModal, setShowPromoteModal] = useState(false);
    const [showReqShortlistPromoteModal, setShowReqShortlistPromoteModal] =
        useState(false);
    const [showEditShortlistModal, setShowEditShortlistModal] = useState(false);
    const [showEditPendingModal, setShowEditPendingModal] = useState(false);
    const [showEditCoreModal, setShowEditCoreModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editRequestData, setEditRequestData] = useState({});
    const [showPendingPromoteModal, setShowPendingPromoteModal] =
        useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectAllShortlist, setSelectAllShortlist] = useState(false);
    const [selectedEditReqRows, setSelectedEditReqRows] = useState([]);
    const [editData, setEditDate] = useState([]);
    const [coreApprovalReqData, setCoreApprovalReqData] = useState([]);
    const [shortlistRequestData, setShortlistRequestData] = useState({});
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const { registrationRequest, loading } = useSelector(
        getRegistrationRequests
    );
    // console.log('registration request', registrationRequest);
    const { employees } = useSelector(getEmployees);
    const { committee } = useSelector(getCommittees);
    const [canShowTransfer, setCanShowTransfer] = useState(true);
    const [canShowApprove, setCanShowApprove] = useState(true);
    const { role } = useSelector(getRoles);
    const [isDownloading, setIsDownloading] = useState(false);

    const formik = useFormik({
        initialValues: {
            committee: [],
        },
    });

    const dispatch = useDispatch();
    const actionsBtn = ['Transfer', 'ApproveDecline'];
    const createFeatureActionsConfig = (actionFeatures, actions) => {
        const config = {};
        actionFeatures.forEach((ids, index) => {
            config[ids] = actions[index];
        });
        return config;
    };
    const computeActiveFeature = () => {
        const buttonStateSetter = {
            Transfer: setCanShowTransfer,
            ApproveDecline: setCanShowApprove,
        };
        const actionButtonMap = createFeatureActionsConfig(
            actionFeatures,
            actionsBtn
        );
        console.log('actionMapObj', actionButtonMap);
        let canShow = true;
        if (!user.isSuperUser && user?.role?.name !== 'Super User') {
            if (features?.docs?.length) {
                features?.docs?.forEach((item) => {
                    console.log('lenght1', features?.docs?.length);
                    if (actionFeatures?.includes(item?._id)) {
                        console.log('lenght2', features?.docs?.length);
                        const action = actionButtonMap[item?._id];
                        console.log('action', action);
                        const status = item?.status;
                        console.log('status', status);
                        if (buttonStateSetter[action]) {
                            buttonStateSetter[action](status === 'active');
                        }
                    }
                });
            }
        } else {
            Object.values(buttonStateSetter).forEach((btn) => btn(canShow));
        }
    };

    const coreApprovalData = async () => {
        const corePayload = {
            isApproved: 'pending',
            populate: true,
            'committee._id': { $in: formik.values.committee },
            sort: { 'committee1.name': 1, firstName: 1 },
        };
        const coreString = QueryString.stringify(corePayload);
        const resp = await authAxiosInstance.get(
            `/users/employee?${coreString}`
        );
        const data = resp?.data?.data?.docs;
        console.log('data', data);
        setCoreApprovalReqData(data);
    };

    useEffect(() => {
        if (selectedTabIndex === 0) {
            dispatch(
                fetchRegistrationRequests({
                    status: 'pending',
                    populate: true,
                    'committee1._id': { $in: formik.values.committee },
                    sort: { 'committee1.name': 1, firstName: 1 },
                })
            );
        }
        if (selectedTabIndex === 1) {
            dispatch(
                fetchRegistrationRequests({
                    status: 'shortlisted',
                    populate: true,
                    'committee1._id': { $in: formik.values.committee },
                    sort: { 'committee1.name': 1, firstName: 1 },
                })
            );
        }
        if (selectedTabIndex === 2) {
            dispatch(
                fetchEmployees({
                    populate: true,
                    'role.name': 'Volunteer',
                    'committee._id': { $in: formik.values.committee },
                    sort: { 'committee.name': 1, firstName: 1 },
                })
            );
        }
        if (selectedTabIndex === 3) {
            dispatch(
                fetchEmployees({
                    populate: true,
                    'role.name': { $in: CORE_ROLE },
                    'committee._id': { $in: formik.values.committee },
                    sort: { 'committee.name': 1, firstName: 1 },
                })
            );
            coreApprovalData();
            // dispatch(fetchEmployees({isApproved : "pending",populate : true}))
        }
        if (selectedTabIndex === 4) {
            dispatch(
                fetchRegistrationRequests({
                    populate: true,
                    status: 'declined',
                    'committee1._id': { $in: formik.values.committee },
                    sort: { 'committee1.name': 1, firstName: 1 },
                })
            );
        }
        if (selectedTabIndex === 5) {
            dispatch(
                fetchRegistrationRequests({
                    populate: true,
                    sort: { firstName: 1 },
                })
            );
        }
    }, [page, selectedTabIndex, JSON.stringify(formik.values)]);

    useEffect(() => {
        dispatch(fetchRoles({ populate: true, type: 'Internal', sort : {name:1} }));
        dispatch(fetchCommittees({ populate: true,sort : {name:1} }));
        // dispatch(fetchFeatures({populate : true, type :'backend'}));
        computeActiveFeature();
    }, [features]);
    console.log('selectedRow', selectedRows);
    let pendingReqColumns = [
        {
            id: 'Select All',
            Header: () => (
                <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={(e) => handleSelectAll(e.target.checked)}
                />
            ),
            Cell: ({ row }) => (
                <input
                    type="checkbox"
                    checked={selectedRows.includes(row?.original?._id)}
                    onChange={(e) => {
                        e.currentTarget.checked;
                        console.log(e.currentTarget.checked);
                        if (e.currentTarget.checked) {
                            setSelectedRows([
                                ...selectedRows,
                                row?.original?._id,
                            ]);
                        } else {
                            const filtered = selectedRows.filter(
                                (ele) => ele !== row.original._id
                            );
                            setSelectedRows(filtered);
                        }
                    }}
                />
            ),
            cellStyle: {
                width: '10%',
            },
        },
        {
            Header: 'Name',
            accessor: (i) => `${i.firstName} ${i.lastName}`,
            cellStyle: {
                width: '20%',
            },
        },
        {
            Header: 'Phone Number',
            Cell: ({ row }) => {
                return (
                    <a href={`tel:${row?.original?.contactNumber}`}>
                        {row?.original?.contactNumber}
                    </a>
                );
            },
            cellStyle: {
                width: '10%',
            },
        },
        {
            Header: 'Committee',
            accessor: 'committee1.name',
            cellStyle: {
                width: '15%',
            },
        },
        // {
        //     Header: 'Status',
        //     accessor: 'status',
        //     cellStyle: {
        //         width: '15%',
        //     },
        // },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row gap-2 items-center">
                        {['Super User', 'Developer', 'HOD'].includes(
                            user?.role?.name
                        ) &&
                            canShowApprove && (
                                <DangerButton
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        await dispatch(
                                            editRegistrationRequest({
                                                ...row.original,
                                                isDecline: true,
                                            })
                                        );
                                        await dispatch(
                                            fetchRegistrationRequests({
                                                status: 'pending',
                                                populate: true,
                                                sort: {
                                                    'committee1.name': 1,
                                                    firstName: 1,
                                                },
                                            })
                                        );
                                    }}
                                >
                                    Decline
                                </DangerButton>
                            )}
                        {['Super User', 'Developer'].includes(
                            user?.role?.name
                        ) && (
                            <PrimaryButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    setRequestData(row?.original);
                                    setShowPromoteModal(true);
                                }}
                            >
                                Edit
                            </PrimaryButton>
                        )}
                        {['HOD', 'Super User', 'Developer'].includes(
                            user?.role?.name
                        ) &&
                            user?._id !== row?.original?._id && (
                                <PrimaryButton
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        setRequestData(row?.original);
                                        setShowPendingPromoteModal(true);
                                    }}
                                >
                                    Promote
                                </PrimaryButton>
                            )}

                        {[
                            'Admin Head',
                            'Super User',
                            'Developer',
                            'HOD',
                        ].includes(user?.role?.name) && (
                            <PrimaryButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    await dispatch(
                                        editShortlistRegistrationRequest({
                                            ...row?.original,
                                            status: 'shortlisted',
                                        })
                                    );
                                    await dispatch(
                                        fetchRegistrationRequests({
                                            status: 'pending',
                                            populate: true,
                                            'committee1._id': {
                                                $in: formik.values.committee,
                                            },
                                            sort: {
                                                'committee1.name': 1,
                                                firstName: 1,
                                            },
                                        })
                                    );
                                }}
                            >
                                Shortlist
                            </PrimaryButton>
                        )}
                        {[
                            'Admin Head',
                            'Super User',
                            'Developer',
                            'HOD',
                        ].includes(user?.role?.name) &&
                            canShowTransfer && (
                                <PrimaryButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setEditPendingRequestData(
                                            row?.original
                                        );
                                        setShowEditPendingModal(true);
                                    }}
                                >
                                    Transfer
                                </PrimaryButton>
                            )}

                        {
                            <Eye
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setRequestData(row?.original);
                                    setShowPreviewModal(true);
                                }}
                                color="#2563eb"
                            />
                            // Preview
                            // </PrimaryButton>
                        }
                    </div>
                );
            },
            cellStyle: {
                width: '30%',
            },
        },
    ];
    let shortlistReqColumns = [
        {
            id: 'Select All',
            Header: () => (
                <input
                    type="checkbox"
                    checked={selectAllShortlist}
                    onChange={(e) => handleSelectAllShortlist(e.target.checked)}
                />
            ),
            Cell: ({ row }) => (
                <input
                    type="checkbox"
                    checked={selectedEditReqRows.includes(row?.original?._id)}
                    onChange={(e) => {
                        if (e.currentTarget.checked) {
                            setSelectedEditReqRows([
                                ...selectedEditReqRows,
                                row?.original?._id,
                            ]);
                        } else {
                            const filtered = selectedEditReqRows.filter(
                                (ele) => ele !== row?.original?._id
                            );
                            setSelectedEditReqRows(filtered);
                        }
                    }}
                />
            ),
            cellStyle: {
                width: '10%',
            },
        },
        {
            Header: 'Name',
            accessor: (i) => `${i.firstName} ${i.lastName}`,
            cellStyle: {
                width: '15%',
            },
        },
        {
            Header: 'Phone Number',
            Cell: ({ row }) => {
                return (
                    <a href={`tel:${row?.original?.contactNumber}`}>
                        {row?.original?.contactNumber}
                    </a>
                );
            },
            cellStyle: {
                width: '15%',
            },
        },
        {
            Header: 'Committee',
            accessor: 'committee1.name',
            cellStyle: {
                width: '15%',
            },
        },
        // {
        //     Header: 'Status',
        //     accessor: 'status',
        //     cellStyle: {
        //         width: '15%',
        //     },
        // },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row gap-2 items-center">
                        {['Super User', 'Developer'].includes(
                            user?.role?.name
                        ) && (
                            <PrimaryButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    setEditRequestData(row?.original);
                                    setShowEditModal(true);
                                }}
                            >
                                Edit
                            </PrimaryButton>
                        )}

                        {['Super User', 'Developer', 'HOD'].includes(
                            user?.role?.name
                        ) &&
                            user?._id !== row?.original?._id && (
                                <PrimaryButton
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        setShortlistRequestData(row?.original);
                                        setShowReqShortlistPromoteModal(true);
                                    }}
                                >
                                    Promote
                                </PrimaryButton>
                            )}
                        {['Super User', 'Developer', 'HOD'].includes(
                            user?.role?.name
                        ) && (
                            <DangerButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    console.log('rows', row?.original);
                                    await dispatch(
                                        editShortlistRegistrationRequest({
                                            ...row?.original,
                                            status: 'pending',
                                        })
                                    );
                                    await dispatch(
                                        fetchRegistrationRequests({
                                            status: 'shortlisted',
                                            populate: true,
                                            'committee1._id': {
                                                $in: formik.values.committee,
                                            },
                                            sort: {
                                                'committee1.name': 1,
                                                firstName: 1,
                                            },
                                        })
                                    );
                                }}
                            >
                                Reset
                                {/* Back To Pending */}
                            </DangerButton>
                        )}
                        {[
                            'Admin Head',
                            'Super User',
                            'Developer',
                            'HOD',
                        ].includes(user?.role?.name) &&
                            canShowTransfer && (
                                <PrimaryButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setRequestData(row?.original);
                                        setShowEditShortlistModal(true);
                                    }}
                                >
                                    Transfer
                                </PrimaryButton>
                            )}
                        {
                            <Eye
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setRequestData(row?.original);
                                    setShowPreviewModal(true);
                                }}
                                color="#2563eb"
                            />
                            // Preview
                            // </PrimaryButton>
                        }
                    </div>
                );
            },
            cellStyle: {
                width: '30%',
            },
        },
    ];
    let approveVolunteerColumns = [
        {
            Header: 'Name',
            accessor: (i) => `${i.firstName} ${i.lastName}`,
            cellStyle: {
                width: '20%',
            },
        },
        {
            Header: 'Phone Number',
            Cell: ({ row }) => {
                return (
                    <a href={`tel:${row?.original?.contactNumber}`}>
                        {row?.original?.contactNumber}
                    </a>
                );
            },
            cellStyle: {
                width: '15%',
            },
        },
        {
            Header: 'Committee',
            Cell: ({ row }) => {
                return (
                    <ul>
                        {row?.original?.committee?.map((ele, i) => (
                            <li key={i}>{<span>{ele?.name}</span>}</li>
                        ))}
                    </ul>
                );
            },
            cellStyle: {
                width: '20%',
            },
        },
        // {
        //     Header: 'Role',
        //     accessor: 'role.name',
        //     cellStyle: {
        //         width: '15%',
        //     },
        // },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row gap-2 items-center">
                        {['Super User', 'Developer', 'HOD'].includes(
                            user?.role?.name
                        ) &&
                            user?._id !== row?.original?._id && (
                                <PrimaryButton
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        setApprovedVolunteerRequestData(
                                            row?.original
                                        );
                                        setShowApprovedVolunteerPromoteModal(
                                            true
                                        );
                                    }}
                                >
                                    Promote
                                </PrimaryButton>
                            )}
                        {['HOD', 'Super User', 'Developer'].includes(
                            user?.role?.name
                        ) &&
                            user?._id !== row?.original?._id && (
                                <DangerButton
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        console.log('reset btn', row?.original);
                                        const restObj = {
                                            committee: [],
                                            role: '',
                                            status: 'pending',
                                            ...row.original,
                                        };
                                        console.log('reset', restObj);
                                        await dispatch(editEmployee(restObj));
                                        await dispatch(
                                            deleteEmployee({
                                                id: row?.original?._id,
                                            })
                                        );
                                        dispatch(
                                            fetchEmployees({
                                                populate: true,
                                                'role.name': 'Volunteer',
                                            })
                                        );
                                        const payload = {
                                            username: row?.original?.username,
                                        };
                                        const string =
                                            QueryString.stringify(payload);
                                        console.log('payload', string);
                                        const registrationResp =
                                            await authAxiosInstance.get(
                                                `/registrationRequest?${string}`
                                            );
                                        const registrationObj =
                                            registrationResp?.data?.data
                                                ?.docs[0];
                                        await dispatch(
                                            editShortlistRegistrationRequest({
                                                ...registrationObj,
                                                status: 'pending',
                                            })
                                        );
                                        const committeePayload = {
                                            _id: row?.original?.committee[0]
                                                ?._id,
                                        };
                                        const stringData =
                                            QueryString.stringify(
                                                committeePayload
                                            );
                                        const resp =
                                            await authAxiosInstance.get(
                                                `/committee?${stringData}`
                                            );
                                        console.log(
                                            'resp',
                                            resp?.data?.data?.docs[0]
                                        );
                                        const committeeObj =
                                            resp?.data?.data?.docs[0];
                                        if (
                                            committeeObj?.scorer &&
                                            committeeObj?.scorer ===
                                                row?.original?._id
                                        ) {
                                            console.log('user is scorer');
                                            const { scorer, ...rest } =
                                                committeeObj;
                                            const editObj = {
                                                ...rest,
                                                scorer : ''
                                            };
                                            console.log('edited obj', editObj);
                                            await dispatch(
                                                editCommittee(editObj)
                                            );
                                        }
                                        dispatch(
                                            fetchRegistrationRequests({
                                                populate: true,
                                                status: 'pending',
                                            })
                                        );
                                    }}
                                >
                                    Reset
                                </DangerButton>
                            )}
                        {[
                            'Admin Head',
                            'HOD',
                            'Super User',
                            'Developer',
                        ].includes(user?.role?.name) &&
                            canShowTransfer && (
                                <PrimaryButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setRequestData(row?.original);
                                        setShowApprovedModal(true);
                                    }}
                                >
                                    Transfer
                                </PrimaryButton>
                            )}

                        {
                            <Eye
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setRequestData(row?.original);
                                    setShowPreviewModal(true);
                                }}
                                color="#2563eb"
                            />
                            // Preview
                            // </PrimaryButton>
                        }
                    </div>
                );
            },
            cellStyle: {
                width: '40%',
            },
        },
    ];
    let coreTeamColumn = [
        {
            Header: 'Name',
            accessor: (i) => `${i.firstName} ${i.lastName} `,
            cellStyle: {
                width: '20%',
            },
        },
        {
            Header: 'Phone Number',
            Cell: ({ row }) => {
                return (
                    <a href={`tel:${row?.original?.contactNumber}`}>
                        {row?.original?.contactNumber}
                    </a>
                );
            },
            cellStyle: {
                width: '15%',
            },
        },
        {
            Header: 'Committee',
            Cell: ({ row }) => {
                return (
                    <ul>
                        {row?.original?.committee?.map((ele, i) => (
                            <li key={i}>{<span>{ele?.name}</span>}</li>
                        ))}
                    </ul>
                );
            },
            cellStyle: {
                width: '20%',
            },
        },
        {
            Header: 'Role',
            accessor: 'role.name',
            cellStyle: {
                width: '20%',
            },
        },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row gap-2 items-center">
                        {
                            <Eye
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setRequestData(row?.original);
                                    setShowPreviewModal(true);
                                }}
                                color="#2563eb"
                            />
                            // Preview
                            // </PrimaryButton>
                        }
                        {['Super User', 'Developer'].includes(
                            user?.role?.name
                        ) && (
                            <PrimaryButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    console.log('core row', row?.original);
                                    setRequestData(row?.original);
                                    setShowEditCoreModal(true);
                                }}
                            >
                                Edit
                            </PrimaryButton>
                        )}
                        {['Super User', 'Developer'].includes(
                            user?.role?.name
                        ) &&
                            user?._id !== row?.original?._id &&
                            user?.role?._id !== row?.original?.role?._id && (
                                <DangerButton
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        console.log('reset btn', row?.original);
                                        const restObj = {
                                            committee: [],
                                            role: '',
                                            status: 'pending',
                                            ...row.original,
                                        };
                                        console.log('reset', restObj);
                                        await dispatch(editEmployee(restObj));
                                        await dispatch(
                                            deleteEmployee({
                                                id: row?.original?._id,
                                            })
                                        );
                                        dispatch(
                                            fetchEmployees({
                                                populate: true,
                                                'role.name': { $in: CORE_ROLE },
                                                sort: {
                                                    'committee.name': 1,
                                                    firstName: 1,
                                                },
                                            })
                                        );
                                        const payload = {
                                            username: row?.original?.username,
                                        };
                                        const string =
                                            QueryString.stringify(payload);
                                        console.log('payload', string);
                                        const registrationResp =
                                            await authAxiosInstance.get(
                                                `/registrationRequest?${string}`
                                            );
                                        const registrationObj =
                                            registrationResp?.data?.data
                                                ?.docs[0];
                                        await dispatch(
                                            editShortlistRegistrationRequest({
                                                ...registrationObj,
                                                status: 'pending',
                                            })
                                        );
                                        dispatch(
                                            fetchRegistrationRequests({
                                                populate: true,
                                                status: 'pending',
                                                sort: {
                                                    'committee1.name': 1,
                                                    firstName: 1,
                                                },
                                            })
                                        );
                                    }}
                                >
                                    Reset
                                </DangerButton>
                            )}
                    </div>
                );
            },
            cellStyle: {
                width: '25%',
            },
        },
    ];
    let coreApprovalCols = [
        {
            Header: 'Name',
            Cell: ({ row }) => {
                return (
                    <span>
                        {row?.original?.firstName} {row?.original?.lastName}
                    </span>
                );
            },
            cellStyle: {
                width: '15%',
            },
        },
        {
            Header: 'Phone Number',
            Cell: ({ row }) => {
                return (
                    <a href={`tel:${row?.original?.contactNumber}`}>
                        {row?.original?.contactNumber}
                    </a>
                );
            },
            cellStyle: {
                width: '15%',
            },
        },
        {
            Header: 'Committee',
            Cell: ({ row }) => {
                console.log('row', row?.original);
                return (
                    <span>
                        {row?.original?.committee?.map((ele) => ele?.name)}
                    </span>
                );
            },
            cellStyle: {
                width: '15%',
            },
        },
        {
            Header: 'Role',
            Cell: ({ row }) => {
                return <span>{row?.original?.role?.name}</span>;
            },
            cellStyle: {
                width: '10%',
            },
        },
        {
            Header: 'Requested Role',
            accessor: 'requestedRole',
            Cell: ({ row }) => {
                const roleName = role?.docs?.find(
                    (ele) => ele?._id == row?.original?.requestedRole
                );
                return <span>{roleName?.name}</span>;
            },
            cellStyle: {
                width: '15%',
            },
        },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    <div className="flex gap-2 items-center cursor-pointer">
                        {['Super User', 'Developer'].includes(
                            user?.role?.name
                        ) &&
                            canShowApprove && (
                                <PrimaryButton
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        // console.log('row app', row?.original);
                                        const payload = {
                                            role: row?.original?.requestedRole,
                                            committee:
                                                row?.original?.committee[0]
                                                    ?._id,
                                        };
                                        const stringData =
                                            QueryString.stringify(payload);
                                        const userData =
                                            await authAxiosInstance.get(
                                                `/users/employee?${stringData}`
                                            );
                                        const allUsers =
                                            userData?.data?.data?.docs;
                                        const payloadCommittee = {
                                            _id: row?.original?.committee[0]
                                                ?._id,
                                            populate: true,
                                        };
                                        const stringCommittee =
                                            QueryString.stringify(
                                                payloadCommittee
                                            );
                                        const res = await authAxiosInstance.get(
                                            `/committee?${stringCommittee}`
                                        );
                                        const committeeData =
                                            res?.data?.data?.docs?.[0];
                                        // console.log(committeeData, 'data');
                                        const memberObj =
                                            committeeData?.members?.find(
                                                (ele) =>
                                                    ele.role._id ==
                                                    row?.original?.requestedRole
                                            );
                                        if (!memberObj?.seats) {
                                            toast.error(
                                                'Seats are not allocated'
                                            );
                                            return;
                                        }
                                        if (
                                            memberObj?.seats > allUsers?.length
                                        ) {
                                            console.log('condition pass');
                                            const updateRoleObj = {
                                                _id: row?.original?._id,
                                                role: row?.original
                                                    ?.requestedRole,
                                                isApproved: 'approved',
                                            };
                                            // console.log('object', updateRoleObj);
                                            await dispatch(
                                                editEmployee(updateRoleObj)
                                            );
                                            coreApprovalData();

                                            const payload = {
                                                username:
                                                    row?.original?.username,
                                            };
                                            const stringData =
                                                QueryString.stringify(payload);
                                            const resp =
                                                await authAxiosInstance.get(
                                                    `/registrationRequest?${stringData}`
                                                );
                                            const respData =
                                                resp?.data?.data?.docs[0];
                                            await dispatch(
                                                editShortlistRegistrationRequest(
                                                    {
                                                        _id: respData?._id,
                                                        status: 'approved',
                                                    }
                                                )
                                            );
                                            await dispatch(
                                                fetchEmployees({
                                                    populate: true,
                                                    'role.name': {
                                                        $in: CORE_ROLE,
                                                    },
                                                    'committee._id': {
                                                        $in: formik.values
                                                            .committee,
                                                    },
                                                    sort: {
                                                        'committee.name': 1,
                                                        firstName: 1,
                                                    },
                                                })
                                            );
                                        } else {
                                            toast.error('Seats are full');
                                        }
                                    }}
                                >
                                    Approve
                                </PrimaryButton>
                            )}
                        {['Super User', 'Developer'].includes(
                            user?.role?.name
                        ) &&
                            canShowApprove && (
                                <DangerButton
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        const updateRoleObj = {
                                            _id: row?.original?._id,
                                            isApproved: 'none',
                                        };
                                        console.log('object', updateRoleObj);
                                        await dispatch(
                                            editEmployee(updateRoleObj)
                                        );
                                        coreApprovalData();
                                    }}
                                >
                                    Decline
                                </DangerButton>
                            )}
                    </div>
                );
            },
            cellStyle: {
                width: '30%',
            },
        },
    ];
    let declineReqColumn = [
        {
            Header: 'Name',
            accessor: (i) => `${i.firstName} ${i.lastName}`,
            cellStyle: {
                width: '25%',
            },
        },
        {
            Header: 'Phone Number',
            Cell: ({ row }) => {
                return (
                    <a href={`tel:${row?.original?.contactNumber}`}>
                        {row?.original?.contactNumber}
                    </a>
                );
            },
            cellStyle: {
                width: '20%',
            },
        },
        {
            Header: 'Committee',
            accessor: 'committee1.name',
            cellStyle: {
                width: '20%',
            },
        },
        // {
        //     Header: 'Status',
        //     accessor: 'status',
        //     cellStyle: {
        //         width: '15%',
        //     },
        // },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row gap-2 items-center">
                        {['Super User', 'Developer', 'HOD'].includes(
                            user?.role?.name
                        ) && (
                            <DangerButton
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    console.log(row?.original);
                                    await dispatch(
                                        editShortlistRegistrationRequest({
                                            ...row.original,
                                            status: 'pending',
                                        })
                                    );
                                    await dispatch(
                                        fetchRegistrationRequests({
                                            populate: true,
                                            status: 'declined',
                                            'committee1._id': {
                                                $in: formik.values.committee,
                                            },
                                            sort: {
                                                'committee1.name': 1,
                                                firstName: 1,
                                            },
                                        })
                                    );
                                }}
                            >
                                Back To Pending
                            </DangerButton>
                        )}
                    </div>
                );
            },
            cellStyle: {
                width: '30%',
            },
        },
    ];
    let masterDataColumn = [
        {
            Header: 'Sr. No.',
            Cell: ({ row }) => {
                return row.index + 1;
            },
            cellStyle: {
                width: '5%',
            },
        },
        {
            Header: 'Name',
            accessor: (i) => `${i.firstName} ${i.lastName}`,
            cellStyle: {
                width: '15%',
            },
        },
        {
            Header: 'Phone Number',
            accessor: 'contactNumber',
            cellStyle: {
                width: '10%',
            },
        },
        {
            Header: 'Preferred Committee',
            Cell: ({ row }) => {
                const preSaveCommittee =
                    row?.original?.preferredCommittee?.split(',') || [];
                const committeeData = committee?.docs?.filter((ele) =>
                    preSaveCommittee.includes(ele?._id)
                );
                return (
                    <ul>
                        <li>
                            {committeeData?.map((ele) => {
                                if (preSaveCommittee[0] === ele?._id)
                                    return ele?.name;
                            })}
                        </li>
                        <li>
                            {committeeData?.map((ele) => {
                                if (preSaveCommittee[1] === ele?._id)
                                    return ele?.name;
                            })}
                        </li>
                        <li>
                            {committeeData?.map((ele) => {
                                if (preSaveCommittee[2] === ele?._id)
                                    return ele?.name;
                            })}
                        </li>
                    </ul>
                );
            },
            cellStyle: {
                width: '10%',
            },
        },
        {
            Header: 'Assign Committee',
            Cell: ({ row }) => {
                // console.log('row', row?.original)
                return (
                    <span>
                        {row?.original?.user &&
                            row?.original?.user?.committee?.map(
                                (ele) => ele?.name
                            )}
                    </span>
                );
            },
            cellStyle: {
                width: '10%',
            },
        },
        {
            Header: 'Role',
            Cell: ({ row }) => {
                return (
                    <span>
                        {row?.original?.user && row?.original?.user?.role?.name}
                    </span>
                );
            },
            cellStyle: {
                width: '10%',
            },
        },
        {
            Header: 'Status',
            accessor: 'status',
            cellStyle: {
                width: '10%',
            },
        },
        {
            Header: 'Department',
            accessor: 'courses',
            cellStyle: {
                width: '10%',
            },
        },
        {
            Header: 'Year',
            accessor: 'year',
            cellStyle: {
                width: '10%',
            },
        },
        {
            Header: 'Part Of O2',
            accessor: 'partOfO2',
            cellStyle: {
                width: '5%',
            },
        },
    ];

    //useMemo for Table
    const pendingReqMemo = useMemo(
        () => (registrationRequest?.docs ? registrationRequest?.docs : []),
        [registrationRequest]
    );
    const shortlistReqMemo = useMemo(
        () => (registrationRequest?.docs ? registrationRequest?.docs : []),
        [registrationRequest]
    );
    const approveVolunteerMemo = useMemo(
        () => (employees?.docs ? employees?.docs : []),
        [employees]
    );
    const coreTeamMemo = useMemo(
        () => (employees?.docs ? employees?.docs : []),
        [employees]
    );
    const coreApprovalMemo = useMemo(
        () => (coreApprovalReqData ? coreApprovalReqData : []),
        [coreApprovalReqData]
    );
    const declineReqMemo = useMemo(
        () => (registrationRequest?.docs ? registrationRequest?.docs : []),
        [registrationRequest]
    );
    const masterDataMemo = useMemo(
        () => (registrationRequest?.docs ? registrationRequest?.docs : []),
        [registrationRequest]
    );
    const handleSelectAll = (checked) => {
        if (checked) {
            const allIds = pendingReqMemo.map((row) => row._id);
            setSelectedRows(allIds);
        } else {
            setSelectedRows([]);
        }
        setSelectAll(checked);
    };
    const handleSelectAllShortlist = (checked) => {
        if (checked) {
            const allIds = shortlistReqMemo.map((row) => row._id);
            setSelectedEditReqRows(allIds);
        } else {
            setSelectedEditReqRows([]);
        }
        setSelectAllShortlist(checked);
    };
    return (
        <PageWithCard
            heading={
                <>
                    <span >Data Bank</span>
                    {/* <Link to={'/allRegistration'}>Data Bank</Link> */}
                    <span className="text-slate-200 inline-block px-1">
                        <ChevronRight size={14} className="-mb-0.5" />{' '}
                    </span>
                    {selectedTabIndex === 0 && <span>{'Pending'}</span>}
                    {selectedTabIndex === 1 && <span>{'Shortlisted'}</span>}
                    {selectedTabIndex === 2 && (
                        <span>{'Approved Volunteer'}</span>
                    )}
                    {selectedTabIndex === 3 && <span>{'Core Team'}</span>}
                    {selectedTabIndex === 4 && <span>{'Declined'}</span>}
                    {selectedTabIndex === 5 && <span>{'Master Data'}</span>}
                </>
            }
        >
            <form className="bg-black p-4 rounded sm:rounded-md">
                <FormikMultiSelect
                    formik={formik}
                    label="Filter By Committees"
                    name="committee"
                    options={generateOptions({
                        array: committee ? committee?.docs : [],
                        valueField: '_id',
                        labelField: 'name',
                    })}
                />
            </form>
            {selectedTabIndex === 0 && (
                <>
                    <EditPending
                        requestData={editPendingRequestData}
                        showApproveModal={showEditPendingModal}
                        setShowApproveModal={setShowEditPendingModal}
                        filterValues={formik.values}
                    />
                    <PendingReqPromote
                        requestData={requestData}
                        showApprovedModal={showPromoteModal}
                        setShowApprovedModal={setShowPromoteModal}
                    />
                    <PendingPromote
                        requestData={requestData}
                        showApprovedModal={showPendingPromoteModal}
                        setShowApprovedModal={setShowPendingPromoteModal}
                        filterValues={formik.values}
                    />
                    <Preview
                        requestData={requestData}
                        showPreviewModal={showPreviewModal}
                        setShowPreviewModal={setShowPreviewModal}
                    />
                </>
            )}
            {selectedTabIndex === 1 &&
                (selectedEditReqRows.length > 0 ? (
                    <BulkEditShortlisted
                        editData={editData}
                        setSelectedEditReqRows={setSelectedEditReqRows}
                        showApproveModal={showEditShortlistModal}
                        setShowApproveModal={setShowEditShortlistModal}
                    />
                ) : (
                    <>
                        <EditShortlisted
                            requestData={requestData}
                            showApproveModal={showEditShortlistModal}
                            setShowApproveModal={setShowEditShortlistModal}
                            filterValues={formik.values}
                        />
                        <ShortlistPromote
                            requestData={shortlistRequestData}
                            showApprovedModal={showReqShortlistPromoteModal}
                            setShowApprovedModal={
                                setShowReqShortlistPromoteModal
                            }
                            filterValues={formik.values}
                        />
                        <ShortlistReqPromote
                            requestData={editRequestData}
                            showApprovedModal={showEditModal}
                            setShowApprovedModal={setShowEditModal}
                        />
                        <Preview
                            requestData={requestData}
                            showPreviewModal={showPreviewModal}
                            setShowPreviewModal={setShowPreviewModal}
                        />
                    </>
                ))}
            {selectedTabIndex === 2 && (
                <>
                    <EditApprovedVolunteer
                        requestData={requestData}
                        showApproveModal={showApprovedModal}
                        setShowApproveModal={setShowApprovedModal}
                        filterValues={formik.values}
                    />
                    <PromoteApprovedVolunteerVolunteer
                        requestData={approvedVolunteerRequestData}
                        showApprovedModal={showApprovedVolunteerPromoteModal}
                        setShowApprovedModal={
                            setShowApprovedVolunteerPromoteModal
                        }
                        filterValues={formik.values}
                    />
                    <Preview
                        requestData={requestData}
                        showPreviewModal={showPreviewModal}
                        setShowPreviewModal={setShowPreviewModal}
                    />
                </>
            )}
            {selectedTabIndex === 3 && (
                <>
                    <EditCoreTeam
                        requestData={requestData}
                        showApproveModal={showEditCoreModal}
                        setShowApproveModal={setShowEditCoreModal}
                        filterValues={formik.values}
                    />
                    <Preview
                        requestData={requestData}
                        showPreviewModal={showPreviewModal}
                        setShowPreviewModal={setShowPreviewModal}
                    />
                </>
            )}
            <Tabs
                headers={[
                    'Pending',
                    'Shortlisted',
                    'Approved Volunteer',
                    'Core Team',
                    'Declined',
                    'Master Data',
                ]}
                onTabChange={(index) => {
                    setSelectedTabIndex(index);
                }}
            >
                <Tab>
                    <TableWithHeadingAndSearch
                        heading={
                            <div>
                                {/* {canShowFeature 
                                    {} */}
                                {[
                                    'Admin Head',
                                    'Super User',
                                    'Developer',
                                    'HOD',
                                ].includes(user?.role?.name) &&
                                    selectedRows.length > 0 && (
                                        <PrimaryButton
                                            onClick={async (e) => {
                                                e.stopPropagation();
                                                for (
                                                    let i = 0;
                                                    i < selectedRows.length;
                                                    i++
                                                ) {
                                                    console.log(
                                                        selectedRows[i]
                                                    );
                                                    await dispatch(
                                                        editShortlistRegistrationRequest(
                                                            {
                                                                _id: selectedRows[
                                                                    i
                                                                ],
                                                                status: 'shortlisted',
                                                            }
                                                        )
                                                    );
                                                    await dispatch(
                                                        fetchRegistrationRequests(
                                                            {
                                                                status: 'pending',
                                                                populate: true,
                                                            }
                                                        )
                                                    );
                                                }
                                                setSelectedRows([]);
                                            }}
                                        >
                                            Shortlist Selected
                                        </PrimaryButton>
                                    )}
                            </div>
                        }
                        columns={pendingReqColumns}
                        data={pendingReqMemo}
                    />
                    <PaginationClassic
                        setPage={setPage}
                        paginationDetails={registrationRequest}
                    />
                </Tab>
                <Tab>
                    <TableWithHeadingAndSearch
                        columns={shortlistReqColumns}
                        data={shortlistReqMemo}
                        heading={
                            <div>
                                {[
                                    'Admin Head',
                                    'Super User',
                                    'Developer',
                                    'HOD',
                                ].includes(user?.role?.name) &&
                                    selectedEditReqRows.length > 0 &&
                                    canShowTransfer && (
                                        <PrimaryButton
                                            onClick={async (e) => {
                                                e.stopPropagation();
                                                console.log(
                                                    'selected row',
                                                    selectedEditReqRows
                                                );
                                                setEditDate(
                                                    selectedEditReqRows
                                                );
                                                setShowEditShortlistModal(true);
                                            }}
                                        >
                                            Transfer Selected
                                        </PrimaryButton>
                                    )}
                            </div>
                        }
                    />
                    <PaginationClassic
                        setPage={setPage}
                        paginationDetails={registrationRequest}
                    />
                </Tab>
                <Tab>
                    <TableWithHeadingAndSearch
                        columns={approveVolunteerColumns}
                        data={approveVolunteerMemo}
                    />
                    <PaginationClassic
                        setPage={setPage}
                        paginationDetails={employees}
                    />
                </Tab>
                <Tab>
                    {['Developer', 'Super User'].includes(user?.role?.name) &&
                        coreApprovalReqData?.length > 0 && (
                            <TableWithHeadingAndSearch
                                data={coreApprovalMemo}
                                columns={coreApprovalCols}
                            />
                        )}
                    <TableWithHeadingAndSearch
                        columns={coreTeamColumn}
                        data={coreTeamMemo}
                    />
                    <PaginationClassic
                        setPage={setPage}
                        paginationDetails={employees}
                    />
                </Tab>
                <Tab>
                    <TableWithHeadingAndSearch
                        columns={declineReqColumn}
                        data={declineReqMemo}
                    />
                    <PaginationClassic
                        setPage={setPage}
                        paginationDetails={registrationRequest}
                    />
                </Tab>
                <Tab>
                    <TableWithHeadingAndSearch
                        columns={masterDataColumn}
                        data={masterDataMemo}
                    />
                    <PaginationClassic
                        setPage={setPage}
                        paginationDetails={registrationRequest}
                    />
                    {isDownloading ? (
                        <ClipLoader color="#FFFFFF" size={18} />
                    ) : (
                        <PrimaryButton
                            onClick={async (e) => {
                                e.stopPropagation();
                                try {
                                    setIsDownloading(true);
                                    let data = {
                                        populate: true,
                                        limit: 5000,
                                    };
                                    const committeeData = QueryString.stringify(
                                        { populate: true }
                                    );
                                    const committeeResp =
                                        await authAxiosInstance.get(
                                            `/committee?${committeeData}`
                                        );
                                    const committeesAry =
                                        committeeResp?.data?.data?.docs;
                                    console.log('array', committeesAry);
                                    const stringData =
                                        QueryString.stringify(data);
                                    const resp = await authAxiosInstance.get(
                                        `/registrationRequest?${stringData}`
                                    );
                                    console.log('resp', resp?.data?.data?.docs);
                                    if (resp?.data?.data?.docs?.length > 0) {
                                        let mainData =
                                            resp?.data?.data?.docs?.map(
                                                (ele, index) => {
                                                    let {
                                                        password,
                                                        preferredCommittee,
                                                        username,
                                                        user,
                                                        ...rest
                                                    } = ele;
                                                    const preferredCommittees =
                                                        preferredCommittee?.split(
                                                            ','
                                                        ) || [];
                                                    console.log(
                                                        'pre com',
                                                        preferredCommittee
                                                    );
                                                    console.log(
                                                        'pre com split',
                                                        preferredCommittees
                                                    );
                                                    const getMatchingCommitteeName =
                                                        (committeeId) => {
                                                            const committeeName =
                                                                committeesAry.find(
                                                                    (c) =>
                                                                        c._id ===
                                                                        committeeId
                                                                );

                                                            return committeeName
                                                                ? committeeName.name
                                                                : '';
                                                        };

                                                    const matchedCommittees =
                                                        preferredCommittees
                                                            .map(
                                                                getMatchingCommitteeName
                                                            )
                                                            .filter(
                                                                (name) => name
                                                            )
                                                            .join(', ');

                                                    return {
                                                        srno: `${index + 1}`,
                                                        ...rest,
                                                        committee1:
                                                            ele?.committee1
                                                                ?.name,
                                                        committee2:
                                                            ele?.committee2
                                                                ?.name,
                                                        committee3:
                                                            ele?.committee3
                                                                ?.name,
                                                        committee:
                                                            ele?.user?.committee
                                                                ?.map(
                                                                    (name) =>
                                                                        name?.name
                                                                )
                                                                .join(', '),
                                                        role: ele?.user?.role
                                                            ?.name,
                                                        // partOfO2: ele?.user?.partOfO2,
                                                        preferredCommittee:
                                                            matchedCommittees,
                                                    };
                                                }
                                            );

                                        // console.log('main obj', mainData);
                                        const filename = 'Master Data';
                                        exportCsv(mainData, filename);
                                    }
                                } catch (error) {
                                    console.log(error);
                                    toast.error('Fail to Download');
                                } finally {
                                    setIsDownloading(false);
                                }
                            }}
                        >
                            <Download size={16} className="mr-2" />{' '}
                            Download
                        </PrimaryButton>
                    )}
                </Tab>
            </Tabs>
        </PageWithCard>
    );
};

export default AllRegistration;
