import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Edit2, Trash } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import {
    deleteCommittee,
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import { useNavigate } from 'react-router-dom';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';

const ViewCommittee = () => {
    const { committee, loading } = useSelector(getCommittees);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const debouncedSearch = useCallback(
        _.debounce((search) => {
            console.log('searching');
            dispatch(fetchCommittees({ search: search, populate: true }));
        }, 300),
        []
    );
    const [page, setPage] = useState(1);

    const cols = [
        {
            Header: 'Committee',
            Cell: ({ row }) => {
                return <span>{row.original.name}</span>;
            },
        },

        {
            Header: 'Type',
            accessor: 'type',
        },
        {
            Header: 'No. of Events',
            accessor: 'numberOfEvents',
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original.numberOfEvents
                            ? row.original.numberOfEvents
                            : 'N.A'}
                    </span>
                );
            },
        },
        {
            Header: 'Members',
            accessor: 'members',
            Cell: ({ row }) => {
                const members = row.original.members || [];
                return (
                    <ul>
                        {members.map((member, index) => (
                            <li key={index}>
                                {member.role && <span>{member.role.name}</span>}
                                &nbsp; - &nbsp; {member.seats}
                            </li>
                        ))}
                    </ul>
                );
            },
        },

        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <div className="flex gap-2 items-center cursor-pointer">
                        <PrimaryButton
                            onClick={async () => {
                                navigate(`/committee/edit/${row.original._id}`);
                            }}
                            color="blue"
                        >
                            Edit
                        </PrimaryButton>
                        <DangerButton
                            onClick={async () => {
                                await dispatch(
                                    deleteCommittee({ id: row.original._id })
                                );
                                dispatch(fetchCommittees({ populate: true }));
                            }}
                            
                        >
                            Delete
                        </DangerButton>
                    </div>
                );
            },
        },
    ];
    const data = useMemo(
        () => (committee?.docs ? committee.docs : []),
        [committee]
    );
    useEffect(() => {
        dispatch(
            fetchCommittees({
                populate: true,
                sort : { type : 1, name : 1},
            })
        );
    }, [page]);
    return (
        <PageWithCard heading={'View committees'}>
            <TableWithHeadingAndGlobalSearch
                loading={loading}
                data={data}
                searchFunction={debouncedSearch}
                columns={cols}
            />
            <PaginationClassic
                setPage={setPage}
                paginationDetails={committee}
            />
        </PageWithCard>
    );
};

export default ViewCommittee;
