import React, { useEffect, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { Link } from 'react-router-dom';
import { ChevronRight, Download, Eye } from 'react-feather';
import TableWithHeading from '../../components/Table/TableWithHeading';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchEmployees,
    getEmployees,
} from '../../app/reducers/Users/EmployeeSlice';
import {
    ACL_ROLE_ID,
    CL_ROLE_ID,
    SUPER_USER_ROLE,
    VOLUNTEER_ROLE_ID,
} from '../../utils/constants';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import {
    fetchParticipants,
    getParticipants,
} from '../../app/reducers/Participant/teamParticipantSlice';
import {
    fetchRegistrationRequests,
    getRegistrationRequests,
} from '../../app/reducers/RegistrationRequest/registrationRequestSlice';
import QueryString from 'qs';
import { exportCsv } from '../../utils/Utils';

const Printable = () => {
    const { employees } = useSelector(getEmployees);
    const { teamParticipant } = useSelector(getParticipants);
    const { registrationRequest } = useSelector(getRegistrationRequests);
    const [loadingDownload, setLoadingDownload] = useState({});
    console.log('emp', employees);
    console.log('parti', teamParticipant);
    const internalPrintable = [
        { name: 'Accredit', _id: 'accredit', prevId: 'accreditP' },
        { name: 'Certificate', _id: 'certificate', prevId: 'certificateP' },
        { name: 'Scoresheet', _id: 'scoreSheet', prevId: 'scoreSheetP' },
    ];
    const externalPrintable = [
        { name: 'CL ID', _id: 'clId', prevId: 'clIdP' },
        { name: 'ACL ID', _id: 'aclId', prevId: 'aclId' },
        { name: 'POP Final', _id: 'pop', prevId: 'popP' },
        {
            name: 'POP Substitute Final',
            _id: 'popSubstituted',
            prevId: 'popSubstitutedP',
        },
        {
            name: 'POP Elimination',
            _id: 'popElimination',
            prevId: 'popEliminationP',
        },
        {
            name: 'POP Substitute Elimination',
            _id: 'popSubElimination',
            prevId: 'popSubEliminationP',
        },
        {
            name: 'CL Certificate',
            _id: 'clCertificate',
            prevId: 'clCertificateP',
        },
        {
            name: 'ACL Certificate',
            _id: 'aclCertificate',
            prevId: 'aclCertificateP',
        },
        {
            name: 'Winner Certificate',
            _id: 'winnerCertificate',
            prevId: 'winnerCertificateP',
        },
        {
            name: '1st Runner-up Certificate',
            _id: 'firstRunnerCertificate',
            prevId: 'firstRunnerCertificateP',
        },
        {
            name: '2nd Runner-up Certificate',
            _id: 'secondRunnerCertificate',
            prevId: 'secondRunnerCertificateP',
        },
        {
            name: 'Participation Certificate',
            _id: 'participationCertificate',
            prevId: 'participationCertificateP',
        },
        {
            name: 'Participants List',
            _id: 'participantsList',
            prevId: 'participantsListP',
        },
    ];

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchEmployees({ populate: true, limit: 5000 }));
        dispatch(fetchParticipants({ populate: true, limit: 5000 }));
        dispatch(
            fetchRegistrationRequests({
                populate: true,
                status: 'approved',
                limit: 5000,
            })
        );
    }, []);

    const handlePreviewClick = async (id) => {
        try {
            setLoadingDownload((state) => ({
                ...state,
                [id]: true,
            }));
            let response;
            let fileName = '';
            switch (id) {
                case 'accreditP':
                    try {
                        const volunteerData = employees?.docs?.filter(
                            (ele) => ele?.role?._id === VOLUNTEER_ROLE_ID
                        );
                        console.log('volunteerAry', [volunteerData[0]]);
                        if (volunteerData.length === 0) {
                            toast.error('No data found');
                            return;
                        }
                        response = await authAxiosInstance.post(
                            '/printable/volunteerPdf',
                            [volunteerData[0]],
                            { responseType: 'blob' }
                        );
                        fileName = 'Accredit.pdf';
                        // console.log('response', response);
                        // saveAs(response.data, 'VolunteerId.pdf');
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }
                    break;
                case 'certificateP':
                    try {
                        const teamData = registrationRequest?.docs[0];
                        if (!teamData) {
                            toast.error('No data found');
                            return;
                        }
                        response = await authAxiosInstance.post(
                            '/printable/teamCertificatePdf',
                            [teamData],
                            { responseType: 'blob' }
                        );
                        fileName = 'Certificate.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }
                    break;
                case 'scoreSheetP':
                    try {
                        const data = {
                            eventIds: teamParticipant?.docs?.map(
                                (ele) => ele.event._id
                            ),
                            status: 'approved',
                            populate: true,
                            limit: 5000,
                        };
                        console.log('data', data);
                        const resp = await authAxiosInstance.post(
                            `/teamParticipant/getEventParticipantData`,
                            data
                        );
                        console.log('event wise', resp?.data?.data[0]);
                        const participantData = resp?.data?.data[0];
                        participantData.participants.sort((a, b) => {
                            if (a.collegeCode < b.collegeCode) return -1;
                            if (a.collegeCode > b.collegeCode) return 0;
                        });

                        console.log('sort data', participantData);
                        if (!participantData) {
                            toast.error('No data found');
                            return;
                        }
                        response = await authAxiosInstance.post(
                            '/printable/eventParticipantScoreSheet',
                            [participantData],
                            { responseType: 'blob' }
                        );
                        fileName = 'ScoreSheet.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }
                    break;
                case 'clIdP':
                    try {
                        const clData = employees?.docs?.filter(
                            (ele) => ele?.role?._id === CL_ROLE_ID
                        );
                        console.log('clAry', clData[0]);
                        if (clData.length === 0) {
                            toast.error('No data found');
                            return;
                        }
                        response = await authAxiosInstance.post(
                            '/printable/clIdPdf',
                            [clData[0]],
                            { responseType: 'blob' }
                        );
                        fileName = 'ClId.pdf';
                        // saveAs(response.data, 'ClId.pdf');
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }
                    break;
                case 'aclIdP':
                    try {
                        const aclData = employees?.docs?.filter(
                            (ele) => ele?.role?._id === ACL_ROLE_ID
                        );
                        console.log('aclAry', aclData[0]);
                        if (aclData.length === 0) {
                            toast.error('No data found');
                            return;
                        }
                        response = await authAxiosInstance.post(
                            '/printable/aclIdPdf',
                            [aclData[0]],
                            { responseType: 'blob' }
                        );
                        fileName = 'AclId.pdf';
                        // saveAs(response.data, 'CLId.pdf');
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }
                    break;
                case 'popP':
                    try {
                        const string = QueryString.stringify({
                            populate: true,
                            limit: 5000,
                        });
                        const resp = await authAxiosInstance.get(
                            `/teamParticipant/getParticipantData?${string}`
                        );
                        console.log('resp parti', resp?.data?.data);
                        const popArray = resp?.data?.data;
                        const popData = popArray.find(
                            (item) =>
                                item.participants.participantStatus !==
                                    'substitute' && item.status === 'approved'
                        );
                        console.log('popData', popData);
                        if (!popData) {
                            toast.error('No data found');
                            return;
                        }
                        response = await authAxiosInstance.post(
                            '/printable/popPdf',
                            [popData],
                            { responseType: 'blob' }
                        );
                        fileName = 'PopFinal.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        if (error.response) {
                            if (error.response.status === 404) {
                                toast.error('No data found');
                            } else {
                                toast.error('Failed to Download');
                            }
                        }
                    }
                    break;
                case 'popSubstitutedP':
                    try {
                        const string = QueryString.stringify({
                            populate: true,
                            limit: 5000,
                        });
                        const resp = await authAxiosInstance.get(
                            `/teamParticipant/getSubstituteData?${string}`
                        );
                        console.log('resp parti', resp?.data?.data);
                        const popArray = resp?.data?.data;
                        const popSubData = popArray.find(
                            (ele) =>
                                ele.substitutes.participantStatus ===
                                    'substituted' && ele.status === 'approved'
                        );
                        console.log('popData', popSubData);
                        if (!popSubData) {
                            toast.error('No data found');
                            return;
                        }
                        response = await authAxiosInstance.post(
                            '/printable/popSubstitutesPdf',
                            [popSubData],
                            { responseType: 'blob' }
                        );
                        fileName = 'PopSubstitution.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        if (error.response) {
                            if (error.response.status === 404) {
                                toast.error('No data found');
                            } else {
                                toast.error('Failed to Download');
                            }
                        }
                    }
                    break;
                case 'popEliminationP':
                    try {
                        const string = QueryString.stringify({
                            populate: true,
                            limit: 5000,
                        });
                        const resp = await authAxiosInstance.get(
                            `/teamParticipant/getParticipantData?${string}`
                        );
                        console.log('resp parti', resp?.data?.data);
                        const popArray = resp?.data?.data;
                        const popEliData = popArray.find(
                            (ele) =>
                                ele.participants.participantStatus !==
                                    'substitute' && ele.status === 'eliminate'
                        );
                        console.log('popData', popEliData);
                        if (!popEliData) {
                            toast.error('No data found');
                            return;
                        }
                        response = await authAxiosInstance.post(
                            '/printable/popEliminationPdf',
                            [popEliData],
                            { responseType: 'blob' }
                        );
                        fileName = 'PopElimination.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        if (error.response) {
                            if (error.response.status === 404) {
                                toast.error('No data found');
                            } else {
                                toast.error('Failed to Download');
                            }
                        }
                    }
                    break;
                case 'popSubEliminationP':
                    try {
                        const string = QueryString.stringify({
                            populate: true,
                            limit: 5000,
                        });
                        const resp = await authAxiosInstance.get(
                            `/teamParticipant/getSubstituteData?${string}`
                        );
                        console.log('resp parti', resp?.data?.data);
                        const popArray = resp?.data?.data;
                        const popSubEliData = popArray.find(
                            (ele) =>
                                ele.substitutes.participantStatus ===
                                    'substituted' && ele.status === 'eliminate'
                        );
                        console.log('popData', popSubEliData);
                        if (!popSubEliData) {
                            toast.error('No data found');
                            return;
                        }
                        response = await authAxiosInstance.post(
                            '/printable/popSubEliminationPdf',
                            [popSubEliData],
                            { responseType: 'blob' }
                        );
                        fileName = 'PopSubstitutionElimination.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        if (error.response) {
                            if (error.response.status === 404) {
                                toast.error('No data found');
                            } else {
                                toast.error('Failed to Download');
                            }
                        }
                    }
                    break;
                case 'clCertificateP':
                    try {
                        const clData = employees?.docs?.filter(
                            (ele) => ele?.role?._id === CL_ROLE_ID
                        );
                        console.log('clAry', clData[0]);
                        response = await authAxiosInstance.post(
                            '/printable/clCertificatePdf',
                            [clData[0]],
                            { responseType: 'blob' }
                        );
                        fileName = 'ClCertificate.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }
                    break;
                case 'aclCertificateP':
                    try {
                        const aclData = employees?.docs?.filter(
                            (ele) => ele?.role?._id === ACL_ROLE_ID
                        );
                        console.log('aclAry', aclData[0]);
                        response = await authAxiosInstance.post(
                            '/printable/aclCertificatePdf',
                            [aclData[0]],
                            { responseType: 'blob' }
                        );
                        fileName = 'AclCertificate.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }
                    break;
                case 'winnerCertificateP':
                    try {
                        const stringData = QueryString.stringify({
                            populate: true,
                            limit: 5000,
                        });
                        const resp = await authAxiosInstance.get(
                            `/scorecard/getFirstPlaceData?${stringData}`
                        );
                        const winnerData = resp?.data?.data;
                        const filteredWinnersData = winnerData.filter(
                            (ele) =>
                                ele.firstPlace && ele.firstPlace.participants
                        );
                        console.log('first place', filteredWinnersData);
                        response = await authAxiosInstance.post(
                            '/printable/winnersPdf',
                            [filteredWinnersData[0]],
                            { responseType: 'blob' }
                        );
                        fileName = 'WinnerCertificate.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }
                    break;
                case 'firstRunnerCertificateP':
                    try {
                        const stringData = QueryString.stringify({
                            populate: true,
                            limit: 5000,
                        });
                        const resp = await authAxiosInstance.get(
                            `/scorecard/getFirstRunnerUpData?${stringData}`
                        );
                        const secondPlaceData = resp?.data?.data;
                        const filteredSecondPlaceData = secondPlaceData.filter(
                            (ele) =>
                                ele.secondPlace && ele.secondPlace.participants
                        );
                        console.log('2nd place', filteredSecondPlaceData);
                        response = await authAxiosInstance.post(
                            '/printable/firstRunnerUpPdf',
                            [filteredSecondPlaceData[0]],
                            { responseType: 'blob' }
                        );
                        fileName = '1stRunnerUpCertificate.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }
                    break;
                case 'secondRunnerCertificateP':
                    try {
                        const stringData = QueryString.stringify({
                            populate: true,
                            limit: 5000,
                        });
                        const resp = await authAxiosInstance.get(
                            `/scorecard/getSecondRunnerUpData?${stringData}`
                        );
                        // console.log('3rd place', resp?.data?.data);
                        const thirdPlaceData = resp?.data?.data;
                        const filteredThirdPlaceData = thirdPlaceData.filter(
                            (ele) =>
                                ele.thirdPlace && ele.thirdPlace.participants
                        );
                        console.log('filter', filteredThirdPlaceData);
                        response = await authAxiosInstance.post(
                            '/printable/secondRunnerUpPdf',
                            [filteredThirdPlaceData[0]],
                            { responseType: 'blob' }
                        );
                        fileName = '2ndRunnerUpCertificate.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }
                    break;
                case 'participationCertificateP':
                    try {
                        const string = QueryString.stringify({
                            populate: true,
                            limit: 5000,
                        });
                        const resp = await authAxiosInstance.get(
                            `/teamParticipant/getParticipantData?${string}`
                        );
                        console.log('resp parti', resp?.data?.data);
                        const participantData = resp?.data?.data[0];
                        response = await authAxiosInstance.post(
                            '/printable/participantCertificatePdf',
                            [participantData],
                            { responseType: 'blob' }
                        );
                        fileName = 'ParticipantCertificate.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }
                    break;
                case 'participantsListP':
                    try {
                        let data = {
                            populate: true,
                            status: 'approved',
                            sort: { 'event.name': 1, collegeCode: 1 },
                            limit: 5000,
                        };
                        console.log('object', data);
                        const stringData = QueryString.stringify(data);
                        const resp = await authAxiosInstance.get(
                            `/teamParticipant?${stringData}`
                        );
                        console.log('dwnld data', resp?.data?.data?.docs);
                        if (resp?.data?.data?.docs?.length > 0) {
                            let mainData = [];
                            let srNo = 1;
                            resp?.data?.data?.docs?.forEach((ele, index) => {
                                let { event, collegeCode, participants } = ele;

                                participants?.forEach((p, i) => {
                                    mainData.push({
                                        srNo: srNo++,
                                        collegeCode: collegeCode,
                                        participantName: `${p.firstName} ${p.lastName}`,
                                        event: event?.name,
                                    });
                                });
                            });
                            console.log('main data', mainData);
                            const fileName = 'ParticipantList';
                            exportCsv([mainData[0]], fileName);
                        }
                    } catch (error) {
                        console.log(error);
                        toast.error('Fail to Download ');
                    }
                    break;
                default:
                    console.error('Unknown printable PrevId:', id);
            }
            if (response) {
                saveAs(response.data, fileName);
            }
        } catch (error) {
            console.error('Error in Preview file:', error);
        } finally {
            setLoadingDownload((state) => ({
                ...state,
                [id]: false,
            }));
        }
    };

    const handleDownloadClick = async (id) => {
        try {
            setLoadingDownload((prevState) => ({
                ...prevState,
                [id]: true,
            }));
            let response;
            let fileName = '';
            switch (id) {
                case 'accredit':
                    try {
                        const volunteerData = employees?.docs?.filter(
                            (ele) => ele?.role?._id !== SUPER_USER_ROLE
                        );
                        console.log('volunteerAry', volunteerData);
                        response = await authAxiosInstance.post(
                            '/printable/volunteerPdf',
                            volunteerData,
                            { responseType: 'blob' }
                        );
                        fileName = 'Accredit.pdf';
                        // console.log('response', response);
                        // saveAs(response.data, 'VolunteerId.pdf');
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }

                    break;
                case 'certificate':
                    try {
                        const teamData = registrationRequest?.docs;
                        response = await authAxiosInstance.post(
                            '/printable/teamCertificatePdf',
                            teamData,
                            { responseType: 'blob' }
                        );
                        fileName = 'Certificate.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }
                    break;
                case 'scoreSheet':
                    try {
                        const data = {
                            eventIds: teamParticipant?.docs?.map(
                                (ele) => ele.event._id
                            ),
                            status: 'approved',
                            populate: true,
                            limit: 5000,
                        };
                        console.log('data', data);
                        const resp = await authAxiosInstance.post(
                            `/teamParticipant/getEventParticipantData`,
                            data
                        );
                        console.log('event wise', resp?.data?.data);
                        const participantData = resp?.data?.data;
                        participantData.map((ele) =>
                            ele.participants.sort((a, b) => {
                                if (a.collegeCode < b.collegeCode) return -1;
                                if (a.collegeCode > b.collegeCode) return 0;
                            })
                        );
                        response = await authAxiosInstance.post(
                            '/printable/eventParticipantScoreSheet',
                            participantData,
                            { responseType: 'blob' }
                        );
                        fileName = 'ScoreSheet.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }
                    break;
                case 'clId':
                    try {
                        const clData = employees?.docs?.filter(
                            (ele) => ele?.role?._id === CL_ROLE_ID
                        );
                        console.log('clAry', clData);
                        response = await authAxiosInstance.post(
                            '/printable/clIdPdf',
                            clData,
                            { responseType: 'blob' }
                        );
                        fileName = 'ClId.pdf';
                        // saveAs(response.data, 'ClId.pdf');
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }
                    break;
                case 'aclId':
                    try {
                        const aclData = employees?.docs?.filter(
                            (ele) => ele?.role?._id === ACL_ROLE_ID
                        );
                        console.log('aclAry', aclData);
                        response = await authAxiosInstance.post(
                            '/printable/aclIdPdf',
                            aclData,
                            { responseType: 'blob' }
                        );
                        fileName = 'AclId.pdf';
                        // saveAs(response.data, 'CLId.pdf');
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }
                    break;
                case 'pop':
                    try {
                        const string = QueryString.stringify({
                            populate: true,
                            limit: 5000,
                        });
                        const resp = await authAxiosInstance.get(
                            `/teamParticipant/getParticipantData?${string}`
                        );
                        console.log('resp parti', resp?.data?.data);
                        const popData = resp?.data?.data;
                        console.log('popData', popData);
                        response = await authAxiosInstance.post(
                            '/printable/popPdf',
                            popData,
                            { responseType: 'blob' }
                        );
                        fileName = 'PopFinal.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        if (error.response) {
                            if (error.response.status === 404) {
                                toast.error('No data found');
                            } else {
                                toast.error('Failed to Download');
                            }
                        }
                    }
                    break;
                case 'popSubstituted':
                    try {
                        const string = QueryString.stringify({
                            populate: true,
                            limit: 5000,
                        });
                        const resp = await authAxiosInstance.get(
                            `/teamParticipant/getSubstituteData?${string}`
                        );
                        console.log('resp parti', resp?.data?.data);
                        const popSubData = resp?.data?.data;
                        console.log('popData', popSubData);
                        response = await authAxiosInstance.post(
                            '/printable/popSubstitutesPdf',
                            popSubData,
                            { responseType: 'blob' }
                        );
                        fileName = 'PopSubstitution.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        if (error.response) {
                            if (error.response.status === 404) {
                                toast.error('No data found');
                            } else {
                                toast.error('Failed to Download');
                            }
                        }
                    }
                    break;
                case 'popElimination':
                    try {
                        const string = QueryString.stringify({
                            populate: true,
                            limit: 5000,
                        });
                        const resp = await authAxiosInstance.get(
                            `/teamParticipant/getParticipantData?${string}`
                        );
                        console.log('resp parti', resp?.data?.data);
                        const popEliData = resp?.data?.data;
                        console.log('popData', popEliData);
                        response = await authAxiosInstance.post(
                            '/printable/popEliminationPdf',
                            popEliData,
                            { responseType: 'blob' }
                        );
                        fileName = 'PopElimination.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        if (error.response) {
                            if (error.response.status === 404) {
                                toast.error('No data found');
                            } else {
                                toast.error('Failed to Download');
                            }
                        }
                    }
                    break;
                case 'popSubElimination':
                    try {
                        const string = QueryString.stringify({
                            populate: true,
                            limit: 5000,
                        });
                        const resp = await authAxiosInstance.get(
                            `/teamParticipant/getSubstituteData?${string}`
                        );
                        console.log('resp parti', resp?.data?.data);
                        const popSubEliData = resp?.data?.data;
                        console.log('popData', popSubEliData);
                        response = await authAxiosInstance.post(
                            '/printable/popSubEliminationPdf',
                            popSubEliData,
                            { responseType: 'blob' }
                        );
                        fileName = 'PopSubstitutionElimination.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        if (error.response) {
                            if (error.response.status === 404) {
                                toast.error('No data found');
                            } else {
                                toast.error('Failed to Download');
                            }
                        }
                    }
                    break;
                case 'clCertificate':
                    try {
                        const clData = employees?.docs?.filter(
                            (ele) => ele?.role?._id === CL_ROLE_ID
                        );
                        console.log('clAry', clData);
                        response = await authAxiosInstance.post(
                            '/printable/clCertificatePdf',
                            clData,
                            { responseType: 'blob' }
                        );
                        fileName = 'ClCertificate.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }
                    break;
                case 'aclCertificate':
                    try {
                        const aclData = employees?.docs?.filter(
                            (ele) => ele?.role?._id === ACL_ROLE_ID
                        );
                        console.log('aclAry', aclData);
                        response = await authAxiosInstance.post(
                            '/printable/aclCertificatePdf',
                            aclData,
                            { responseType: 'blob' }
                        );
                        fileName = 'AclCertificate.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }
                    break;
                case 'winnerCertificate':
                    try {
                        const stringData = QueryString.stringify({
                            populate: true,
                            limit: 5000,
                        });
                        const resp = await authAxiosInstance.get(
                            `/scorecard/getFirstPlaceData?${stringData}`
                        );
                        const winnerData = resp?.data?.data;
                        const filteredWinnersData = winnerData.filter(
                            (ele) =>
                                ele.firstPlace && ele.firstPlace.participants
                        );
                        console.log('first place', filteredWinnersData);
                        response = await authAxiosInstance.post(
                            '/printable/winnersPdf',
                            filteredWinnersData,
                            { responseType: 'blob' }
                        );
                        fileName = 'WinnerCertificate.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }
                    break;
                case 'firstRunnerCertificate':
                    try {
                        const stringData = QueryString.stringify({
                            populate: true,
                            limit: 5000,
                        });
                        const resp = await authAxiosInstance.get(
                            `/scorecard/getFirstRunnerUpData?${stringData}`
                        );
                        const secondPlaceData = resp?.data?.data;
                        const filteredSecondPlaceData = secondPlaceData.filter(
                            (ele) =>
                                ele.secondPlace && ele.secondPlace.participants
                        );
                        console.log('2nd place', filteredSecondPlaceData);
                        response = await authAxiosInstance.post(
                            '/printable/firstRunnerUpPdf',
                            filteredSecondPlaceData,
                            { responseType: 'blob' }
                        );
                        fileName = '1stRunnerUpCertificate.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }
                    break;
                case 'secondRunnerCertificate':
                    try {
                        const stringData = QueryString.stringify({
                            populate: true,
                            limit: 5000,
                        });
                        const resp = await authAxiosInstance.get(
                            `/scorecard/getSecondRunnerUpData?${stringData}`
                        );
                        // console.log('3rd place', resp?.data?.data);
                        const thirdPlaceData = resp?.data?.data;
                        const filteredThirdPlaceData = thirdPlaceData.filter(
                            (ele) =>
                                ele.thirdPlace && ele.thirdPlace.participants
                        );
                        console.log('filter', filteredThirdPlaceData);
                        response = await authAxiosInstance.post(
                            '/printable/secondRunnerUpPdf',
                            filteredThirdPlaceData,
                            { responseType: 'blob' }
                        );
                        fileName = '2ndRunnerUpCertificate.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }
                    break;
                case 'participationCertificate':
                    try {
                        const string = QueryString.stringify({
                            populate: true,
                            limit: 5000,
                        });
                        const resp = await authAxiosInstance.get(
                            `/teamParticipant/getParticipantData?${string}`
                        );
                        console.log('resp parti', resp?.data?.data);
                        const participantData = resp?.data?.data;
                        response = await authAxiosInstance.post(
                            '/printable/participantCertificatePdf',
                            participantData,
                            { responseType: 'blob' }
                        );
                        fileName = 'ParticipantCertificate.pdf';
                    } catch (error) {
                        console.log('Error', error);
                        toast.error('Failed to Download');
                    }
                    break;
                case 'participantsList':
                    try {
                        let data = {
                            populate: true,
                            status: 'approved',
                            sort: { 'event.name': 1, collegeCode: 1 },
                            limit: 5000,
                        };
                        console.log('object', data);
                        const stringData = QueryString.stringify(data);
                        const resp = await authAxiosInstance.get(
                            `/teamParticipant?${stringData}`
                        );
                        console.log('dwnld data', resp?.data?.data?.docs);
                        if (resp?.data?.data?.docs?.length > 0) {
                            let mainData = [];
                            let srNo = 1;
                            resp?.data?.data?.docs?.forEach((ele, index) => {
                                let { event, collegeCode, participants } = ele;

                                participants?.forEach((p, i) => {
                                    mainData.push({
                                        srNo: srNo++,
                                        collegeCode: collegeCode,
                                        participantName: `${p.firstName} ${p.lastName}`,
                                        event: event?.name,
                                    });
                                });
                            });
                            console.log('main data', mainData);
                            const fileName = 'ParticipantList';
                            exportCsv(mainData, fileName);
                        }
                    } catch (error) {
                        console.log(error);
                        toast.error('Fail to Download');
                    }
                    break;
                default:
                    console.error('Unknown printable id:', id);
            }
            if (response) {
                saveAs(response.data, fileName);
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        } finally {
            setLoadingDownload((prevState) => ({
                ...prevState,
                [id]: false,
            }));
        }
    };
    const intPrintableCols = [
        {
            id: 'internalName',
            Header: ' ',
            Cell: ({ row }) => {
                // console.log('row', row)
                return <span>{row?.original?.name}</span>;
            },
            cellStyle: {
                width: '50%',
            },
        },
        {
            id: 'internalAction',
            Header: ' ',
            Cell: ({ row }) => {
                const isDownload = loadingDownload[row?.original?._id];
                const isDownloadP = loadingDownload[row?.original?.prevId];
                return (
                    <div className="flex flex-row items-center gap-4">
                        {isDownloadP ? (
                            <ClipLoader color="#FFFFFF" size={18} />
                        ) : (
                            <PrimaryButton
                                onClick={() => {
                                    handlePreviewClick(row.original.prevId);
                                }}
                            >
                                Preview <Eye size={14} className="ml-2" />
                            </PrimaryButton>
                        )}
                        {isDownload ? (
                            <ClipLoader color="#FFFFFF" size={18} />
                        ) : (
                            <SecondaryButton
                                onClick={() =>
                                    handleDownloadClick(row.original._id)
                                }
                            >
                                Download <Download size={14} className="ml-2" />
                            </SecondaryButton>
                        )}
                    </div>
                );
            },
            ellStyle: {
                width: '50%',
            },
        },
    ];
    const extPrintableCols = [
        {
            id: 'externalName',
            Header: ' ',
            Cell: ({ row }) => {
                return <span>{row?.original?.name}</span>;
            },
            cellStyle: {
                width: '50%',
            },
        },
        {
            id: 'externalAction',
            Header: ' ',
            Cell: ({ row }) => {
                const isDownload = loadingDownload[row?.original?._id];
                const isDownloadP = loadingDownload[row?.original?.prevId];
                return (
                    <div className="flex flex-row items-center gap-4">
                        {isDownloadP ? (
                            <ClipLoader color="#FFFFFF" size={18} />
                        ) : (
                            <PrimaryButton
                                onClick={() => {
                                    handlePreviewClick(row.original.prevId);
                                }}
                            >
                                Preview <Eye size={14} className="ml-2" />
                            </PrimaryButton>
                        )}
                        {isDownload ? (
                            <ClipLoader color="#FFFFFF" size={18} />
                        ) : (
                            <SecondaryButton
                                onClick={() =>
                                    handleDownloadClick(row.original._id)
                                }
                            >
                                Download <Download size={14} className="ml-2" />
                            </SecondaryButton>
                        )}
                    </div>
                );
            },
            ellStyle: {
                width: '50%',
            },
        },
    ];
    return (
        <PageWithCard
            heading={
                <>
                    {/* <Link to={'/allRegistration'}>Data Bank</Link> */}
                    <span >Data Bank</span>
                    <span className="text-slate-200 inline-block px-1">
                        <ChevronRight size={14} className="-mb-0.5" />{' '}
                    </span>
                    <span>{'Printable'}</span>
                </>
            }
        >
            <div className="w-full md:w-2/3">
                <h1 className="text-2xl text-slate-200 font-semibold mb-2">
                    Internal
                </h1>
                <TableWithHeading
                    columns={intPrintableCols}
                    data={internalPrintable}
                />
                <h1 className="text-2xl text-slate-200 font-semibold mb-2 mt-8">
                    External
                </h1>
                <TableWithHeading
                    columns={extPrintableCols}
                    data={externalPrintable}
                />
            </div>
        </PageWithCard>
    );
};

export default Printable;
