import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import SidebarLinks from './SidebarLinks';
import UserMenu from '../../components/infrastructure/UserMenu';
import { useSelector } from 'react-redux';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import { getWebsiteCreatives } from '../../app/reducers/WebsitesCreatives/websitesCreativeSlice';

function Sidebar({ sidebarOpen, setSidebarOpen }) {
    const trigger = useRef(null);
    const sidebar = useRef(null);
    const { user } = useSelector(getAuth);
    const { websiteCreatives } = useSelector(getWebsiteCreatives);
    const isDynamicData = websiteCreatives[0];
    const [sidebarExpanded, setSidebarExpanded] = useState(false);

    // Handle click outside sidebar to close it
    useEffect(() => {
        if (window.innerWidth < 1024) {
            const clickHandler = ({ target }) => {
                if (!sidebar.current || !trigger.current) return;
                if (
                    !sidebarOpen ||
                    sidebar.current.contains(target) ||
                    trigger.current.contains(target)
                )
                    return;
                setSidebarOpen(false);
            };
            document.addEventListener('click', clickHandler);
            return () => document.removeEventListener('click', clickHandler);
        }
    }, [sidebarOpen]);

    // Handle ESC key to close sidebar
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!sidebarOpen || keyCode !== 27) return;
            setSidebarOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    }, [sidebarOpen]);

    const handleLinkClick = () => {
        if (window.innerWidth < 1024) {
            setSidebarOpen(false);
        } else {
            setSidebarOpen(true);
        }
    };

    return (
        <div>
            {/* Top Navigation Bar */}
            <nav
                className="fixed top-0 z-50 w-full border-b border-slate-700 p-4"
                style={{ backgroundColor: '#141414' }}
            >
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <button
                            ref={trigger}
                            onClick={() => setSidebarOpen(!sidebarOpen)}
                            aria-controls="sidebar"
                            aria-expanded={sidebarOpen}
                            className="inline-flex items-center py-2 pr-2 text-sm text-slate-200 rounded-lg focus:outline-none focus:ring-2"
                        >
                            <span className="sr-only">Open sidebar</span>
                            <svg
                                className="w-6 h-6"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    clipRule="evenodd"
                                    fillRule="evenodd"
                                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                                />
                            </svg>
                        </button>
                        {user?.role?.name === 'CL' && (
                            <div>
                                <h1
                                    className="text-lg lg:ml-4"
                                    style={{
                                        color: isDynamicData
                                            ? isDynamicData.highlightedText
                                            : '#ffffff',
                                    }}
                                >
                                    {user?.username}
                                </h1>
                            </div>
                        )}
                        <NavLink to="/" className="flex items-center">
                            {/* Logo */}
                        </NavLink>
                    </div>
                    <div className="flex items-center space-x-3">
                        <UserMenu align="right" />
                    </div>
                </div>
            </nav>

            {/* Sidebar */}
            <aside
                id="sidebar"
                ref={sidebar}
                className={`fixed top-0 left-0 z-40 w-64 h-screen pt-16 transition-transform transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} bg-black`}
                aria-label="Sidebar"
            >
                <div
                    className={`px-3 pb-8 bg-black h-[90vh] overflow-auto no-scrollbar`}
                >
                    <SidebarLinks
                        setSidebarExpanded={setSidebarExpanded}
                        sidebarExpanded={sidebarExpanded}
                        handleLinkClick={handleLinkClick}
                        sidebarOpen={sidebarOpen}
                    />
                </div>
            </aside>
        </div>
    );
}

export default Sidebar;
